import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Settings.css";
import { AuthContext } from "../../../providers/AuthProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import { Loader } from "../../../components/Loader";
import MainCard from "../../../components/MainCard";
import Button from "../../../components/Button";
import { Save } from "@geist-ui/icons";
import ModalAlert from "../../../components/ModalAlert";
import { Typography } from "@mui/material";

const Section = ({ title, children, isOpen, onToggle, description }) => {
  return (
    <div className="settingsSection">
      <button type="button" onClick={onToggle} className="sectionHeader">
        <div>
          <span className="title">{title}</span>
          {description && <span className="description">{description}</span>}
        </div>
        <span className="arrow">{isOpen ? "▼" : "▶"}</span>
      </button>
      {isOpen && <div className="sectionContent">{children}</div>}
    </div>
  );
};

const FormField = ({ label, children, description }) => (
  <div className="formField">
    <label>{label}</label>
    {description && <p className="description">{description}</p>}
    {children}
  </div>
);

const Settings = () => {
  const navigate = useNavigate();
  const [loadingFile, setLoadingFile] = useState(false);
  const [listFiles, setListFiles] = useState([]);
  const [openSections, setOpenSections] = useState({
    instructions: false,
    identity: false,
    business: false,
    products: false,
    strategy: false,
    files: false,
  });

  const [mockFiles] = useState([
    {
      id: 1,
      name: "Catálogo de Produtos 2024.pdf",
      size: "2.5 MB",
      type: "pdf",
    },
    { id: 2, name: "Tabela de Preços.xlsx", size: "1.2 MB", type: "excel" },
    { id: 3, name: "Manual de Atendimento.docx", size: "850 KB", type: "word" },
    {
      id: 4,
      name: "Apresentação Institucional.pptx",
      size: "3.8 MB",
      type: "powerpoint",
    },
  ]);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const { user, updateUser, updatePartnerDetail, checkComplete } =
    useContext(AuthContext);

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const toneArray = [
    "Respeitoso & Empático",
    "Animado & Engajador",
    "Profissional & Firme",
    "Atencioso & Calmo",
    "Encorajador & Motivador",
    "Compassivo & Compreensivo",
    "Divertido & Cativante",
    "Cortês & Gentil",
    "Solidário & Amigável",
    "Eficiente & Rápido",
    "Confiável & Preciso",
    "Assertivo & Decisivo",
    "Perspicaz & Observador",
    "Outro",
  ];

  const validationSchema = yup.object({
    attendantInstructions: yup.string(),
    attendanceGoals: yup.string(),
    keyDifferentials: yup.string(),
    forbiddenResponses: yup.string(),
    // conversationFlows: yup.string(),
    nameAttendant: yup.string(),
    companyName: yup.string(),
    companyNiche: yup.string(),
    preferentialLanguage: yup.string(),
    companyContact: yup.string().url("Digite uma URL válida").nullable(),
    catalogLink: yup.string().url("Digite uma URL válida").nullable(),
    instagramLink: yup
      .string()
      .url("Digite uma URL válida do Instagram")
      .nullable(),
    facebookLink: yup
      .string()
      .url("Digite uma URL válida do Facebook")
      .nullable(),
    twitterXLink: yup
      .string()
      .url("Digite uma URL válida do X (Twitter)")
      .nullable(),
    youtubeLink: yup
      .string()
      .url("Digite uma URL válida do YouTube")
      .nullable(),
  });

  const form = useFormik({
    // validationSchema,
    initialValues: {
      attendantInstructions: "",
      attendanceGoals: "",
      keyDifferentials: "",
      forbiddenResponses: "",
      conversationFlows: "",
      nameAttendant: "",
      companyName: "",
      companyNiche: "",
      servedRegion: "",
      companyProducts: "",
      companyServices: "",
      companyContact: "",
      toneVoice: [],
      toneVoiceExtra: "",
      preferentialLanguage: "",
      catalogLink: "",
      instagramLink: "",
      facebookLink: "",
      twitterXLink: "",
      youtubeLink: "",
    },
    onSubmit: async (values) => {
      // console.log('Form submitted with values:', values);
      await updateSettings(values);
    },
  });

  const getSettings = async () => {
    setLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.get(
          `partners/partner_details/${user.partnerDetailId}`
        );
        if (data) {
          let toneExtra = "";
          const toneVoiceFiltrados = data.data?.attributes?.toneVoice
            ? Object.keys(data.data.attributes.toneVoice).reduce((acc, key) => {
                if (toneArray.includes(data.data.attributes.toneVoice[key])) {
                  acc[key] = data.data.attributes.toneVoice[key];
                } else {
                  acc[key] = "Outro";
                  toneExtra = data.data.attributes.toneVoice[key];
                }
                return acc;
              }, [])
            : [];

          form.setValues({
            ...data.data.attributes,
            toneVoice: toneVoiceFiltrados,
            toneVoiceExtra: toneExtra,
            catalogLink: data?.data?.attributes?.catalog_link || "",
          });
        }
      }
    } catch (error) {
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
    }
  };

  const updateSettings = async (values) => {
    setSaveLoading(true);
    try {
      const payload = {
        data: {
          // type: "partner_details",
          attributes: {
            nameAttendant: values.nameAttendant || "",
            companyName: values.companyName || "",
            companyNiche: values.companyNiche || "",
            toneVoice:
              values?.toneVoice?.map((item) => {
                if (item === "Outro") {
                  return values.toneVoiceExtra;
                }
                return item;
              }) || [],
            preferentialLanguage: values.preferentialLanguage || "",
            attendantInstructions: values.attendantInstructions || "",
            companyServices: values.companyServices || "",
            companyProducts: values.companyProducts || "",
            twitterXLink: values.twitterXLink || "",
            youtubeLink: values.youtubeLink || "",
            facebookLink: values.facebookLink || "",
            instagramLink: values.instagramLink || "",
            companyContact: values.companyContact || "",
            keyDifferentials: values.keyDifferentials || "",
            catalogLink: values.catalogLink || "",
            forbiddenResponses: values.forbiddenResponses || "",
            attendanceGoals: values.attendanceGoals || "",
            // conversationFlows: values.conversationFlows || "",
            // servedRegion: values.servedRegion || "",
          },
        },
      };

      // console.log('Saving settings with payload:', payload);

      if (user.partnerDetailId) {
        const response = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          payload
        );
        console.log("Update response:", response);

        if (response.data) {
          await checkComplete(user.id);
          toast.success("Configurações atualizadas com sucesso!");
          getSettings(); // Recarrega os dados após salvar
        } else {
          throw new Error("No data received from update");
        }
      } else {
        payload.data.attributes.partnerId = user.id;
        const response = await api.post(`partners/partner_details/`, payload);
        console.log("Create response:", response);

        if (response.data) {
          await checkComplete(user.id);
          toast.success("Configurações criadas com sucesso!");
          updatePartnerDetail(response.data);
          getSettings(); // Recarrega os dados após criar
        } else {
          throw new Error("No data received from create");
        }
      }
    } catch (error) {
      console.error("Erro ao salvar configurações:", error);
      console.error("Error details:", {
        message: error.message,
        response: error.response,
        stack: error.stack,
      });

      let errorMessage = "Não foi possível atualizar as configurações. ";

      if (error.response?.data?.message) {
        errorMessage += error.response.data.message;
      } else if (error.message) {
        errorMessage += error.message;
      }

      toast.error(errorMessage);
    } finally {
      setSaveLoading(false);
    }
  };

  const getListFIles = async () => {
    try {
      const { data } = await api.get(`partners/detail_files`);
      if (data) {
        setListFiles(data.data);
      }
    } catch (error) {
      // toast.error("Não foi possível carregar as configurações.");
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getSettings();
    getListFIles();
  }, []);

  const fetchFile = async (base64, name) => {
    setLoadingFile(true);
    try {
      await api.post(`/partners/detail_files/`, {
        data: {
          attributes: {
            filename: name,
            file: {
              data: base64,
            },
          },
        },
      });
    } catch (error) {
    } finally {
      getListFIles();
      setLoadingFile(false);
    }
  };

  const handleFileUpload = (files) => {
    if (!files || files.length === 0) return;

    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result; // Mantém o Data URI completo
        const fileName = file.name; // Obtém o nome do arquivo

        fetchFile(base64String, fileName);
      };
      reader.onerror = (error) => console.error("Erro ao ler arquivo", error);
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileUpload(event.dataTransfer.files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  if (loading) {
    return (
      <AuthorizedLayout>
        <Loader size={150} />
      </AuthorizedLayout>
    );
  }

  const CardFileItens = ({ file }) => {
    const [loadingD, setLoadingD] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [modalAlert, setModalAlert] = useState(false);

    const handleFileDownload = (file, fileName) => {
      setLoadingD(true);
      fetch(file)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => console.error("Erro ao baixar o arquivo:", error))
        .finally(() => setLoadingD(false));
    };

    const handleFileDelete = async (fileId) => {
      setLoadingDelete(true);
      try {
        await api.delete(`/partners/detail_files/${fileId}`);
      } catch (error) {
      } finally {
        setLoadingDelete(false);
        setModalAlert(false);
        getListFIles();
      }
    };

    return (
      <>
        <div key={file.id} className="fileItem">
          <div className="fileInfo">
            <svg
              className="fileIcon"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
              <polyline points="13 2 13 9 20 9" />
            </svg>
            <div>
              <div className="fileName">
                {file?.attributes?.filename || "Nome indefinido"}
              </div>
              {/* <div className="fileSize">{file.size}</div> */}
            </div>
          </div>
          <div className="fileActions">
            {loadingD ? (
              <button type="button" className="actionButton" title="Download">
                <svg
                  className="loading-spinner"
                  width="20"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="4"
                >
                  <circle cx="25" cy="25" r="20" strokeOpacity="0.5" />
                  <path d="M25 5a20 20 0 0 1 20 20" className="spinner-path" />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                className="actionButton"
                onClick={() =>
                  handleFileDownload(
                    file.attributes.file,
                    file.attributes.filename
                  )
                }
                title="Download"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                  <polyline points="7 10 12 15 17 10" />
                  <line x1="12" y1="15" x2="12" y2="3" />
                </svg>
              </button>
            )}
            <button
              type="button"
              className="actionButton delete"
              // onClick={() => handleFileDelete(file.id)}
              onClick={() => setModalAlert(true)}
              title="Excluir"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <polyline points="3 6 5 6 21 6" />
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
              </svg>
            </button>
          </div>
        </div>

        <ModalAlert
          handleClose={() => setModalAlert(false)}
          handleSubmit={() => handleFileDelete(file.id)}
          open={modalAlert}
          loading={loadingDelete}
        >
          <Typography variant={isMobileDevice ? "h7" : "h6"}>
            Tem certeza que deseja excluir esse arquivo? Essa ação é
            irreversível.
          </Typography>
        </ModalAlert>
      </>
    );
  };

  return (
    <AuthorizedLayout>
      <ToastContainer position="top-right" autoClose={3000} />
      <MainCard>
        <div className="settingsHeader">
          <h1 className="title">Configurações do Atendente Virtual</h1>
          <p className="subtitle">
            Personalize como seu atendente virtual irá interagir com seus
            clientes
          </p>

          <div className="buttonContainer">
            <Button
              variant="outline"
              onClick={() => {
                localStorage.setItem("@sacgpt:checkWizard", "false");
                navigate("/configuracoes/automatica");
              }}
            >
              Entrar em modo automático
            </Button>
            <Button variant="filled" onClick={() => navigate("/simulador")}>
              Simular atendimento
            </Button>
          </div>
        </div>

        <div className="settingsContent">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log("Form submitted");
              form.submitForm();
            }}
          >
            {/* Seção Principal: Instruções do Atendente */}
            <Section
              title="Instruções do Atendente"
              isOpen={openSections.instructions}
              onToggle={() => toggleSection("instructions")}
            >
              <FormField
                label="Instruções Principais"
                description="Descreva em detalhes como o atendente deve se comportar e responder"
              >
                <textarea
                  name="attendantInstructions"
                  value={form.values.attendantInstructions}
                  onChange={form.handleChange}
                  className="formTextarea"
                  placeholder="Ex: Seja sempre cordial e profissional. Evite gírias. Priorize respostas objetivas..."
                />
              </FormField>

              <FormField
                label="Objetivos do Atendimento"
                description="Defina os principais objetivos que o atendente deve buscar alcançar"
              >
                <textarea
                  name="attendanceGoals"
                  value={form.values.attendanceGoals}
                  onChange={form.handleChange}
                  className="formTextarea"
                  placeholder="Ex: Realizar vendas, captar leads, agendar demonstrações, esclarecer dúvidas..."
                />
              </FormField>

              <FormField
                label="Diferenciais do Negócio"
                description="Liste os principais pontos que fazem seu negócio se destacar e como o atendente deve enfatizá-los"
              >
                <textarea
                  name="keyDifferentials"
                  value={form.values.keyDifferentials}
                  onChange={form.handleChange}
                  className="formTextarea"
                  placeholder="Ex: Atendimento 24h, Entrega expressa, Garantia estendida..."
                />
              </FormField>

              <FormField
                label="Respostas Proibidas"
                description="Liste temas ou tipos de respostas que o atendente deve evitar"
              >
                <textarea
                  name="forbiddenResponses"
                  value={form.values.forbiddenResponses}
                  onChange={form.handleChange}
                  className="formTextarea"
                  placeholder="Ex: Não discutir política, não revelar informações confidenciais..."
                />
              </FormField>
              {/* 
                <FormField 
                  label="Fluxos de Conversa"
                  description="Defina os principais fluxos de conversa que o atendente deve seguir"
                >
                  <textarea
                    name="conversationFlows"
                    value={form.values.conversationFlows}
                    onChange={form.handleChange}
                    className="formTextarea"
                    placeholder="Ex: 1. Saudação inicial; 2. Identificar necessidade; 3. Oferecer solução..."
                  />
                </FormField> */}
            </Section>

            {/* Seção 1: Identidade e Comunicação */}
            <Section
              title="Identidade e Comunicação"
              isOpen={openSections.identity}
              onToggle={() => toggleSection("identity")}
            >
              <FormField
                label="Nome do Atendente"
                description="Nome que será usado pelo atendente virtual"
              >
                <div>
                  <input
                    type="text"
                    name="nameAttendant"
                    value={form.values.nameAttendant}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className={`formInput ${
                      form.touched.nameAttendant && form.errors.nameAttendant
                        ? "border-error"
                        : ""
                    }`}
                    placeholder="Ex: Maria, João..."
                  />
                  {form.touched.nameAttendant && form.errors.nameAttendant && (
                    <div className="error">{form.errors.nameAttendant}</div>
                  )}
                </div>
              </FormField>

              {/* <FormField
                label="Tom de Voz"
                description="Escolha o estilo de comunicação do atendente"
              >
                <select
                  name="toneVoice"
                  value={form.values.toneVoice}
                  onChange={form.handleChange}
                  multiple
                  className="formInput"
                >
                  {toneArray.map((tone, index) => (
                    <option key={index} value={tone}>
                      {tone}
                    </option>
                  ))}
                </select>
              </FormField> */}

              <FormField
                label="Tom de Voz"
                description="Escolha o estilo de comunicação do atendente"
              >
                <div>
                  <select
                    name="toneVoice"
                    value={form.values.toneVoice}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className={`formInput ${
                      form.touched.toneVoice && form.errors.toneVoice
                        ? "border-error"
                        : ""
                    }`}
                  >
                    {toneArray.map((tone, index) => (
                      <option key={index} value={tone}>
                        {tone}
                      </option>
                    ))}
                  </select>
                  {form.touched.toneVoice && form.errors.toneVoice && (
                    <div className="error">{form.errors.toneVoice}</div>
                  )}
                </div>
              </FormField>

              {form.values.toneVoice.includes("Outro") && (
                <FormField
                  label="Descrição do Tom de Voz Personalizado"
                  description="Descreva o tom de voz específico desejado"
                >
                  <input
                    type="text"
                    name="toneVoiceExtra"
                    value={form.values.toneVoiceExtra}
                    onChange={form.handleChange}
                    className="formInput"
                    placeholder="Ex: Linguagem técnica específica do setor..."
                  />
                </FormField>
              )}

              <FormField label="Idioma de Resposta">
                <div>
                  <select
                    name="preferentialLanguage"
                    value={form.values.preferentialLanguage}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className={`formInput ${
                      form.touched.preferentialLanguage &&
                      form.errors.preferentialLanguage
                        ? "border-error"
                        : ""
                    }`}
                  >
                    <option value="">Selecione o idioma</option>
                    <option value="Português Brasileiro">
                      Português Brasileiro
                    </option>
                    <option value="Inglês">Inglês</option>
                    <option value="Japonês">Japonês</option>
                    <option value="Mandarim">Mandarim</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Espanhol">Espanhol</option>
                  </select>
                  {form.touched.preferentialLanguage &&
                    form.errors.preferentialLanguage && (
                      <div className="error">
                        {form.errors.preferentialLanguage}
                      </div>
                    )}
                </div>
              </FormField>
            </Section>

            {/* Seção 2: Informações do Negócio */}
            <Section
              title="Informações do Negócio"
              isOpen={openSections.business}
              onToggle={() => toggleSection("business")}
            >
              <FormField
                label="Nome Fantasia"
                description="Nome que o atendente usará para se referir ao seu negócio"
              >
                <div>
                  <input
                    type="text"
                    name="companyName"
                    value={form.values.companyName}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className={`formInput ${
                      form.touched.companyName && form.errors.companyName
                        ? "border-error"
                        : ""
                    }`}
                    placeholder="Nome da sua empresa"
                  />
                  {form.touched.companyName && form.errors.companyName && (
                    <div className="error">{form.errors.companyName}</div>
                  )}
                </div>
              </FormField>

              {/* <FormField 
                  label="Campo de Atuação"
                  description="Área principal de atuação do seu negócio"
                >
                  <input
                    type="text"
                    name="servedRegion"
                    value={form.values.servedRegion}
                    onChange={form.handleChange}
                    className="formInput"
                    placeholder="Ex: Tecnologia, Saúde..."
                  />
                </FormField> */}

              <FormField
                label="Nicho Específico"
                description="Especificação detalhada do seu nicho de mercado"
              >
                <div>
                  <input
                    type="text"
                    name="companyNiche"
                    value={form.values.companyNiche}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className={`formInput ${
                      form.touched.companyNiche && form.errors.companyNiche
                        ? "border-error"
                        : ""
                    }`}
                    placeholder="Ex: Desenvolvimento Web, Nutrição Esportiva..."
                  />
                  {form.touched.companyNiche && form.errors.companyNiche && (
                    <div className="error">{form.errors.companyNiche}</div>
                  )}
                </div>
              </FormField>

              <FormField
                label="Site do Negócio"
                description="Endereço do seu site principal"
              >
                <div>
                  <input
                    type="url"
                    name="companyContact"
                    value={form.values.companyContact}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className={`formInput ${
                      form.touched.companyContact && form.errors.companyContact
                        ? "border-error"
                        : ""
                    }`}
                    placeholder="https://..."
                  />
                  {form.touched.companyContact &&
                    form.errors.companyContact && (
                      <div className="error">{form.errors.companyContact}</div>
                    )}
                </div>
              </FormField>
            </Section>

            {/* Seção 3: Produtos e Serviços */}
            <Section
              title="Produtos e Serviços"
              isOpen={openSections.products}
              onToggle={() => toggleSection("products")}
            >
              <FormField
                label="Produtos"
                description="Liste os principais produtos do seu negócio"
              >
                <textarea
                  name="companyProducts"
                  value={form.values.companyProducts}
                  onChange={form.handleChange}
                  className="formTextarea"
                  placeholder="Descreva seus produtos..."
                />
              </FormField>

              <FormField
                label="Serviços"
                description="Descreva os serviços oferecidos"
              >
                <textarea
                  name="companyServices"
                  value={form.values.companyServices}
                  onChange={form.handleChange}
                  className="formTextarea"
                  placeholder="Descreva seus serviços..."
                />
              </FormField>
            </Section>

            {/* Seção 4: Canais de Comunicação */}
            <Section
              title="Canais de Comunicação"
              isOpen={openSections.strategy}
              onToggle={() => toggleSection("strategy")}
            >
              <FormField
                label="Redes Sociais"
                description="Insira os links das suas redes sociais"
              >
                <div className="space-y-3">
                  <FormField label="Instagram">
                    <input
                      type="url"
                      name="instagramLink"
                      value={form.values.instagramLink}
                      onChange={form.handleChange}
                      className="formInput"
                      placeholder="Ex: https://instagram.com/suaempresa"
                    />
                  </FormField>

                  <FormField label="Facebook">
                    <input
                      type="url"
                      name="facebookLink"
                      value={form.values.facebookLink}
                      onChange={form.handleChange}
                      className="formInput"
                      placeholder="Ex: https://facebook.com/suaempresa"
                    />
                  </FormField>

                  <FormField label="X (Twitter)">
                    <input
                      type="url"
                      name="twitterXLink"
                      value={form.values.twitterXLink}
                      onChange={form.handleChange}
                      className="formInput"
                      placeholder="Ex: https://x.com/suaempresa"
                    />
                  </FormField>

                  <FormField label="YouTube">
                    <input
                      type="url"
                      name="youtubeLink"
                      value={form.values.youtubeLink}
                      onChange={form.handleChange}
                      className="formInput"
                      placeholder="Ex: https://youtube.com/c/suaempresa"
                    />
                  </FormField>
                </div>
              </FormField>
            </Section>

            {/* Seção 5: Arquivos */}
            <Section
              title="Arquivos"
              isOpen={openSections.files}
              onToggle={() => toggleSection("files")}
            >
              <FormField
                label="Upload de Arquivos"
                description="Arraste e solte arquivos ou clique para fazer upload"
              >
                {loadingFile ? (
                  <div className="dragDropArea">
                    <svg
                      className="loading-spinner"
                      width="20"
                      height="20"
                      viewBox="0 0 50 50"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="4"
                    >
                      <circle cx="25" cy="25" r="20" strokeOpacity="0.5" />
                      <path
                        d="M25 5a20 20 0 0 1 20 20"
                        className="spinner-path"
                      />
                    </svg>
                  </div>
                ) : (
                  <div
                    className="dragDropArea"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      multiple
                      onChange={(event) => handleFileUpload(event.target.files)}
                    />
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                      <polyline points="17 8 12 3 7 8" />
                      <line x1="12" y1="3" x2="12" y2="15" />
                    </svg>
                    <p>Arraste seus arquivos aqui ou clique para selecionar</p>
                  </div>
                )}

                <div className="fileList">
                  {listFiles?.map((file) => (
                    <CardFileItens file={file} />
                  ))}
                </div>
              </FormField>
            </Section>

            <div className="buttonContainer">
              <Button
                type="submit"
                variant="filled"
                disabled={saveLoading}
                icon={<Save />}
              >
                {saveLoading ? "Salvando..." : "Salvar Configurações"}
              </Button>
            </div>
          </form>
        </div>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default Settings;
