import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import api from "../../../utils/api";
import moment from "moment";

export const useFollowUpConfig = () => {
  const [config, setConfig] = useState({
    followUpHours: 24,
    dontDisturbFollowUp: false,
    dontDisturbFollowUpDispatchStart: "",
    dontDisturbFollowUpDispatchEnd: "",
    dontDisturbFollowUpWeekDays: [],
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [id, setId] = useState(null);

  const getConfig = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/partners/automatic_message_configs");
      if (data?.data) {
        setId(data?.data.id);
        setConfig({
          id: Number(data?.data.id),
          ...data?.data.attributes,
          dontDisturbFollowUpDispatchStart:
            moment(data.data.attributes.dontDisturbFollowUpDispatchStart)
              ?.utc()
              .format("HH:mm:ss") || null,
          dontDisturbFollowUpDispatchEnd:
            moment(data.data.attributes.dontDisturbFollowUpDispatchEnd)
              ?.utc()
              .format("HH:mm:ss") || null,
        });
      }
    } catch (error) {
      console.error("Erro ao carregar configurações:", error);
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const updateConfig = useCallback((key, value) => {
    setConfig((prevConfig) => ({ ...prevConfig, [key]: value }));
  }, []);

  const handleSave = useCallback(async () => {
    setSaving(true);
    try {
     if (id) {
      await api.put(`/partners/automatic_message_configs/${config.id}`, {
        data: {
          attributes: {
            ...config,
          },
        },
      });
     } else {
      await api.post(`/partners/automatic_message_configs`, {
        data: {
          attributes: {
            ...config,
          },
        },
      });
     }
      toast.success("Configurações salvas com sucesso!");
      setShowConfirmModal(false);
    } catch (error) {
      console.error("Erro ao salvar configurações:", error);
      toast.error("Erro ao salvar configurações. Por favor, tente novamente.");
    } finally {
      setSaving(false);
    }
  }, [config]);

  return {
    config,
    loading,
    saving,
    updateConfig,
    handleSave,
    showConfirmModal,
    setShowConfirmModal,
  };
};
