import React from 'react';
import './GeistTableFilter.css';
import { Search, Filter, Share2, UserPlus } from '@geist-ui/icons';
import Button from '../Button';
import FormInput from '../FormInput';

const GeistTableFilter = ({
  searchTerm,
  onSearchChange,
  onSearchClear,
  filterKeys,
  selectedFilters,
  onFilterChange,
  onExport,
  exportData,
  addButton,
  labelINput="Buscar..."
}) => {
  return (
    <div className="geist-table-filter">
      <div className="geist-table-filter-search">
        <FormInput
          id="table-search"
          name="table-search"
          label="Buscar"
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder={labelINput}
          width="100%"
          className="geist-table-filter-input form-input-search"
          // Esconde o label visualmente mas mantém para acessibilidade
          style={{
            '--input-height': '36px'
          }}
        />
        {searchTerm && (
          <button
            className="geist-table-filter-clear"
            onClick={onSearchClear}
            aria-label="Limpar busca"
          >
            ×
          </button>
        )}
      </div>
      <div className="geist-table-filter-actions">
        {/* <Button
          variant="outline"
          onClick={() => onFilterChange(selectedFilters)}
          icon={<Filter size={18} />}
        >
          Filtros
        </Button> */}
        {onExport && (
          <Button
            variant="outline"
            onClick={() => onExport(exportData)}
            icon={<Share2 size={18} />}
          >
            Exportar
          </Button>
        )}
        {addButton && (
          <Button
            variant="outline"
            onClick={addButton.onClick}
            icon={<UserPlus size={18} color="#539d65" />}
          >
            {addButton.label || 'Adicionar'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GeistTableFilter;
