import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Card from "../../../../components/Card";
import Button from "../../../../components/Button";
import arrow from "../../../../assets/Vector.png";

const SubscriptionCard = ({ subscription, checkPlan, navigate }) => (
  <Card className="cardFinancePartner">
    <div className="title">{subscription?.name}</div>
    <div className="containerText" style={{ display: 'flex', alignItems: 'center' }}>
      <strong>Data de contratação:</strong>{" "}
      {moment(subscription?.payDate).format("DD/MM/YYYY")}
    </div>
    <div className="containerText" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <strong>Válido até:</strong>{" "}
      {moment(subscription?.payValidate).format("DD/MM/YYYY")}
    </div>
    <Button
      onClick={() => navigate("/financeiro/step1")}
      variant="outline"
    >
      {checkPlan ? "Adquirir Plano" : "Alterar Plano"}
      {/* <img src={arrow} alt="arrow" className="imgBUtonNex" /> */}
    </Button>
  </Card>
);

SubscriptionCard.propTypes = {
  subscription: PropTypes.shape({
    name: PropTypes.string,
    payDate: PropTypes.string,
    payValidate: PropTypes.string,
  }),
  checkPlan: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default SubscriptionCard;