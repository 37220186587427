import React, { useContext, useEffect } from "react";
import NewPlanContext from "../Context/NewPlanContext";
import { useNavigate } from "react-router-dom";

import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";
import arrow from "../../../../assets/Vector.png";

import "./Step2.css";
import MainCard from "../../../../components/MainCard";
import { Text } from "@geist-ui/core";
import FormInput from "../../../../components/FormInput";
import Card from "../../../../components/Card";
import Button from "../../../../components/Button";

const Step2 = () => {
  const navigate = useNavigate();
  const { step1Form, step2Form, saveCard } = useContext(NewPlanContext);

  useEffect(() => {
    if (step1Form.values.title === "Plano Gratuito") {
      navigate("/financeiro/step3");
    } else if (step1Form.values.title === "") {
      navigate("/financeiro/step1");
    }
  }, [navigate, step1Form.values.title]);

  return (
    <AuthorizedLayout>
      <MainCard variant="minimal" flex>
        <Card>
          <Text h3>OPÇÕES DE PAGAMENTO</Text>
          <form onSubmit={step2Form.handleSubmit} style={{ marginTop: "20px" }}>
            <FormInput
              label="Nome do Titular do Cartão"
              name="cardFullName"
              value={step2Form.values.cardFullName}
              onChange={step2Form.handleChange}
              onBlur={step2Form.handleBlur}
              error={
                step2Form.touched.cardFullName && step2Form.errors.cardFullName
                  ? step2Form.errors.cardFullName
                  : null
              }
              validate={
                step2Form.touched.cardFullName && step2Form.errors.cardFullName
              }
              placeholder="Digite o nome do Titular do Cartão"
              width="100%"
            />

            <FormInput
              label="Número do Cartão"
              name="cardNumber"
              value={step2Form.values.cardNumber}
              onChange={step2Form.handleChange}
              onBlur={step2Form.handleBlur}
              error={
                step2Form.touched.cardNumber && step2Form.errors.cardNumber
                  ? step2Form.errors.cardNumber
                  : null
              }
              validate={
                step2Form.touched.cardNumber && step2Form.errors.cardNumber
              }
              mask={"**** **** **** 9999"}
              placeholder="1234 1234 1234 1234"
              width="100%"
            />

            <FormInput
              label="Validade"
              name="cardExpirationDate"
              value={step2Form.values.cardExpirationDate}
              onChange={step2Form.handleChange}
              onBlur={step2Form.handleBlur}
              error={
                step2Form.touched.cardExpirationDate &&
                step2Form.errors.cardExpirationDate
                  ? step2Form.errors.cardExpirationDate
                  : null
              }
              validate={
                step2Form.touched.cardExpirationDate &&
                step2Form.errors.cardExpirationDate
              }
              mask={"99/99"}
              placeholder="10/32"
              width="100%"
            />

            <FormInput
              label="Código de Segurança"
              name="cardCVV"
              value={step2Form.values.cardCVV}
              onChange={step2Form.handleChange}
              onBlur={step2Form.handleBlur}
              error={
                step2Form.touched.cardCVV && step2Form.errors.cardCVV
                  ? step2Form.errors.cardCVV
                  : null
              }
              validate={step2Form.touched.cardCVV && step2Form.errors.cardCVV}
              mask={"999"}
              placeholder="999"
              width="100%"
            />

            <div className="buttonContainer">
              <Button
                variant="outline"
                onClick={() => navigate("/financeiro/step1")}
              >
                Voltar
              </Button>
              <Button
                variant="filled"
                onClick={step2Form.handleSubmit}
                className="buttonNextCRedCard"
              >
                {saveCard ? "Carregando..." : <>Próximo</>}
              </Button>
            </div>
          </form>
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default Step2;
