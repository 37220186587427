import React from 'react';
import PropTypes from 'prop-types';
import './MainCard.css';

/**
 * MainCard - Componente de card com suporte a variantes de estilo
 * 
 * @param {ReactNode} children - Conteúdo do card
 * @param {boolean} flex - Habilita layout flex
 * @param {string} columnWidth - Largura personalizada
 * @param {string} variant - Variante de estilo ('default' ou 'minimal')
 */
const MainCard = ({ children, flex, columnWidth, variant = 'default' }) => {
  const style = columnWidth ? { width: columnWidth } : {};
  
  const containerClassName = [
    'mainContainer',
    flex ? 'flex' : '',
    variant === 'minimal' ? 'minimal' : '',
  ].filter(Boolean).join(' ');

  const cardClassName = [
    'mainCard',
    flex ? 'flex' : '',
    variant === 'minimal' ? 'minimal' : '',
  ].filter(Boolean).join(' ');

  return (
    <div className={containerClassName} style={style}>
      <div className={cardClassName}>
        {children}
      </div>
    </div>
  );
};

MainCard.propTypes = {
  children: PropTypes.node.isRequired,
  flex: PropTypes.bool,
  columnWidth: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'minimal']),
};

MainCard.defaultProps = {
  flex: false,
  variant: 'default',
};

export default MainCard;