import React, { useContext } from "react";
import "./Step2.css";
import { FluxoAddProviderContext } from "./FluxoAddProvider";
import { Info, Plus, Upload, Download, FileText, Trash } from "@geist-ui/icons";
import Stepper from "./components/Stepper";
import InputMask from "react-input-mask";
import Button from "../../../../components/Button";

const Step2 = () => {
  const {
    formSchedules,
    setCurrentStep,
    handleAddPhoneNumber,
    phoneNumbers,
    handleDeletePhoneNumber,
    loading,
    loadingCsv,
    handleFileChange,
    getCsv,
    setPhone,
    phone,
    countryCode,
    setCountryCode,
    countryFlags,
    handleInput,
    getSumary,
  } = useContext(FluxoAddProviderContext);

  const handlePhoneChange = (value) => {
    // Extrai o código do país
    const match = value.match(/^\+(\d{1,3})/); // Busca por + seguido de 1 a 3 dígitos
    if (match) {
      const code = match[1];
      setCountryCode(code);
    }
    setPhone(value);
  };

  function extractCountryCode(phoneNumber) {
    const match = phoneNumber?.match(/^\+(\d+)/);
    return match ? match[1] : null;
  }

  function extractPhoneWithoutCountryCode(phoneNumber) {
    // Remove espaços extras e mantém somente o '+' e números
    const sanitizedNumber = phoneNumber?.trim() || "";

    // Encontra o código do país e separa o restante
    const match = sanitizedNumber?.match(/^\+\d+\s?(.*)/);
    return match ? match[1] : null; // Retorna o restante do número ou null se não encontrar
  }

  console.log(phoneNumbers);

  return (
    <>
      {loading ? (
        <div className="loading-container">
          <div className="loading-content">
            <div>Conectando...</div>
          </div>
        </div>
      ) : (
        <div className="container">
          <Stepper currentStep={2} />
          <div className="card">
            <div className="card-content">
              <div className="sections-grid">
                {/* Upload de Planilha */}
                <div className="section">
                  <h3 className="section-title">Importar Contatos</h3>
                  <div className="upload-section">
                    <div className="upload-content">
                      {!formSchedules.values.fileCsv ? (
                        <>
                          <Upload className="upload-icon" />
                          <div className="upload-text">
                            <h4>
                              Arraste sua planilha ou clique para selecionar
                            </h4>
                            <p>
                              Formato aceito: XLSX. Máximo 5.000 contatos por
                              vez
                            </p>
                            <button
                              className="button button-link"
                              onClick={getCsv}
                            >
                              <Download className="button-icon" />
                              {loadingCsv
                                ? "Baixando..."
                                : "Baixar modelo de planilha"}
                            </button>
                            <div className="divider">
                              <div className="divider-line" />
                              <span>ou</span>
                              <div className="divider-line" />
                            </div>

                            <button
                              className="button button-outline"
                              onClick={() =>
                                document.getElementById("file-input").click()
                              }
                            >
                              <Upload className="button-icon" />
                              Selecionar arquivo
                            </button>
                            <input
                              id="file-input"
                              type="file"
                              className="hidden"
                              accept=".xlsx"
                              onChange={handleFileChange}
                            />
                           
                          </div>
                        </>
                      ) : (
                        <div className="file-preview">
                          <div className="file-info">
                            <FileText className="file-icon" />
                            <div>
                              <p className="file-name">
                                {formSchedules.values.fileCsv.name}
                              </p>
                              <p className="file-size">
                                {(
                                  formSchedules.values.fileCsv.size / 1024
                                ).toFixed(2)}{" "}
                                KB
                              </p>
                            </div>
                          </div>
                          <button
                            className="button-icon-only"
                            onClick={() =>
                              formSchedules.setFieldValue("fileCsv", null)
                            }
                          >
                            <Trash className="icon-small" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Adição Manual */}
                <div className="section">
                  <h3 className="section-title">
                    Adicionar Contatos Manualmente
                  </h3>
                  {/* <div className="info-box">
                    <Info className="info-icon" />
                    <div>
                      <p className="info-title">Parâmetros necessários</p>
                      <p>Ao adicionar contatos manualmente, você precisará preencher os seguintes parâmetros:</p>
                      <div className="params-list">
                        <p>• {'{NOME}'} - Nome do cliente</p>
                        <p>• {'{EMPRESA}'} - Nome da empresa</p>
                      </div>
                    </div>
                  </div>
                   */}

                  <div className="info-box">
                    <Info className="info-icon" />
                    <div>
                      <p className="info-title">
                        Você também pode adicionar números de telefone de forma
                        manual digitando-os a seguir
                      </p>
                      <p>
                        {" "}
                        Obs: Números adicionados manualmente perdem a
                        personalização de parâmetros que podem ser feitas
                        somente via planilha.
                      </p>
                    </div>
                  </div>

                  <div className="manual-input-section">
                    <div className="input-grid">
                      <div className="input-group">
                        <label>Telefone</label>
                        <div className="phone-input">
                          <img
                            src={
                              countryFlags[countryCode] || countryFlags["55"]
                            }
                            alt="Country flag"
                            className="country-flag"
                          />
                          <InputMask
                            className="input"
                            mask={"+99 99 99999-9999"}
                            value={phone}
                            onChange={(e) => handlePhoneChange(e.target.value)}
                            placeholder="+55 (11) 98765-4321"
                          />
                        </div>
                      </div>

                      {/* <div className="input-group">
                        <label>Nome do Cliente</label>
                        <input
                          className="input"
                          type="text"
                          placeholder="Ex: João Silva"
                          value={formSchedules.values.param1 || ''}
                          onChange={handleInput}
                          name="param1"
                        />
                      </div>

                      <div className="input-group">
                        <label>Nome da Empresa</label>
                        <input
                          className="input"
                          type="text"
                          placeholder="Ex: Tech Corp"
                          value={formSchedules.values.param2 || ''}
                          onChange={handleInput}
                          name="param2"
                        />
                      </div> */}

                      <div className="input-group full-width">
                        <button
                          className="button button-primary full-width"
                          onClick={handleAddPhoneNumber}
                          disabled={!phone}
                        >
                          <Plus className="button-icon" />
                          Adicionar Contato
                        </button>
                      </div>
                    </div>

                    {phoneNumbers.length > 0 && (
                      <div className="contacts-list">
                        <h4>Contatos adicionados ({phoneNumbers.length})</h4>
                        <div className="contacts-grid">
                          {phoneNumbers?.map((number, index) => (
                            <div key={index} className="contact-item">
                              <div className="contact-info">
                                <img
                                  src={
                                    countryFlags[
                                      extractCountryCode(number.phone)
                                    ] || "https://flagcdn.com/w320/un.png"
                                  }
                                  alt="Country flag"
                                  className="contact-flag"
                                />
                                <span>
                                  {extractPhoneWithoutCountryCode(number.phone)}
                                </span>
                              </div>
                              <button
                                className="button-icon-only"
                                onClick={() =>
                                  handleDeletePhoneNumber(
                                    number?.phone,
                                    number?.id
                                  )
                                }
                              >
                                <Trash className="icon-small" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Botões de Navegação */}
              <div className="navigation-buttons">
                <Button
                  variant="outline"
                  onClick={() => {
                    setCurrentStep("step1");
                  }}
                >
                  Voltar
                </Button>

                <Button
                  variant="filled"
                  onClick={() => {
                    formSchedules.handleSubmit();
                    getSumary();
                    setCurrentStep("step3");
                  }}
                >
                  Próximo
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Step2;
