import { Box, Button, Grid, Modal, IconButton } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export default function ModalAlert({ children, handleClose, handleSubmit, open, loading = false }) {
  const isMobileDevice = () => window.innerWidth <= 768;
  const modalWidth = isMobileDevice() ? 350 : 515;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: modalWidth,
          bgcolor: "background.paper",
          boxShadow: 3,
          borderRadius: 2,
          p: 3,
          outline: "none",
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <IconButton disabled>
              <WarningAmberIcon color="warning" sx={{ fontSize: 40 }} />
            </IconButton>
          </Grid>
          <Grid item xs>
            {children}
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="error"
            >
              Não
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                handleSubmit();
                handleClose(false);
              }}
              variant="contained"
            >
              {loading ?  <svg
                className="loading-spinner"
                width="20"
                height="20"
                viewBox="0 0 50 50"
                fill="none"
                stroke="currentColor"
                strokeWidth="4"
              >
                <circle cx="25" cy="25" r="20" strokeOpacity="0.5" />
                <path d="M25 5a20 20 0 0 1 20 20" className="spinner-path" />
              </svg> : 'Sim'}
              
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
