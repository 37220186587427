import React, { useState } from 'react';
import { Table, Loading, Text } from '@geist-ui/core';
import { ChevronUp, ChevronDown } from '@geist-ui/icons';
import './GeistTable.css';

export const TableUser = ({ name, email, avatar }) => {
  return (
    <div className="geist-table-user">
      <img 
        src={avatar} 
        alt={name} 
        className="geist-table-user-avatar" 
        style={{ 
          width: '32px', 
          height: '32px', 
          maxWidth: '32px', 
          minWidth: '32px',
          flexShrink: 0,
          display: 'block'
        }} 
      />
      <div className="geist-table-user-info">
        <span className="geist-table-user-name">{name || 'No Name'}</span>
        <span className="geist-table-user-email">{email || 'No Email'}</span>
      </div>
    </div>
  );
};

export const TableBadge = ({ value, type = 'default' }) => (
  <span className={`geist-table-badge ${type}`}>
    {value}
  </span>
);

/**
 * Renderiza uma tag/etiqueta na tabela
 * @param {string} value - Texto da tag
 * @param {string} variant - Variante de estilo (default, success, warning, error, info)
 * @param {string} size - Tamanho da tag (sm, md, lg)
 * @param {function} onClick - Função de callback para clique (opcional)
 */
const renderTag = (value, variant = 'default', size = 'sm', onClick) => {
  const className = `geist-table-tag geist-table-tag--${variant} geist-table-tag--${size} ${onClick ? 'geist-table-tag--clickable' : ''}`;
  
  return (
    <span
      className={className}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? 0 : undefined}
    >
      {value}
    </span>
  );
};

const GeistTable = ({ 
  columns, 
  data, 
  loading,
  emptyText = "Nenhum dado encontrado",
  className,
  style
}) => {
  const [sortConfig, setSortConfig] = useState({ field: null, direction: 'asc' });

  const handleSort = (field) => {
    const direction = sortConfig.field === field && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ field, direction });
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig.field) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.field] < b[sortConfig.field]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.field] > b[sortConfig.field]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);

  if (loading) {
    return (
      <div className="geist-table-loading">
        <Loading>Carregando...</Loading>
      </div>
    );
  }

  if (!data?.length) {
    return (
      <div className="geist-table-empty">
        <Text>{emptyText}</Text>
      </div>
    );
  }

  const renderCell = (value, rowData, col) => {
    if (col.render) {
      return col.render(value, rowData);
    }

    if (col.tag) {
      return renderTag(
        value,
        typeof col.tag === 'function' ? col.tag(value, rowData) : col.tag.variant,
        col.tag.size || 'sm',
        col.tag.onClick
      );
    }

    if (col.field === 'lead') {
      return (
        <TableBadge 
          value={value} 
          type={value === 'Inconclusivo' ? 'default' : 'success'} 
        />
      );
    }

    return value;
  };

  return (
    <div className="geist-table-container">
      <Table 
        data={sortedData} 
        className={`geist-table ${className || ''}`}
        style={{
          ...style,
          '--table-img-width': '32px',
          '--table-img-height': '32px'
        }}
        width="100%"
        scale={1}
        hover={true}
      >
        {columns.map((col, index) => (
          <Table.Column
            key={index}
            prop={col.field}
            width={col.width || 'auto'}
            label={
              col.sortable ? (
                <div 
                  style={{ 
                    cursor: 'pointer', 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: '4px',
                    height: '100%'
                  }}
                  onClick={() => handleSort(col.field)}
                >
                  {col.label}
                  {sortConfig.field === col.field && (
                    sortConfig.direction === 'asc' ? 
                      <ChevronUp size={14} className="geist-table-sort-icon" /> : 
                      <ChevronDown size={14} className="geist-table-sort-icon" />
                  )}
                </div>
              ) : col.label
            }
            render={(value, rowData) => (
              <div className={`geist-table-cell-content ${col.field === 'actions' ? 'actions' : ''}`}>
                {renderCell(value, rowData, col)}
              </div>
            )}
          />
        ))}
      </Table>
    </div>
  );
};

export default GeistTable;
