import React from "react";
import PropTypes from "prop-types";

// Componentes
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import { Loader } from "../../../components/Loader";
import { MainContentContainer } from "../../../components/MainContentContainer";
import MainCard from "../../../components/MainCard";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import ModalAlert from "../../../components/ModalAlert";
import DoNotDisturb from "../../../components/DoNotDisturb/DoNotDisturb";

// Hooks e estilos
import { useFollowUpConfig } from "./useFollowUpConfig";
import "./FollowUP.css";
import FormInput from "../../../components/FormInput";
import "./FormInput.css";

function isMobileDevice() {
  return window.innerWidth <= 768;
}

// Componente para input de horas de follow-up
const FollowUpHoursInput = ({ value, onChange }) => (
  <FormInput
    label="Horas de espera antes de enviar a próxima mensagem"
    type="number"
    fieldName={value}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="migrated-form-input"
    width="100%"
    min={1}
    max={168}
  />
);

FollowUpHoursInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FollowUP = () => {
  const {
    config,
    loading,
    saving,
    updateConfig,
    handleSave,
    showConfirmModal,
    setShowConfirmModal,
  } = useFollowUpConfig();

  return (
    <AuthorizedLayout>
      <MainCard flex>
        {/* Seção de introdução */}
        <Card title="Follow up" width={isMobileDevice() ? "100%" : "100%"}>
          <p className="subTitleInput">
            Mantenha o contato com seus clientes de forma inteligente.
          </p>
          <p className="subTitleInput">
            Com o Follow up, você pode enviar mensagens automáticas para seus
            clientes após um período de inatividade. Isso ajuda a reativar
            conversas, responder dúvidas e aumentar suas chances de conversão.
          </p>
          <FollowUpHoursInput
            value={config.followUpHours}
            onChange={(value) => updateConfig("followUpHours", Number(value))}
          />
          <p className="subTitleInput">
            Exemplo: Se um cliente não responder por 24 horas, enviar uma nova
            mensagem.
          </p>
        </Card>

        {/* Configuração de "Não perturbe" */}
        <Card>
          <DoNotDisturb
            enabled={config.dontDisturbFollowUp}
            onEnabledChange={(enabled) =>
              updateConfig("dontDisturbFollowUp", enabled)
            }
            startTime={config.dontDisturbFollowUpDispatchStart}
            onStartTimeChange={(time) =>
              updateConfig("dontDisturbFollowUpDispatchStart", time)
            }
            endTime={config.dontDisturbFollowUpDispatchEnd}
            onEndTimeChange={(time) =>
              updateConfig("dontDisturbFollowUpDispatchEnd", time)
            }
            selectedDays={config.dontDisturbFollowUpWeekDays}
            onDaySelect={(days) =>
              updateConfig("dontDisturbFollowUpWeekDays", days)
            }
            title="Não perturbe"
            description="Defina horários e dias de intervalo antes de enviar a próxima mensagem."
          />
        </Card>

        {/* Botão de salvar */}
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Button
            onClick={() => setShowConfirmModal(true)}
            disabled={saving}
            style={{ marginTop: "32px" }}
            variant="filled"
          >
            {saving ? "Salvando..." : "Salvar alterações"}
          </Button>
        </div>
      </MainCard>

      {/* Modal de confirmação */}
      <ModalAlert
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleSubmit={handleSave}
        title="Confirmar alterações"
        message="Tem certeza que deseja salvar as alterações nas configurações de Follow Up?"
        confirmText="Sim, salvar"
        cancelText="Cancelar"
      >
        Tem certeza que deseja salvar as alterações nas configurações de Follow
        Up?
      </ModalAlert>
    </AuthorizedLayout>
  );
};

export default FollowUP;
