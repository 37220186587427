import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import { MainContentContainer } from "../../../components/MainContentContainer";
import MainCard from "../../../components/MainCard";
import { Loader } from "../../../components/Loader";
import TableTransactions from "./Components/TableTransactions";

import { useFinancePartner } from "./useFinancePartner";
import SubscriptionCard from "./Components/SubscriptionCard";
import TokenCard from "./Components/TokenCard";
import CreditCardInfo from "./Components/CreditCardInfo";

import "./FinancePartner.css";

const FinancePartner = () => {
  const navigate = useNavigate();
  const {
    loading,
    loadingTable,
    transactions,
    subscription,
    metrics,
    isCardExpired
  } = useFinancePartner();

  const checkPlan = subscription?.name === "Plano Gratuito";

  return (
    <AuthorizedLayout>
      <MainCard variant="minimal" flex>
       
            <div className="headerFinancePartner">
              <SubscriptionCard subscription={subscription} checkPlan={checkPlan} navigate={navigate} />
              <TokenCard metrics={metrics} navigate={navigate} />
            </div>
            <div className="containerSumary">
              <CreditCardInfo subscription={subscription} isCardExpired={isCardExpired} navigate={navigate} />
              <TableTransactions
                transactions={transactions}
                loadingTable={loadingTable}
              />
            </div>
     
      </MainCard>
    </AuthorizedLayout>
  );
};

FinancePartner.propTypes = {
  // Add any props if needed in the future
};

export default FinancePartner;
