import React, { useContext, useEffect, useState } from "react";
import { Grid, Loading, Text, useToasts } from "@geist-ui/core";
import { MessageSquare } from "@geist-ui/icons";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import MainCard from "../../../components/MainCard";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import Papa from "papaparse";
import "./Clients.css";
import iconUser from "../../../assets/noAvatar.svg";
import GeistTable, {
  TableUser,
  TableBadge,
} from "../../../components/GeistTable/GeistTable";
import GeistPagination from "../../../components/GeistPagination/GeistPagination";
import GeistTableFilter from "../../../components/GeistTableFilter/GeistTableFilter";

const Clients = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { setToast } = useToasts();

  // Estados
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [typeFilter, setTypeFilter] = useState("");
  const [filterKeys, setFilterKeys] = useState([
    { label: "ID", value: "id", className: "id" },
    { label: "Nome", value: "name", className: "name" },
    { label: "Telefone", value: "phoneNumber", className: "phone" },
    { label: "Interesse", value: "lead", className: "interest" },
    {
      label: "Último Contato",
      value: "last-contact",
      className: "last-contact",
    },
  ]);

  const getClients = async (page = 1, perPage = 10, search = "") => {
    setClients([]);
    setLoading(true);
    try {
      // Add query parameters for pagination and search
      const params = new URLSearchParams({
        page: page,
        per_page: perPage,
      });

      // Add search parameter if there's a search term
      if (search) {
        params.append("search", search);
      }

      const { data } = await api.get(
        `partners/partner_clients?${params.toString()}`
      );

      if (data) {
        setClients(
          data.data.map((item) => ({
            ...item.attributes,
            id: Number(item.id),
            name: item.attributes?.name || "Nome não informado",
            phoneNumber: item.attributes?.phone || "Número não informado",
            email: item.attributes?.email || "Email não informado",
            lead: item.attributes?.leadScore || 0,
            "last-contact":
              moment(item.attributes?.lastMessage).format("DD/MM/YYYY") ||
              "Inconclusivo",
            avatar: item.attributes?.avatar,
          }))
        );

        setTotalItems(data?.meta?.total_pages || 0);
      }
    } catch (error) {
      setToast({
        text: "Não foi possível carregar a lista de clientes.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients(currentPage, itemsPerPage, searchTerm);
  }, [currentPage, itemsPerPage, searchTerm]);

  const filteredData = clients.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = totalItems;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (perPage) => {
    setItemsPerPage(perPage);
    setCurrentPage(1); //
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const generateCSV = (data) => {
    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      Nome: item?.name || "Nome não informado",
      Telefone: item?.phoneNumber || "Número não informado",
      Interesse: item?.lead || "Inconclusivo",
      ÚltimoContato: item?.["last-contact"] || "Inconclusivo",
    }));

    const xlsx = Papa.unparse(formattedData);
    const blob = new Blob([xlsx], { type: "text/xlsx;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "clientes.xlsx");
    link.click();
  };

  return (
    <AuthorizedLayout>
      <MainCard>
        <Card title="Lista de Clientes">
          <Grid.Container gap={0}>
            <Grid xs={24} style={{ marginBottom: "var(--spacing-lg)" }}>
              <GeistTableFilter
                searchTerm={searchTerm}
                onSearchChange={handleSearchChange}
                onSearchClear={() => {
                  handleSearchChange("");
                  setTypeFilter("");
                }}
                filterKeys={filterKeys}
                selectedFilters={filterKeys.map((k) => k.value)}
                onFilterChange={(values) => {
                  setFilterKeys(
                    filterKeys.filter((item) => values.includes(item.value))
                  );
                }}
                onExport={generateCSV}
                exportData={currentItems}
                labelINput={'Digite o nome do cliente'}
              />
            </Grid>

            <Grid
              xs={24}
              style={{ marginTop: 0, width: "100%", display: "flex" }}
            >
              {loading ? (
                <div className="loading-container">
                  <Loading>Carregando clientes...</Loading>
                </div>
              ) : currentItems.length === 0 ? (
                <div className="empty-state">Nenhum cliente encontrado</div>
              ) : (
                <GeistTable
                  columns={[
                    {
                      field: "name",
                      label: "Nome",
                      sortable: true,
                      render: (value, rowData) => (
                        <TableUser
                          name={rowData.name}
                          email={rowData.email}
                          avatar={rowData.avatar || iconUser}
                        />
                      ),
                    },
                    {
                      field: "phoneNumber",
                      label: "Telefone",
                      sortable: true,
                      render: (value) => parsePhoneAdmin(value),
                    },
                    {
                      field: "lead",
                      label: "Interesse",
                      sortable: true,
                      render: (value) => (
                        <div
                          className="scoreValue"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#eafbe7",
                            color: "#539d65",
                            borderRadius: "50%",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          {value}
                        </div>
                      ),
                    },
                    {
                      field: "last-contact",
                      label: "Último Contato",
                      sortable: true,
                    },
                    {
                      field: "actions",
                      label: "Ações",
                      render: (value, rowData) => (
                        <Button
                          icon={<MessageSquare />}
                          variant="outline"
                          onClick={() => navigate(`/chat/${rowData.id}`)}
                        >
                          Chat
                        </Button>
                      ),
                    },
                  ]}
                  data={currentItems}
                  loading={loading}
                  emptyText="Nenhum cliente encontrado"
                  className="geist-table"
                />
              )}
            </Grid>

            <Grid xs={24}>
              <GeistPagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={handlePerPageChange}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                totalPages={totalPages}
              />
            </Grid>
          </Grid.Container>
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default Clients;
