/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../providers/AuthProvider";
import api from "../../../../utils/api";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import unmask from "../../../../utils/unmask";

export const FluxoAddProviderContext = createContext();

export const FluxoAddProvider = ({ children }) => {
  const { user, checkComplete, updatePartnerDetail } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState("step1");
  const { id } = useParams();
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [sumary, setSumary] = useState(null);
  const [modalSchedules, setModalSchedules] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phoneNumbersDeleted, setPhoneNumbersDeleted] = useState([]);
  const [countryCode, setCountryCode] = useState(""); // Código padrão
  const handleAddPhoneNumber = () => {
    if (phone && !phoneNumbers.includes(phone)) {
      setPhoneNumbers([...phoneNumbers, { phone: phone }]);
      setPhone(""); // Limpa o campo após adicionar
      setCountryCode("");
    }
  };

  const handleDeletePhoneNumber = (phoneNUmber, idPhone) => {
    if (idPhone) {
      setPhoneNumbersDeleted([...phoneNumbersDeleted, idPhone]);
      setPhoneNumbers(phoneNumbers.filter((number) => number.id !== idPhone));
    } else {
      setPhoneNumbers(
        phoneNumbers.filter((number) => number.phone !== phoneNUmber)
      );
    }
  };

  const handleDigitAPI = (input) => {
    if (input.startsWith("55")) {
      let num;
      num = input.slice(2);
      return num;
    }
    return input;
  };

  const formSchedules = useFormik({
    initialValues: {
      id: null,
      title: null,
      message: "",
      triggerType: "manual",
      triggerTime: null,
      dontDisturb: false,
      dontDisturbDispatchStart: null,
      dontDisturbDispatchEnd: null,
      dontDisturbWeekDays: [],
      param1: null,
      param2: null,
      param3: null,
      param4: null,
      param5: null,
      fileCsv: null,
      fileTitle: null,
      automaticMessagePhonesAttributes: [],
    },
    onSubmit: () => updateSchedules(),
  });

  const [loadingCsv, setLoadingCsv] = useState(false);

  const getSumary = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/partners/automatic_messages/${id || formSchedules.values.id}`
      );
      if (data.data) {
        setSumary(data?.data?.attributes);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  const getINfos = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/partners/automatic_messages/${id || formSchedules.values.id}`
      );
      if (data.data) {
        console.log(data.data);

        formSchedules.setValues({
          id: data.data.id,
          title: data.data.attributes.title || null,
          message: data.data.attributes.message || null,
          triggerType: data.data.attributes.triggerType || "manual",
          triggerTime: moment(data.data.attributes.triggerTime) || null,
          dontDisturb: data.data.attributes.dontDisturb || false,
          dontDisturbDispatchStart:
            moment(data.data.attributes.dontDisturbDispatchStart) || null,
          dontDisturbDispatchEnd:
            moment(data.data.attributes.dontDisturbDispatchEnd) || null,
          dontDisturbWeekDays: data.data.attributes.dontDisturbWeekDays || [],
          param1: data.data.attributes.param1 || null,
          param2: data.data.attributes.param2 || null,
          param3: data.data.attributes.param3 || null,
          param4: data.data.attributes.param4 || null,
          param5: data.data.attributes.param5 || null,
          automaticMessagePhonesAttributes:
            data.data.attributes.automaticMessagePhones || [],
        });

        const numbers =
        data?.data?.attributes?.automaticMessagePhonesManuallyAdded?.map(
          (phone) => ({ id: phone.id, phone: phone.phone })
        ) || [];

        setPhoneNumbers(numbers);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getINfos();
    }
  }, [id]);

  const getCsv = async () => {
    setLoadingCsv(true);
    try {
      const response = await api.get(
        `/partners/automatic_messages/${formSchedules?.values?.id}/export`,
        {
          responseType: "blob", // Configura o tipo de resposta para 'blob'
        }
      );

      // Cria um objeto URL para o blob retornado
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Define o nome do arquivo
      link.setAttribute("download", "modelo_XLS.xlsx");
      document.body.appendChild(link);
      link.click();

      // Remove o link após o download
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoadingCsv(false);
    }
  };
  

  const updateSchedules = async () => {
    setSaveLoading(true);

    try {
      if (formSchedules?.values?.id) {
        const { data } = await api.put(
          `partners/automatic_messages/${formSchedules?.values?.id}`,
          {
            data: {
              attributes: {
                title: formSchedules?.values?.title || "",
                message: formSchedules?.values?.message || "",
                triggerType: formSchedules?.values?.triggerType || "",
                triggerTime: formSchedules?.values?.triggerTime || "",
                param1: formSchedules?.values?.param1 || "",
                param2: formSchedules?.values?.param2 || "",
                param3: formSchedules?.values?.param3 || "",
                param4: formSchedules?.values?.param4 || "",
                param5: formSchedules?.values?.param5 || "",
                dontDisturb: formSchedules?.values?.dontDisturb || false,
                dontDisturbWeekDays:
                  formSchedules?.values?.dontDisturbWeekDays || "",
                dontDisturbDispatchStart:
                  moment(formSchedules?.values?.dontDisturbDispatchStart)
                    .utc()
                    .format("HH:mm:ss") || "",
                dontDisturbDispatchEnd:
                  moment(formSchedules?.values?.dontDisturbDispatchEnd)
                    .utc()
                    .format("HH:mm:ss") || "",
                automaticMessagePhonesAttributes:
                  phoneNumbers?.length > 0
                    ? [
                        ...new Set(
                          phoneNumbers
                            .filter((phone) => !phone.id)
                            .map((phone) => phone.phone) // Extraindo apenas os valores únicos
                        ),
                      ].map((phone) => ({ phone })) // Mapeando para objetos corretamente
                    : [],
              },
            },
          }
        );

        if (data) {
          if (phoneNumbersDeleted.length > 0) {
            await api.post(`partners/automatic_messages/${id}/delete_phones`, {
              data: { attributes: { phones: phoneNumbersDeleted } },
            });
          }
          if (formSchedules.values.fileCsv) {
            const formData = new FormData();
            formData.append("file", formSchedules.values.fileCsv);
            await api.post(
              `/partners/automatic_messages/${formSchedules?.values?.id}/upload`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }

          if (currentStep === "step4") {
            if (formSchedules?.values?.triggerType === "scheduled") {
              toast.success(
                `Disparo de mensagem atualizado com sucesso! 🚀! Seu disparo de mensagem será iniciado em ${moment(
                  formSchedules?.values?.triggerTime
                ).format("DD/MM/YYYY [às] HH:mm")}. 🚀`
              );
            } else {
              toast.success("Disparo RÁPIDO de mensagem atualizado com sucesso! 🚀");
            }
          }
          setModalSchedules(false);
          // navigate(-1);
        }
      } else {
        const { data } = await api.post(`partners/automatic_messages`, {
          data: {
            attributes: {
              title: formSchedules?.values?.title || "",
              message: formSchedules?.values?.message || "",
              param1: formSchedules?.values?.param1 || "",
              param2: formSchedules?.values?.param2 || "",
              param3: formSchedules?.values?.param3 || "",
              param4: formSchedules?.values?.param4 || "",
              param5: formSchedules?.values?.param5 || "",
              triggerType: formSchedules?.values?.triggerType || "",
              triggerTime: formSchedules?.values?.triggerTime || "",
              dontDisturb: formSchedules?.values?.dontDisturb || "",
              dontDisturbWeekDays:
                formSchedules?.values?.dontDisturbWeekDays || "",
              dontDisturbDispatchStart:
                moment(formSchedules?.values?.dontDisturbDispatchStart)
                  .utc()
                  .format("HH:mm:ss") || "",
              dontDisturbDispatchEnd:
                moment(formSchedules?.values?.dontDisturbDispatchEnd)
                  .utc()
                  .format("HH:mm:ss") || "",
              automaticMessagePhonesAttributes:
                phoneNumbers.length > 0
                  ? [
                      ...new Set(
                        phoneNumbers
                          .filter((phone) => !phone.id)
                          .map((phone) => phone.phone) // Extraindo apenas os valores únicos
                      ),
                    ].map((phone) => ({ phone })) // Mapeando para objetos corretamente
                  : [],
            },
          },
        });

        if (data) {
          formSchedules.setFieldValue("id", data.data.id);

          // console.log(data);

          if (formSchedules.values.fileCsv) {
            const formData = new FormData();
            formData.append("file", formSchedules.values.fileCsv);
            await api.post(
              `/partners/automatic_messages/${data.data.id}/upload`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }

          if (currentStep.menu === "Revisão") {
            if (formSchedules?.values?.triggerType === "scheduled") {
              toast.success(
                `Disparo de mensagem criado com sucesso! 🚀! Seu disparo de mensagem será iniciado em ${moment(
                  formSchedules?.values?.triggerTime
                ).format("DD/MM/YYYY [às] HH:mm")}. 🚀`
              );
            } else {
              toast.success("Disparo RÁPIDO de mensagem criado com sucesso! 🚀");
            }
          }
          setModalSchedules(false);
          // navigate(-1);
        }
      }
    } catch (error) {
      if (error.response.data.errors[0].title) {
        toast.error(error.response.data.errors[0].title);
      }
      return toast.error("Não foi criar/atualizar seus disparo de Messagem.");
    } finally {
      setSaveLoading(false);
      // setCurrentStep("step2");
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    formSchedules.setFieldValue(name, value);
  };

  const handleInputParams = (event) => {
    const { name, value } = event.target;
    const formattedValue = value.toUpperCase().replace(/\s+/g, "");
    formSchedules.setFieldValue(name, formattedValue);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formSchedules.setFieldValue("fileCsv", file);
    }
  };

  // Função para inserir o parâmetro no campo de mensagem
  const insertParamIntoMessage = (param) => {
    formSchedules.setFieldValue(
      "message",
      formSchedules.values.message + ` {${param}}`
    );
  };

  const countryFlags = {
    1: "https://flagcdn.com/w320/us.png", // Estados Unidos
    7: "https://flagcdn.com/w320/ru.png", // Rússia
    20: "https://flagcdn.com/w320/eg.png", // Egito
    30: "https://flagcdn.com/w320/gr.png", // Grécia
    31: "https://flagcdn.com/w320/nl.png", // Países Baixos
    33: "https://flagcdn.com/w320/fr.png", // França
    34: "https://flagcdn.com/w320/es.png", // Espanha
    39: "https://flagcdn.com/w320/it.png", // Itália
    44: "https://flagcdn.com/w320/gb.png", // Reino Unido
    49: "https://flagcdn.com/w320/de.png", // Alemanha
    52: "https://flagcdn.com/w320/mx.png", // México
    55: "https://flagcdn.com/w320/br.png", // Brasil
    61: "https://flagcdn.com/w320/au.png", // Austrália
    62: "https://flagcdn.com/w320/id.png", // Indonésia
    81: "https://flagcdn.com/w320/jp.png", // Japão
    82: "https://flagcdn.com/w320/kr.png", // Coreia do Sul
    86: "https://flagcdn.com/w320/cn.png", // China
    90: "https://flagcdn.com/w320/tr.png", // Turquia
    91: "https://flagcdn.com/w320/in.png", // Índia
    98: "https://flagcdn.com/w320/ir.png", // Irã
    212: "https://flagcdn.com/w320/ma.png", // Marrocos
    213: "https://flagcdn.com/w320/dz.png", // Argélia
    234: "https://flagcdn.com/w320/ng.png", // Nigéria
    351: "https://flagcdn.com/w320/pt.png", // Portugal
    971: "https://flagcdn.com/w320/ae.png", // Emirados Árabes Unidos
    972: "https://flagcdn.com/w320/il.png", // Israel
  };

  return (
    <FluxoAddProviderContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        phoneNumbers,
        setPhoneNumbers,
        modalSchedules,
        setModalSchedules,
        phone,
        setPhone,
        handleAddPhoneNumber,
        handleDeletePhoneNumber,
        handleDigitAPI,
        formSchedules,
        loadingCsv,
        setLoadingCsv,
        getINfos,
        updateSchedules,
        sumary,
        getCsv,
        handleInput,
        handleFileChange,
        insertParamIntoMessage,
        navigate,
        id,
        saveLoading,
        setSaveLoading,
        loading,
        setLoading,
        handleInputParams,
        countryCode,
        setCountryCode,
        countryFlags,
        getSumary
      }}
    >
      {children}
    </FluxoAddProviderContext.Provider>
  );
};
