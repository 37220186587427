import React, { useContext, useState } from "react";
import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { FluxoAddProviderContext } from "./FluxoAddProvider";

import "./FluxoAdd.css";

const FluxoAdd = () => {
  const { currentStep } = useContext(FluxoAddProviderContext);

  const renderContent = () => {
    switch (currentStep) {
      case "step2":
        return <Step2 />;
      case "step3":
        return <Step3 />;
      case "step4":
        return <Step4 />;
      default:
        return <Step1 />;
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerFluxoAdd">{renderContent()}</div>
    </AuthorizedLayout>
  );
};

export default FluxoAdd;
