import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../../utils/api";
import { useFormik } from "formik";
import { Bell, Clock, CheckCircle, AlertTriangle } from "@geist-ui/icons";
import DoNotDisturb from "../../../../../components/DoNotDisturb/DoNotDisturb";
import "./styles.css";
import MainCard from "../../../../../components/MainCard";
import Card from "../../../../../components/Card";
import moment from "moment";
import Button from "../../../../../components/Button";

const ReminderConfig = () => {
  const [schedulesID, setSchedulesID] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [config, setConfig] = useState({
    scheduleReminderHours: 24,
    dontDisturbScheduleReminder: false,
    dontDisturbScheduleReminderDispatchStart: "",
    dontDisturbScheduleReminderDispatchEnd: "",
    dontDisturbScheduleReminderWeekDays: [],
  });

  const getScheduleSettings = async () => {
    setScheduleLoading(true);
    try {
      const { data } = await api.get("/partners/automatic_message_configs");
      if (data.data) {
        setSchedulesID(data?.data.id);
        setConfig({
          id: Number(data?.data.id),
          ...data?.data.attributes,
          dontDisturbScheduleReminderDispatchStart:
            moment(
              data.data.attributes.dontDisturbScheduleReminderDispatchStart
            )
              ?.utc()
              .format("HH:mm:ss") || null,
          dontDisturbScheduleReminderDispatchEnd:
            moment(data.data.attributes.dontDisturbScheduleReminderDispatchEnd)
              ?.utc()
              .format("HH:mm:ss") || null,
        });
      }
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao tentar recuperar as configurações de agendamento"
      );
    } finally {
      setScheduleLoading(false);
    }
  };

  const updateSchedules = async () => {
    setSaveLoading(true);
    try {
      if (schedulesID) {
        await api.put(`/partners/automatic_message_configs/${config.id}`, {
          data: {
            attributes: {
              ...config,
            },
          },
        });
      } else {
        await api.post(`/partners/automatic_message_configs`, {
          data: {
            attributes: {
              ...config,
            },
          },
        });
      }

      toast.success("Configurações de lembrete atualizadas com sucesso!");
    } catch (error) {
      toast.error("Não foi possível atualizar as configurações de lembrete.");
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    getScheduleSettings();
  }, []);

  const updateConfig = useCallback((key, value) => {
    setConfig((prevConfig) => ({ ...prevConfig, [key]: value }));
  }, []);

  return (
    <MainCard variant="minimal" flex>
      <Card title="Lembrete de agendamento">
        <div className="reminder-config-sections">
          {/* Status */}
          <div className="reminder-config-status">
            <div className="reminder-config-status-content">
              <div className="reminder-config-status-icon">
                <CheckCircle className="reminder-config-icon" />
              </div>
              <div>
                <h3 className="reminder-config-status-title">
                  Lembretes Ativos
                </h3>
                <p className="reminder-config-status-text">
                  Os lembretes serão enviados via WhatsApp automaticamente
                </p>
              </div>
            </div>
          </div>

          {/* Tempo de Antecedência */}
          <div className="reminder-config-section">
            <div className="reminder-config-section-header">
              <div className="reminder-config-section-icon">
                <Bell className="reminder-config-icon" />
              </div>
              <div>
                <h3 className="reminder-config-section-title">
                  Tempo de Antecedência
                </h3>
                <p className="reminder-config-section-description">
                  Quanto tempo antes do agendamento o lembrete será enviado
                </p>
              </div>
            </div>

            <div className="reminder-config-input-group">
              <input
                type="number"
                min={5}
                max={120}
                value={config.scheduleReminderHours}
                onChange={(event) =>
                  updateConfig("scheduleReminderHours", event.target.value)
                }
                name="scheduleReminderHours"
                className="reminder-config-input"
              />
              <span className="reminder-config-input-label">minutos antes</span>
            </div>

            {/* {!isValidSettings() && (
              <div className="reminder-config-error">
                <AlertTriangle className="reminder-config-error-icon" />
                <span>O tempo deve estar entre 5 e 120 minutos</span>
              </div>
            )} */}
          </div>

          {/* Modo Não Perturbe */}
          <DoNotDisturb
            enabled={config.dontDisturbScheduleReminder}
            onEnabledChange={(enabled) =>
              updateConfig("dontDisturbScheduleReminder", enabled)
            }
            startTime={config.dontDisturbScheduleReminderDispatchStart}
            onStartTimeChange={(time) =>
              updateConfig("dontDisturbScheduleReminderDispatchStart", time)
            }
            endTime={config.dontDisturbScheduleReminderDispatchEnd}
            onEndTimeChange={(time) =>
              updateConfig("dontDisturbScheduleReminderDispatchEnd", time)
            }
            selectedDays={config.dontDisturbScheduleReminderWeekDays}
            onDaySelect={(days) =>
              updateConfig("dontDisturbScheduleReminderWeekDays", days)
            }
            description="Defina um período em que os lembretes não serão enviados"
          />
        </div>
      </Card>

      {/* Botões */}
      <Card>
        {/* <button
          onClick={updateSchedules}
          className={`reminder-config-button ${
            saveLoading ? "reminder-config-button-disabled" : ""
          }`}
        >
          {saveLoading ? (
            <div className="reminder-config-button-content">
              <Clock className="reminder-config-loading-icon" />
              <span>Salvando...</span>
            </div>
          ) : (
            "Salvar Configurações"
          )}
        </button> */}

<div style={{ display: 'flex', justifyContent: 'flex-end'}}>

        <Button
          onClick={updateSchedules}
          disabled={saveLoading}
          style={{ marginTop: "32px" }}
          variant="filled"
        >
          {saveLoading ? "Salvando..." : "Salvar Configurações"}
        </Button>

</div>
      </Card>
    </MainCard>
  );
};

export default ReminderConfig;
