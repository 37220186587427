import React, { useEffect, useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import arrow from "../../../../../assets/Vector.png";

import "./Step7.css";
import { toast } from "react-toastify";
import api from "../../../../../utils/api";
import moment from "moment";
import { useFormik } from "formik";
import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import FormInput from "../../../../../components/FormInput";
import './FormInput.css';

const navigateimulator = {
  menu: "Simulador do Atendente",
  index: 6,
};

const daysArray = [
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
  "Domingo",
];

const Step7 = ({
  setMenuSelected,
  checkList,
  section1Ref,
  disabled,
  scrollToSection1,
  setCheckSave,
}) => {
  const [schedulesID, setSchedulesID] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [modalSchedules, setModalSchedules] = useState(false);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const handleMenu = (item) => {
    if (
      item.menu === "Simulador do Atendente" &&
      !checkList
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      setMenuSelected(item);
      if (isMobileDevice()) {
        scrollToSection1();
      }
    }
  };

  const formSchedules = useFormik({
    initialValues: {
      // password: "",
      durationInMinutes: moment(new Date(2000, 0, 1, 0, 0, 0)),
      weekDays: [],
      startTime: moment(new Date(2000, 0, 1, 8, 0, 0)),
      endTime: moment(new Date(2000, 0, 1, 20, 0, 0)),
      intervalMinutes: moment(new Date(2000, 0, 1, 0, 0, 0)),
    },

    onSubmit: () => updateSchedules(),
  });

  const handleHoursToMinutes = (hours, minutes) => {
    console.log(hours);
    console.log(minutes);
    return Number(hours) * 60 + Number(minutes);
  };

  function handleMinutesToHours(minutes) {
    const hours = Math.trunc(minutes / 60);
    const remainingMinutes = minutes % 60;
    console.log(`${hours}:${remainingMinutes}`);
    return `${hours}:${remainingMinutes}`;
  }

  const getScheduleSettings = async () => {
    setScheduleLoading(true);

    try {
      const { data } = await api.get(
        "/affiliates/schedule_settings/my_settings"
      );
      if (data.data) {
        setSchedulesID(data?.data?.id);
        formSchedules.setValues({
          weekDays: data?.data?.attributes.weekDays.split(", "),
          startTime: moment(data?.data?.attributes.startTime, "HH:mm"),
          endTime: moment(data?.data?.attributes.endTime, "HH:mm"),

          durationInMinutes: data?.data?.attributes?.durationInMinutes
            ? moment(
                handleMinutesToHours(data?.data?.attributes?.durationInMinutes),
                "HH:mm"
              )
            : moment(new Date(2000, 0, 1, 0, 0, 0)),
          intervalMinutes: data?.data?.attributes?.intervalMinutes
            ? moment(
                handleMinutesToHours(data?.data?.attributes?.intervalMinutes),
                "HH:mm"
              )
            : moment(new Date(2000, 0, 1, 0, 0, 0)),

          // durationInMinutes: data?.data?.attributes?.durationInMinutes || 0,
          // intervalMinutes: data?.data?.attributes?.intervalMinutes || 0,
        });
      }
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao tentar recuperar as configurações de agendamento"
      );
    } finally {
      setScheduleLoading(false);
    }
  };

  const updateSchedules = async () => {
    setSaveLoading(true);

    try {
      if (schedulesID) {
        const { data } = await api.put(
          `affiliates/schedule_settings/${schedulesID}`,
          {
            data: {
              attributes: {
                weekDays: formSchedules?.values?.weekDays.join(", "),
                startTime: formSchedules?.values?.startTime.format("HH:mm"),
                endTime: formSchedules?.values?.endTime.format("HH:mm"),
                intervalMinutes: Number(
                  handleHoursToMinutes(
                    formSchedules?.values?.intervalMinutes.format("HH"),
                    formSchedules?.values?.intervalMinutes.format("mm")
                  )
                ),
                durationInMinutes: Number(
                  handleHoursToMinutes(
                    formSchedules?.values?.durationInMinutes.format("HH"),
                    formSchedules?.values?.durationInMinutes.format("mm")
                  )
                ),
              },
            },
          }
        );

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success(
            "Configurações de agendamento atualizadas com sucesso!"
          );
          setModalSchedules(false);
        }
      } else {
        const { data } = await api.post(`affiliates/schedule_settings`, {
          data: {
            attributes: {
              weekDays: formSchedules?.values?.weekDays.join(", "),
              startTime: formSchedules?.values?.startTime.format("HH:mm"),
              endTime: formSchedules?.values?.endTime.format("HH:mm"),
              intervalMinutes: Number(
                handleHoursToMinutes(
                  formSchedules?.values?.intervalMinutes.format("HH"),
                  formSchedules?.values?.intervalMinutes.format("mm")
                )
              ),
              durationInMinutes: Number(
                handleHoursToMinutes(
                  formSchedules?.values?.durationInMinutes.format("HH"),
                  formSchedules?.values?.durationInMinutes.format("mm")
                )
              ),
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success(
            "Configurações de agendamento atualizadas com sucesso!"
          );
          setModalSchedules(false);
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error(
        "Não foi possível atualizar as configurações de agendamento."
      );
    } finally {
      setSaveLoading(false);
    }
  };

  const sortDays = (selected) => {
    return selected.sort((a, b) => {
      return daysArray.indexOf(a) - daysArray.indexOf(b);
    });
  };

  useEffect(() => {
    // getSettings();
    getScheduleSettings();
  }, []);

  // const handleInput = (event) => {
  //   const { name, value } = event.target;
  //   formSchedules.setFieldValue(name, value);
  //   setCheckSave(false);
  // };

  const handleChange = (event, field) => {
    let values = event.target.value;

    // Verifica se o valorEspecial está no array.
    const especialIndex = values.indexOf("Outro");

    // Se o valorEspecial estiver no array, remova-o e adicione-o de volta ao final.
    if (especialIndex !== -1) {
      values.splice(especialIndex, 1);
      values.push("Outro");
    }

    formSchedules.setFieldValue(field, values);
  };

  return (
    <div className="containerStep2" ref={section1Ref}>
      <div className="titlePage">Agendamento</div>
      <div className="containerInputs">
        <div className="card">
          <div className="containerInput">
            <div className="title">
              Dias disponíveis para agendamento de reuniões
            </div>
            <div className="subTitleInput">
              Escolha os dias da semana em que o atendente poderá agendar
              reuniões com os clientes
            </div>
            <FormInput variant="outlined"
              form={formSchedules}
              fieldName="weekDays"
              renderValue={(selected) => selected.join(", ")}
              className="migrated-form-input"
              width="100%"
              onChange={(event) => handleChange(event, "weekDays")}
              multiple
              disabled={disabled}
              className="inpurt"
            >
              {daysArray.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    <Checkbox
                      checked={formSchedules.values.weekDays.indexOf(item) > -1}
                      style={{ color: "#102649" }}
                    />
                    <ListItemText
                      className="textTOne"
                      style={{ color: "#102649", fontWeight: "400" }}
                    >
                      {item}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </FormInput>
          </div>

          <div className="containerInput">
            <div className="title">Duração das reuniões</div>
            <div className="subTitleInput">
              Insira a duração das reuniões a serem marcadas pelo atendente
            </div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                value={formSchedules.values.durationInMinutes}
                onChange={(newValue) =>
                  formSchedules.setFieldValue("durationInMinutes", newValue)
                }
                format="HH[h] mm[m]"
                className="custom-timepicker"
              />
            </LocalizationProvider>
          </div>

          <div className="containerInput">
            <div className="title">Intervalo entre as reuniões</div>
            <div className="subTitleInput">
              Insira a duração do intervalo entre os horários das reuniões a
              serem marcadas
            </div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                value={formSchedules.values.intervalMinutes}
                onChange={(newValue) =>
                  formSchedules.setFieldValue("intervalMinutes", newValue)
                }
                format="HH[h] mm[m]"
                className="custom-timepicker"
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="card">
          <div className="containerInput">
            <div className="title">Hora de Início</div>
            <div className="subTitleInput">
              Escolha o horário de início do expediente disponível para reuniões
              nos dias selecionados
            </div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                value={formSchedules.values.startTime}
                onChange={(newValue) =>
                  formSchedules.setFieldValue("startTime", newValue)
                }
                // format="HH[h] mm[m]"
                className="custom-timepicker"
              />
            </LocalizationProvider>
          </div>

          <div className="containerInput">
            <div className="title">Hora de Término</div>
            <div className="subTitleInput">
              Escolha o horário limite para iniciar uma reunião nos dias
              selecionados
            </div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                value={formSchedules.values.endTime}
                onChange={(newValue) =>
                  formSchedules.setFieldValue("endTime", newValue)
                }
                // format="HH[h] mm[m]"
                className="custom-timepicker"
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>

      {!disabled && (
        <div className="containerButtons">
          <div
            onClick={() => handleMenu(navigateimulator)}
            className={`buttonSimulator ${
              !checkList && "disabledMenus"
            }`}
          >
            Simular atendimento
          </div>

          <div onClick={formSchedules.handleSubmit} className="buttonSave">
            {saveLoading ? (
              "Carregando..."
            ) : (
              <>
                Salvar alterações
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Step7;
