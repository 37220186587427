import React from 'react';
import { MessageSquare, User, Clock, CheckCircle } from '@geist-ui/icons';
import './Stepper.css';

const Stepper = ({ currentStep }) => {
  const steps = [
    { id: 1, title: 'Mensagem', icon: MessageSquare },
    { id: 2, title: 'Contatos', icon: User },
    { id: 3, title: 'Agendamento', icon: Clock },
    { id: 4, title: 'Revisão', icon: CheckCircle }
  ];

  const getStepNumber = (stepId) => {
    switch (stepId) {
      case 1:
        return currentStep > 1 ? <CheckCircle className="icon" /> : "1";
      case 2:
        return currentStep > 2 ? <CheckCircle className="icon" /> : "2";
      case 3:
        return currentStep > 3 ? <CheckCircle className="icon" /> : "3";
      case 4:
        return currentStep > 4 ? <CheckCircle className="icon" /> : "4";
      default:
        return stepId;
    }
  };

  return (
    <div className="w-full mb-8">
      <div className="stepper-container">
        <div className="progress-line">
          <div 
            className="progress-fill"
            style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
          />
        </div>

        {steps.map((step) => {
          const isActive = step.id === currentStep;
          const isCompleted = step.id < currentStep;

          return (
            <div key={step.id} className="step-item">
              <div className={`step-icon ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`}>
                {isCompleted ? <CheckCircle className="icon" /> : <step.icon className="icon" />}
              </div>
              <span className={`step-title ${isActive || isCompleted ? 'active' : ''}`}>
                {step.title}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
