import React from "react";
import PropTypes from "prop-types";
import { Input, Text, Tooltip, Spacer, useTheme } from "@geist-ui/core";
import { Info } from "@geist-ui/icons";
import ReactInputMask from "react-input-mask";
import "./FormInput.css";

/**
 * FormInput - Componente unificado para inputs com label
 *
 * Este componente combina as funcionalidades dos componentes InputField e InputWithLabel
 * em um único componente com suporte a acessibilidade, validação e máscaras,
 * seguindo os padrões da biblioteca Geist UI.
 */
export const FormInput = ({
  // Propriedades básicas
  id,
  name,
  label,
  value,
  onChange,
  onBlur,

  // Propriedades de estilo e comportamento
  width = "100%",
  disabled = false,
  readOnly = false,
  required = false,
  autoFocus = false,

  // Propriedades de validação
  error = null,
  validate = false,

  // Propriedades de máscara
  mask = "",
  maskChar = "",

  // Propriedades de ajuda e informação
  helperText = "",
  infoTooltip = "",

  // Propriedades de tipo e formato
  type = "text",
  htmlType = "text",
  placeholder = "",

  // Propriedades específicas para textarea
  multiline = false,
  rows = 4,

  // Integração com Formik (opcional)
  form = null,
  fieldName = "",

  // Propriedades de estilo
  className = "",
  style = {},

  // Outras propriedades
  ...props
}) => {
  const theme = useTheme();

  // Determinar se estamos usando Formik ou não
  const isFormik = form !== null && fieldName !== "";

  // Valores e handlers para Formik ou controlados diretamente
  const inputValue = isFormik ? form.values[fieldName] : value;
  const hasError = isFormik
    ? validate && form.touched[fieldName] && form.errors[fieldName]
    : validate && error;
  const errorMessage = isFormik ? form.errors[fieldName] : error;

  // Status do input para Geist UI
  const status = hasError ? "error" : "default";

  // Handlers para onChange e onBlur
  const handleChange = (e) => {
    const val = e.target ? e.target.value : e;

    if (isFormik) {
      form.setFieldValue(fieldName, val);
    } else if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (e) => {
    if (isFormik) {
      form.setFieldTouched(fieldName, true);
    } else if (onBlur) {
      onBlur(e);
    }
  };

  // Componente de input com ou sem máscara
  const renderInput = () => {
    const inputProps = {
      id: id || name || fieldName,
      width: width,
      value: inputValue || "",
      onChange: handleChange,
      onBlur: handleBlur,
      disabled: disabled,
      readOnly: readOnly,
      required: required,
      autoFocus: autoFocus,
      placeholder: placeholder,
      status: status,
      className: `form-input ${className}`,
      style: { ...style },
      "aria-describedby": helperText
        ? `${id || name || fieldName}-helper-text`
        : undefined,
      ...props,
    };

    // Para campos de senha
    if (type === "password") {
      return (
        <Input.Password {...inputProps} htmlType={htmlType} label={label} />
      );
    }

    // Para campos multiline (textarea)
    if (multiline) {
      return <Input.Textarea {...inputProps} rows={rows} label={label} />;
    }

    // Para campos com máscara
    if (mask) {
      return (
        <div className="form-select-wrapper" style={{ height: "40px" }}>
          <label className="form-select-label">{label}</label>
          <ReactInputMask
            mask={mask}
            maskChar={maskChar}
            value={inputValue || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            readOnly={readOnly}
          >
            {(inputProps) => {
              // Mesclar os props do ReactInputMask com nossos props personalizados
              const mergedProps = {
                ...inputProps,
                id: id || name || fieldName,
                width: width,
                placeholder: placeholder,
                status: status,
                className: `form-input ${className}`,
                style: { ...style },
                "aria-describedby": helperText
                  ? `${id || name || fieldName}-helper-text`
                  : undefined,
                ...props,
              };
              return (
                <input {...mergedProps} type="text" className="inputMask" />
              );
            }}
          </ReactInputMask>
        </div>
      );
    }

    // Input padrão
    return <Input {...inputProps} htmlType={htmlType} label={label} />;
  };

  return (
    <div className="form-input-container">
      {/* Label e tooltip de informação (se houver) */}
      {infoTooltip && (
        <div className="form-input-label-container">
          <Tooltip text={infoTooltip} placement="topStart">
            <div className="form-input-info-icon">
              <Info size={16} />
            </div>
          </Tooltip>
        </div>
      )}

      {/* Renderiza o input */}
      {renderInput()}

      {/* Mensagem de erro de validação */}
      {validate && hasError && (
        <Text
          type="error"
          small
          id={`${id || name || fieldName}-error-text`}
          className="form-input-error"
        >
          {errorMessage}
        </Text>
      )}

      {/* Helper text (quando não há erro) */}
      {helperText && !hasError && (
        <Text
          small
          type="secondary"
          id={`${id || name || fieldName}-helper-text`}
          className="form-input-helper"
        >
          {helperText}
        </Text>
      )}

      <Spacer h={0.5} />
    </div>
  );
};

FormInput.propTypes = {
  // Propriedades básicas
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  // Propriedades de estilo e comportamento
  width: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,

  // Propriedades de validação
  error: PropTypes.string,
  validate: PropTypes.bool,

  // Propriedades de máscara
  mask: PropTypes.string,
  maskChar: PropTypes.string,

  // Propriedades de ajuda e informação
  helperText: PropTypes.string,
  infoTooltip: PropTypes.string,

  // Propriedades de tipo e formato
  type: PropTypes.string,
  htmlType: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,

  // Integração com Formik (opcional)
  form: PropTypes.object,
  fieldName: PropTypes.string,

  // Propriedades de estilo
  className: PropTypes.string,
  style: PropTypes.object,
};

export default FormInput;
