import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import api from "../../../../../utils/api";
import { toast } from "react-toastify";
import { faker } from "@faker-js/faker";
import { AuthContext } from "../../../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../../../../assets/Vector (20).png";
import iconLOading from "../../../../../assets/Frame 11.png";

import "./Step1.css";
import { CheckContext } from "./CheckProvider";
import ModalAlert from "../../../../../components/ModalAlert";

const Step1 = () => {
  const { user, checkComplete, updatePartnerDetail } = useContext(AuthContext);
  const { setCurrentStep, setDataUpdate } = useContext(CheckContext);
  const navigate = useNavigate();
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [selectedMode, setSelectedMode] = useState("link");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [modalWarning, setModalWarning] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [filePayload, setFilePayload] = useState({});

  useEffect(() => {
    console.log(selectedFile);
  }, [selectedFile]);

  const fetchFile = async (file) => {
    setLoadingFile(true);
    try {
      setSelectedFile(file);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFile(false);
    }
  };

  const handleFileUpload = (files) => {
    if (!files || files.length === 0) return;
    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result; // Mantém o Data URI completo
        const fileName = file.name; // Obtém o nome do arquivo

        setFilePayload({ name: fileName, base64: base64String });

        fetchFile(file);
      };

      reader.onerror = (error) => console.error("Erro ao ler arquivo", error);
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileUpload(event.dataTransfer.files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const CardFileItens = () => {
    const [loadingD, setLoadingD] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [modalAlert, setModalAlert] = useState(false);

    const handleFileDownload = () => {
      setLoadingD(true);
      fetch(selectedFile)
        .then((response) => response.blob())
        .then(() => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(selectedFile);
          link.download = selectedFile.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => console.error("Erro ao baixar o arquivo:", error))
        .finally(() => setLoadingD(false));
    };

    const handleFileDelete = async () => {
      setLoadingDelete(true);
      try {
        setSelectedFile({});
        setFilePayload({});
      } catch (error) {
      } finally {
        setLoadingDelete(false);
        setModalAlert(false);
      }
    };

    return (
      <>
        <div className="fileItem">
          <div className="fileInfo">
            <svg
              className="fileIcon"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
              <polyline points="13 2 13 9 20 9" />
            </svg>
            <div>
              <div className="fileName">
                {selectedFile?.name || "Nome indefinido"}
              </div>
              {/* <div className="fileSize">{file.size}</div> */}
            </div>
          </div>
          <div className="fileActions">
            {loadingD ? (
              <button type="button" className="actionButton" title="Download">
                <svg
                  className="loading-spinner"
                  width="20"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="4"
                >
                  <circle cx="25" cy="25" r="20" strokeOpacity="0.5" />
                  <path d="M25 5a20 20 0 0 1 20 20" className="spinner-path" />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                className="actionButton"
                onClick={() => handleFileDownload(selectedFile)}
                title="Download"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                  <polyline points="7 10 12 15 17 10" />
                  <line x1="12" y1="15" x2="12" y2="3" />
                </svg>
              </button>
            )}
            <button
              type="button"
              className="actionButton delete"
              // onClick={() => handleFileDelete(file.id)}
              onClick={() => setModalAlert(true)}
              title="Excluir"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <polyline points="3 6 5 6 21 6" />
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
              </svg>
            </button>
          </div>
        </div>

        <ModalAlert
          handleClose={() => setModalAlert(false)}
          handleSubmit={() => handleFileDelete()}
          open={modalAlert}
          loading={loadingDelete}
        >
          <Typography variant={isMobileDevice ? "h7" : "h6"}>
            Tem certeza que deseja excluir esse arquivo? Essa ação é
            irreversível.
          </Typography>
        </ModalAlert>
      </>
    );
  };

  const validateResponse = async (data) => {
    try {
      // Supondo que a resposta contenha as informações necessárias para a configuração
      await checkComplete(user.id);
      // Redirecionar para a tela de chat de demonstração
      console.log("Resposta do serviço:", data?.analysisResult);

      if (selectedMode === "link") {
        if (
          !data?.analysisResult?.nome_fantasia ||
          !data?.analysisResult?.nicho ||
          !data?.analysisResult?.objetivos?.outro
        ) {
          setLoading(false);
          setError(
            "Não foram encontradas informações suficientes na página inserida. Por favor, prossiga utilizando o modo de Descrição ou tente um link diferente."
          );
          setModalWarning(true);
          return false;
        } else {
          return true;
        }
      } else if (selectedMode === "description") {
        if (!data?.analysisResult?.nome_fantasia) {
          setLoading(false);
          setError("Por favor, adicione o nome do seu negócio na descrição.");
          setModalWarning(true);
          return false;
        } else if (!data?.analysisResult?.nicho) {
          setLoading(false);
          setError("Por favor, adicione o nicho do seu negócio na descrição.");
          setModalWarning(true);
          return false;
        } else if (!data?.analysisResult?.objetivos?.outro) {
          setLoading(false);
          setError(
            "Por favor, adicione instruções para o atendente na descrição."
          );
          setModalWarning(true);
          return false;
        } else if (
          data?.analysisResult?.nome_fantasia &&
          data?.analysisResult?.nicho &&
          data?.analysisResult?.objetivos?.outro
        ) {
          return true;
        }
      } else {
        if (
          !data?.analysisResult?.nome_fantasia ||
          !data?.analysisResult?.nicho ||
          !data?.analysisResult?.objetivos?.outro
        ) {
          setLoading(false);
          setError(
            "Não foram encontradas informações suficientes no documento inserido. Por favor, prossiga utilizando o modo de Descrição ou tente um arquivo diferente."
          );
          setModalWarning(true);
          return false;
        } else {
          return true;
        }
      }
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      if (selectedMode === "link") {
        if (!websiteUrl) {
          setError("Por favor, insira a URL do seu site.");
          setLoading(false);
          return;
        }
        // Enviar a URL para o serviço de terceiros
        const { data } = await api.post(
          "https://primary-production-5d91.up.railway.app/webhook/atendente",
          {
            url: handleUrl(websiteUrl),
          }
        );

        if (await validateResponse(data)) {
          updateSettings({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
            companyContact: handleUrl(websiteUrl),
          });
          setDataUpdate({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
          });
          setCurrentStep("step2");
        } else {
          console.log("Resposta do serviço:", data);
        }
      } else if (selectedMode === "description") {
        if (!companyDescription.trim()) {
          setError("Por favor, insira a descrição da sua empresa.");
          setLoading(false);
          return;
        }

        // Enviar a descrição para outro serviço de terceiros
        const { data } = await api.post(
          "https://primary-production-5d91.up.railway.app/webhook/atendente-text",
          {
            description: companyDescription.trim(),
          }
        );

        if (await validateResponse(data)) {
          updateSettings({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
          });
          setDataUpdate({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
          });
          setCurrentStep("step2");
        } else {
          console.log("Resposta do serviço:", data);
        }
      } else {
        if (!selectedFile?.size) {
          setError("Por favor, insira um arquivo válido");
          setLoading(false);
          return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        // Enviar a descrição para outro serviço de terceiros
        const { data } = await api.post(
          "https://primary-production-5d91.up.railway.app/webhook/888ced17-5d5c-46f6-bd17-4aa987ad0f08",
          formData
        );

        if (await validateResponse(data)) {
          await api.post(`/partners/detail_files/`, {
            data: {
              attributes: {
                filename: filePayload?.name,
                file: {
                  data: filePayload?.base64,
                },
              },
            },
          });
          updateSettings({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
          });
          setDataUpdate({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
          });
          setCurrentStep("step2");
        } else {
          console.log("Resposta do serviço:", data);
        }
      }
    } catch (err) {
      // Registrar o erro no console
      console.error("Erro ao configurar o atendente:", err);
      setError("Ocorreu um erro. Por favor, tente novamente.");
      setLoading(false);
    } finally {
    }
  };

  const updateSettings = async (values) => {
    setLoading(true);

    try {
      let body = {
        data: {
          attributes: {
            nameAttendant: values?.nameAttendant,
            companyName: values?.companyName,
            companyNiche: values?.companyNiche,
            servedRegion: values?.servedRegion,
            companyServices: values?.companyServices?.join(", "),
            companyProducts: values?.companyProducts?.join(", "),
            companyContact: values.companyContact,
            // companyObjectives: values?.companyObjectives?.map((item) => {
            //   if (item === "Outro") {
            //     return values.companyObjectivesExtra;
            //   } else {
            //     return item;
            //   }
            // }),
            companyObjectives: [
              // values?.companyObjectives?.agendar_reuniao && optionsArray[0],
              // values?.companyObjectives?.adquirir_email && optionsArray[1],
              false,
              false,
              values?.companyObjectives?.outro &&
                values?.companyObjectives?.outro,
            ]?.filter(Boolean),
            attendantInstructions: values?.companyObjectives?.outro,
            // twitterXLink: values.twitterXLink,
            // youtubeLink: values.youtubeLink,
            // facebookLink: values.facebookLink,
            // instagramLink: values.instagramLink,
            key_differentials: values?.keyDifferentials?.join(", "),
            // tone_voice: values?.toneVoice?.map((item) => {
            //   if (item === "Outro") {
            //     return values.toneVoiceExtra;
            //   } else {
            //     return item;
            //   }
            // }),
            // preferentialLanguage: values.preferentialLanguage,
            // catalogLink: values.catalogLink,
          },
        },
      };

      if (!user?.partnerDetailId) {
        body.data.attributes.partnerId = user.id;
      }

      const { data } = user?.partnerDetailId
        ? await api.put(
            `partners/partner_details/${user.partnerDetailId}`,
            body
          )
        : await api.post(`partners/partner_details/`, body);

      if (data) {
        updatePartnerDetail(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setLoading(false);
    }
  };

  const handleUrl = (value) => {
    if (value.startsWith("http://") || value.startsWith("https://")) {
      return value;
    } else {
      return "http://" + value;
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }
  return (
    <>
      {modalWarning && (
        <Modal
          open
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setModalWarning(false);
            }
          }}
        >
          <Box
            style={{
              background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
              borderRadius: "20px",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobileDevice() ? 350 : 700,
              p: 2.5,
            }}
          >
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: [
                      '"Roboto"',
                      '"Helvetica"',
                      '"Arial"',
                      "sans-serif",
                    ],
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    alignSelf: "flex-start",
                    marginTop: "15px",
                    marginBottom: "15px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Typography fontSize={28} fontWeight={600} color={"red"}>
                    ATENÇÃO
                  </Typography>
                </Typography>

                <Typography
                  textAlign={"center"}
                  fontWeight={"600"}
                  style={{ marginBottom: "25px" }}
                  fontSize={20}
                >
                  {"Falha ao configurar o atendente"}
                </Typography>
                <Typography
                  textAlign={"center"}
                  style={{ marginBottom: "25px" }}
                  fontSize={18}
                >
                  {/* {
                    "Por favor, elabore um descritivo mais detalhado a respeito de sua empresa ou serviço."
                  } */}
                  {error}
                </Typography>

                <Button
                  sx={{
                    height: "35px",
                    p: 2,
                    m: 1,
                    alignSelf: "center",
                  }}
                  variant="contained"
                  onClick={() => {
                    setModalWarning(false);
                  }}
                >
                  <Typography color={"white"} variant={"h6"}>
                    {"OK, ENTENDI"}
                  </Typography>
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {loading ? (
        <div className="containerLoadingCheck">
          <div className="loadingDescription">
            <img src={iconLOading} alt="" />
            <div>Estamos criando seu assistente...</div>
          </div>
        </div>
      ) : (
        <div className="containerStep1Check">
          <div className="containerHeader">
            {`Olá, ${user.name}! Vamos começar?`}
          </div>

          <form
            onSubmit={handleSubmit}
            className="containerCardDescription"
            style={{
              height:
                selectedMode === "link"
                  ? "370px"
                  : selectedMode === "file" && selectedFile?.size
                  ? "570px"
                  : selectedMode === "file" && !selectedFile?.size
                  ? "510px"
                  : "670px",
            }}
          >
            <div className="titleCheck">
              {selectedMode === "link" && "Website"}
              {selectedMode === "description" && "Descrição da sua empresa"}
              {selectedMode === "file" && "Envio de arquivo"}
            </div>
            <div className="subtitleCheck">
              {selectedMode === "link" &&
                "Insira o website da sua empresa para otimizar o atendimento ao cliente."}
              {selectedMode === "description" &&
                "Nos conte um pouco sobre sua empresa e que tipo de suporte você precisa."}
              {selectedMode === "file" &&
                "Selecione e envie um documento que fale sobre sua empresa e o que ela oferece."}
            </div>
            {selectedMode === "link" && (
              <input
                // type="url"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                required
                className="urlInput"
              />
            )}

            {selectedMode === "description" && (
              <textarea
                placeholder="Digite a descrição da sua empresa"
                value={companyDescription}
                onChange={(e) => setCompanyDescription(e.target.value)}
                required
                className="descriptionInput"
                // rows="4"
              />
            )}

            {selectedMode === "file" && (
              <>
                {loadingFile ? (
                  <div className="dragDropArea">
                    <svg
                      className="loading-spinner"
                      width="20"
                      height="20"
                      viewBox="0 0 50 50"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="4"
                    >
                      <circle cx="25" cy="25" r="20" strokeOpacity="0.5" />
                      <path
                        d="M25 5a20 20 0 0 1 20 20"
                        className="spinner-path"
                      />
                    </svg>
                  </div>
                ) : (
                  <div
                    className="dragDropArea"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(event) => handleFileUpload(event.target.files)}
                    />
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                      <polyline points="17 8 12 3 7 8" />
                      <line x1="12" y1="3" x2="12" y2="15" />
                    </svg>
                    <p>Arraste seus arquivos aqui ou clique para selecionar</p>
                  </div>
                )}

                <div className="fileList">
                  {selectedFile?.size && <CardFileItens file={selectedFile} />}
                </div>
              </>
            )}

            <button type="submit" disabled={loading} className="submitButton">
              {loading ? "Processando..." : "Criar meu assistente"}
              <img src={arrowIcon} alt="arrow icon" />
            </button>

            {selectedMode === "link" && (
              <div style={{ alignItems: 'center', display: 'flex', flexDirection :'column'}}>
                <div
                  onClick={() => setSelectedMode("description")}
                  className="buttonTypeINput"
                >
                  Prefiro escrever uma descrição
                </div>
                <div className="optionText">ou</div>
                <div
                  onClick={() => setSelectedMode("file")}
                  className="buttonTypeINput"
                >
                  Prefiro enviar um documento
                </div>
              </div>
            )}

            {selectedMode === "description" && (
              <div>
                <div
                  onClick={() => setSelectedMode("link")}
                  className="buttonTypeINput"
                >
                  Prefiro enviar meu site
                </div>
                <div className="optionText">OU</div>
                <div
                  onClick={() => setSelectedMode("file")}
                  className="buttonTypeINput"
                >
                  Prefiro enviar um documento
                </div>
              </div>
            )}

            {selectedMode === "file" && (
              <div style={{ alignItems: 'center', display: 'flex', flexDirection :'column'}}>
                <div
                  onClick={() => setSelectedMode("link")}
                  className="buttonTypeINput"
                >
                  Prefiro enviar meu site
                </div>
                <div className="optionText">ou</div>
                <div
                  onClick={() => setSelectedMode("description")}
                  className="buttonTypeINput"
                >
                  Prefiro escrever uma descrição
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default Step1;
