import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import "./Support.css";
import Button from "../../../components/Button";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Digite um e-mail válido")
    .required("E-mail é obrigatório"),
  subject: yup
    .string()
    .required("Assunto é obrigatório")
    .min(3, "Assunto deve ter no mínimo 3 caracteres"),
  content: yup
    .string()
    .required("Mensagem é obrigatória")
    .min(10, "Mensagem deve ter no mínimo 10 caracteres"),
});

const Support = () => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const showToast = (message, type) => {
    // Using react-toastify instead of Geist UI toast
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
  };

  const supportForm = useFormik({
    initialValues: {
      email: user?.email || "",
      subject: "",
      content: "",
    },
    validationSchema,
    onSubmit: sendSupport,
  });

  async function sendSupport(values) {
    setLoading(true);
    try {
      const { data } = await api.post("/partners/support/send_mail", {
        data: {
          attributes: {
            contact: values.email,
            subject: values.subject,
            body: values.content,
          },
        },
      });

      if (data) {
        showToast(
          "Mensagem enviada com sucesso! Nossa equipe entrará em contato com você em breve.",
          "success"
        );
        supportForm.resetForm();
      }
    } catch (error) {
      showToast(
        "Ocorreu um erro ao enviar a mensagem de suporte. Tente novamente mais tarde.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <AuthorizedLayout>
      <div className="support-container">
        <div className="support-card">
          <div className="support-card-content">
            <div className="support-header">
              <h2>Entre em contato conosco</h2>
              <p>
                Está com alguma dúvida? Tem alguma sugestão? Entre em contato
                conosco!
              </p>
            </div>

            <form onSubmit={supportForm.handleSubmit} className="support-form">
              <div className="form-group">
                <div className="input-group">
                  <label className="label">E-mail</label>
                  <input
                    className="input"
                    type="email"
                    name="email"
                    placeholder="Insira seu melhor e-mail para contato"
                    value={supportForm.values.email}
                    onChange={supportForm.handleChange}
                    onBlur={supportForm.handleBlur}
                  />
                  {supportForm.touched.email && supportForm.errors.email && (
                    <div className="error-text">{supportForm.errors.email}</div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="input-group">
                  <label className="label">Assunto</label>
                  <input
                    className="input"
                    type="text"
                    name="subject"
                    placeholder="Descreva brevemente o que deseja tratar conosco"
                    value={supportForm.values.subject}
                    onChange={supportForm.handleChange}
                    onBlur={supportForm.handleBlur}
                  />
                  {supportForm.touched.subject &&
                    supportForm.errors.subject && (
                      <div className="error-text">
                        {supportForm.errors.subject}
                      </div>
                    )}
                </div>
              </div>

              <div className="form-group">
                <div className="input-group">
                  <label className="label">Mensagem</label>
                  <div className="textarea-container">
                    <textarea
                      className="textarea"
                      name="content"
                      placeholder="Detalhe que tipo de suporte precisa"
                      value={supportForm.values.content}
                      onChange={supportForm.handleChange}
                      onBlur={supportForm.handleBlur}
                      rows={10}
                    />
                    <div className="char-counter">
                      {(supportForm.values.content || "").length} caracteres
                    </div>
                  </div>
                  {supportForm.touched.content &&
                    supportForm.errors.content && (
                      <div className="error-text">
                        {supportForm.errors.content}
                      </div>
                    )}
                </div>
              </div>

              <div className="navigation-buttons">
                <Button
                  variant="filled"
                  onClick={supportForm.handleSubmit}
                  disabled={loading}
                  // icon={<Save />}
                >
                  {loading ? "Enviando..." : "Enviar mensagem"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Support;
