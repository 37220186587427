import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import logo from "../../../assets/altLogo.svg";
import arrow from "../../../assets/Vector.png";
import image1 from "../../../assets/fuundo_ondas (1).png";
import image2 from "../../../assets/fuundo_ondas.png";

import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import api from "../../../utils/api";
import FormInput from "../../../components/FormInput";

export const RecoveryRequest = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = location.state || {};

  const form = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    try {
      setLoading(true);

      if (type === "Parceiro") {
        const { data } = await api.post("/partners/recover_password", {
          data: {
            attributes: {
              email: values.email,
            },
          },
        });
        if (data) {
          toast.success(
            "Email de recuperação enviado com sucesso! Confira sua caixa de entrada!"
          );
          navigate("/");
        }
      } else {
        const { data } = await api.post("/affiliates/recover_password", {
          data: {
            attributes: {
              email: values.email,
            },
          },
        });
        if (data) {
          toast.success(
            "Email de recuperação enviado com sucesso! Confira sua caixa de entrada!"
          );
          navigate("/vendas");
        }
      }

      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu um erro ao enviar o email de recuperação.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="containerLogin">
      <img src={image1} className="image1" alt="Background decoration" />
      <img src={image2} className="image2" alt="Background decoration" />

      <div className="containerCenterLogin">
        <div className="boxLogin">
          <div className="loginTitle">Recuperação de Conta</div>
          <form onSubmit={form.handleSubmit} className="formLogin">
            <FormInput
              label="E-mail"
              name="email"
              htmlType="email"
              value={form.values.email}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched.email && form.errors.email
                  ? form.errors.email
                  : null
              }
              validate={form.touched.email && form.errors.email}
              placeholder="Digite seu e-mail"
              className="loginFormInput"
              width="100%"
            />

            <button type="submit" className="buttonLogin">
              {loading ? (
                "Carregando..."
              ) : (
                <div className="buttonContent">
                  Enviar
                  <img src={arrow} alt="Seta para a direita" />
                </div>
              )}
            </button>
          </form>
        </div>
      </div>

      <footer className="footerLogin">
        sacgpt.com.br © 2024 - Todos os Direitos Reservados
      </footer>
    </div>
  );
};
