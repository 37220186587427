import React, { memo } from 'react';
import PropTypes from 'prop-types';

/**
 * GeistInfoItem - Componente para exibir um par de rótulo e valor
 * 
 * @param {string} label - Rótulo do item
 * @param {string|number|ReactNode} value - Valor do item
 */
const GeistInfoItem = memo(({ label, value }) => {
  return (
    <div className="geist-info-item">
      <span className="geist-label">{label}</span>
      <span className="geist-value">{value}</span>
    </div>
  );
});

GeistInfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
};

GeistInfoItem.displayName = 'GeistInfoItem';

export default GeistInfoItem;