import React, { useState, useContext, useEffect } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import { MainContentContainer } from "../../../components/MainContentContainer";
import MainCard from "../../../components/MainCard";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { useFormik } from "formik";
import * as yup from "yup";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import validateCPF from "../../../utils/validateCPF";
import validateCNPJ from "../../../utils/validateCNPJ";
import unmask from "../../../utils/unmask";

import { Grid, Text, Avatar, Modal, useToasts, Select } from "@geist-ui/core";
import FormInput from "../../../components/FormInput";
import { Edit, ArrowRight } from "@geist-ui/icons";

import "./Profile.css";
import FormSelect from "../../../components/FormInput/FormSelect";

const Profile = () => {
  const {
    user,
    updateUser,
    updatePartnerDetail,
    signOut,
    checkComplete,
    checkList,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [metrics, setMetrics] = useState({});
  const [subscription, setSubscription] = useState({});
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [modalWarning, setModalWarning] = useState(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState(false);

  const getFirstName = (fullName) => {
    if (!fullName) return "";
    const names = fullName.split(" ");
    if (names.length < 2) return names[0];
    return `${names[0]} ${names[1]}`;
  };

  const formatCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const formatCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return cnpj;
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        form.setFieldValue("avatar", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteAccount = async () => {
    setLoadingDeleteAccount(true);
    try {
      await api.delete(`/partners/partners/${user.id}`);
      setModalWarning(false);
      signOut(0);
    } catch (error) {
      console.error("Error deleting account:", error);
      toast.error("Erro ao excluir a conta. Por favor, tente novamente.");
    } finally {
      setLoadingDeleteAccount(false);
    }
  };

  const handleDigit = (input) => {
    if (input?.length >= 11 && !input.startsWith("55")) {
      let num;
      num = `55${input}`;
      return num;
    }
    return input;
  };

  // console.log(user);

  const handleDigitAPI = (input) => {
    if (input.startsWith("55")) {
      let num;
      num = input.slice(2);
      return num;
    }
    return input;
  };

  const form = useFormik({
    initialValues: {
      name: user?.name || "",
      companyName: user?.companyName || "",
      phone: parsePhoneAdmin(handleDigit(user?.contactNumber)) || "",
      email: user?.email || "",
      avatar: null,
      cpf: user?.document?.length
        ? user?.document?.length === 11
          ? user?.document
          : ""
        : "",
      cnpj: user?.document?.length
        ? user?.document?.length === 11
          ? ""
          : user?.document
        : "",
      documentType: user?.document?.length > 11 ? "cnpj" : "cpf",
      newPassword: "",
      passwordConfirmation: "",
    },

    validationSchema: yup.object({
      name: yup.string().required("Nome é obrigatório!"),
      phone: yup
        .string()
        .min(14, "Telefone inválido!")
        .required("Telefone de contato é obrigatório!"),

      // cpf: yup.string().when("documentType", {
      //   is: "cpf",
      //   then: () =>
      //     yup
      //       .string()
      //       .required("Número do documento é obrigatório!")
      //       .test({
      //         name: "isValid",
      //         exclusive: false,
      //         params: {},
      //         message: "CPF inválido",
      //         test: function (value) {
      //           return validateCPF(value || "");
      //         },
      //       }),
      // }),

      // cnpj: yup.string().when("documentType", {
      //   is: "cnpj",
      //   then: () =>
      //     yup
      //       .string()
      //       .required("Número do documento é obrigatório!")
      //       .test({
      //         name: "isValid",
      //         exclusive: false,
      //         params: {},
      //         message: "CNPJ inválido",
      //         test: function (value) {
      //           return validateCNPJ(value || "");
      //         },
      //       }),
      // }),
      documentType: yup
        .string()
        .required("Escolher um tipo de documento é obrigatório!"),
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
      newPassword: yup
        .string()
        .min(8, "A nova senha deve conter no mínimo 8 caracteres."),

      passwordConfirmation: yup
        .string()
        .oneOf(
          [yup.ref("newPassword"), ""],
          "As novas senhas devem ser iguais."
        ),
    }),
    onSubmit: (values) => updateSettings(values),
  });

  const updateSettings = async (values) => {
    setSaveLoading(true);

    const dataUser = values.avatar
      ? {
          data: {
            attributes: {
              name: values.name.trim(),
              avatar: { data: values.avatar },
              companyName: values.companyName.trim(),
              contact_number: handleDigitAPI(unmask(values.phone.trim())),
              document:
                values.documentType === "cpf"
                  ? unmask(values.cpf)
                  : values.documentType === "cnpj"
                  ? unmask(values.cnpj)
                  : null,
              password: values.newPassword ? values.newPassword : null,
              passwordConfirmation: values.passwordConfirmation
                ? values.passwordConfirmation
                : null,
            },
          },
        }
      : {
          data: {
            attributes: {
              name: values.name.trim(),
              companyName: values.companyName.trim(),
              contact_number: handleDigitAPI(unmask(values.phone.trim())),
              document:
                values.documentType === "cpf"
                  ? unmask(values.cpf)
                  : values.documentType === "cnpj"
                  ? unmask(values.cnpj)
                  : null,
              password: values.newPassword ? values.newPassword : null,
              passwordConfirmation: values.passwordConfirmation
                ? values.passwordConfirmation
                : null,
            },
          },
        };

    try {
      const { data } = await api.put(`/partners/partners/${user.id}`, {
        ...dataUser,
      });
      if (data) {
        updateUser(data);
        await checkComplete(user.id);
        toast.success("Dados da conta atualizados com sucesso!");
        form.setFieldValue(`avatar`, null);
        if (!checkList.partnerDetailsFilled && !checkList.activePlan) {
          navigate("/financeiro/step1");
        } else if (checkList.partnerDetailsFilled && !checkList.activePlan) {
          navigate("/financeiro/step1");
        } else if (!checkList.partnerDetailsFilled && checkList.activePlan) {
          navigate("/simulador");
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.errors[0]?.title?.message) {
        toast.error(
          `${error?.response?.data?.errors[0]?.title?.message} Por favor entre em contato com o suporte.`
        );
      } else
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
    } finally {
      setSaveLoading(false);
    }
  };

  // console.log(form.values);

  useEffect(() => {
    const fetchSubscriptionAndMetrics = async () => {
      setLoadingSubscription(true);
      try {
        const [subscriptionResponse, metricsResponse] = await Promise.all([
          api.get("partners/payment_subscriptions/last_active_subscription"),
          api.get("partners/montly_usage_history"),
        ]);
        setSubscription(subscriptionResponse.data?.data?.attributes);
        setMetrics(metricsResponse.data?.data?.usageStatistics);
      } catch (error) {
        console.error("Error fetching subscription and metrics:", error);
        toast.error("Erro ao carregar dados da assinatura e métricas.");
      } finally {
        setLoadingSubscription(false);
      }
    };

    fetchSubscriptionAndMetrics();
  }, []);

  return (
    <AuthorizedLayout>
      <MainCard variant="minimal" flex>
        <Card width={isMobileDevice() ? "100%" : "48.5%"}>
          {/* <div className="profile-avatar-container">
                      <Avatar
                        src={form.values.avatar || user?.avatar || ""}
                        text={getFirstName(form.values.name)}
                        width="50px"
                        height="50px"
                      />
                      <label htmlFor="fupload" className="profile-avatar-edit">
                        <Edit size={20} />
                      </label>
                      <input
                        type="file"
                        id="fupload"
                        name="fupload"
                        style={{ display: 'none' }}
                        accept=".jpeg, .png, .jpg"
                        onChange={handleFileInputChange}
                      />
                    </div> */}
          <Text h3>{getFirstName(form.values.name)}</Text>
          <div style={{marginTop: '5px'}}/>
          <Text b >Telefone:</Text>
          <Text>{form.values.phone}</Text>
          <div style={{marginTop: '5px'}}/>
          <Text b>CPF/CNPJ:</Text>
          <Text>
            {form.values.documentType === "cpf"
              ? formatCPF(form.values.cpf) || ""
              : formatCNPJ(form.values.cnpj) || ""}
          </Text>
        </Card>

        <Card width={isMobileDevice() ? "100%" : "50%"}>
          <Text b>Seu Negócio:</Text> <Text>{form.values.companyName}</Text>
          <div style={{marginTop: '5px'}}/>
          <Text b>Seu Plano:</Text>
          <Text>{subscription?.name || "Nenhum plano selecionado"}</Text>
          <div style={{marginTop: '5px'}}/>
          <Text b>Tokens disponíveis:</Text>
          <Text>{Number(metrics?.remainingTokens || 0)?.toLocaleString()}</Text>
          <div style={{marginTop: '10px'}}/>
          <Button
            icon={<ArrowRight />}
            onClick={() => navigate("/financeiro/step1")}
          >
            Alterar Plano
          </Button>
        </Card>

        {modalWarning && (
          <Modal visible={modalWarning} onClose={() => setModalWarning(false)}>
            <Modal.Title>ATENÇÃO</Modal.Title>
            <Modal.Content>
              <Text>
                Tem certeza de que deseja excluir sua conta? Esta ação é
                permanente e não pode ser desfeita.
              </Text>
            </Modal.Content>
            <Modal.Action passive onClick={() => setModalWarning(false)}>
              Cancelar
            </Modal.Action>
            <Modal.Action
              onClick={deleteAccount}
              loading={loadingDeleteAccount}
            >
              {loadingDeleteAccount ? "Excluindo..." : "Excluir"}
            </Modal.Action>
          </Modal>
        )}

        <Card>
          <Text h3>Editar dados da conta</Text>
          <div style={{marginTop: '10px'}}/>
          <form onSubmit={form.handleSubmit}>
            <FormInput
              label="Nome"
              name="name"
              value={form.values.name}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched.name && form.errors.name ? form.errors.name : null
              }
              validate={form.touched.name && form.errors.name}
              placeholder="Digite seu nome completo"
              width="100%"
            />

            <FormInput
              label="Nome do Negócio"
              name="companyName"
              value={form.values.companyName}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched.companyName && form.errors.companyName
                  ? form.errors.companyName
                  : null
              }
              validate={form.touched.companyName && form.errors.companyName}
              placeholder="Digite o nome do seu negócio"
              width="100%"
            />

            <FormInput
              label="E-mail"
              name="email"
              value={form.values.email}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched.email && form.errors.email
                  ? form.errors.email
                  : null
              }
              validate={form.touched.email && form.errors.email}
              htmlType="email"
              placeholder="exemplo@email.com"
              width="100%"
            />

            <FormInput
              label="Telefone"
              name="phone"
              value={form.values.phone}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched.phone && form.errors.phone
                  ? form.errors.phone
                  : null
              }
              validate={form.touched.phone && form.errors.phone}
              mask={"+99 99 99999-9999"}
              placeholder="(00) 00000-0000"
              width="100%"
            />

            <FormSelect
              label="Tipo de Documento"
              select
              value={form.values.documentType}
              onChange={(value) => form.setFieldValue("documentType", value)}
              width="100%"
              options={[
                { label: "CPF", value: "cpf", disabled: false },
                { label: "CNPJ", value: "cnpj", disabled: false },
              ]}
            />

            <FormInput
              fieldName={form.values.documentType === "cpf" ? "cpf" : "cnpj"}
              label={form.values.documentType === "cpf" ? "CPF" : "CNPJ"}
              name={form.values.documentType === "cpf" ? "cpf" : "cnpj"}
              value={
                form.values.documentType === "cpf"
                  ? form.values.cpf
                  : form.values.cnpj
              }
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched[form.values.documentType] &&
                form.errors[form.values.documentType]
                  ? form.errors[form.values.documentType]
                  : null
              }
              validate={
                form.touched[form.values.documentType] &&
                form.errors[form.values.documentType]
              }
              mask={
                form.values.documentType === "cpf"
                  ? "999.999.999-99"
                  : form.values.documentType === "cnpj"
                  ? "99.999.999/9999-99"
                  : ""
              }
              placeholder={
                form.values.documentType === "cpf"
                  ? "000.000.000-00"
                  : "00.000.000/0000-00"
              }
              form={form}
              width="100%"
            />

            <FormInput
              label="Nova Senha"
              name="newPassword"
              value={form.values.newPassword}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched.newPassword && form.errors.newPassword
                  ? form.errors.newPassword
                  : null
              }
              validate={form.touched.newPassword && form.errors.newPassword}
              type="password"
              helperText="Deixe em branco para manter a senha atual"
              width="100%"
            />

            <FormInput
              label="Confirmar Nova Senha"
              name="passwordConfirmation"
              value={form.values.passwordConfirmation}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched.passwordConfirmation &&
                form.errors.passwordConfirmation
                  ? form.errors.passwordConfirmation
                  : null
              }
              validate={
                form.touched.passwordConfirmation &&
                form.errors.passwordConfirmation
              }
              type="password"
              width="100%"
            />

            {/* <Button htmlType="submit" loading={saveLoading}>
              {saveLoading ? "Salvando..." : "Salvar alterações"}
            </Button> */}

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="filled">
                {saveLoading ? "Carregando..." : "Salvar alterações"}
              </Button>
            </div>
          </form>
        </Card>

        <Card width={isMobileDevice() ? "100%" : "100%"}>
          <Text h3>Excluir Conta</Text>
          <div style={{marginTop: '10px'}}/>
          <Text>
            Atenção: A exclusão da conta é uma ação permanente e não pode ser
            desfeita. Todos os seus dados serão removidos permanentemente.
          </Text>

          <div style={{ marginTop: "10px" }}>
            <Button variant="outline" onClick={() => setModalWarning(true)}>
              Excluir conta
            </Button>
          </div>
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default Profile;
