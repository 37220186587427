import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import ReminderConfig from "./screens/ReminderConfig";
import ScheduleConfig from "./screens/ScheduleConfig";

const AgendamentoRoutes = () => {
  return (
    <AuthorizedLayout>
      <Routes>
        <Route path="/" element={<ScheduleConfig />} />
        <Route path="/lembrete" element={<ReminderConfig />} />
      </Routes>
    </AuthorizedLayout>
  );
};


export default AgendamentoRoutes;
