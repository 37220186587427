import {
  Close,
  Info,
  InfoOutlined,
  WhatsApp,
  ZoomIn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api from "../../../utils/api";
import { Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ModalAlert from "../../../components/ModalAlert";
import { InputField } from "../../../components/InputField";
import { useFormik } from "formik";
import parsePhone from "../../../utils/parsePhone";
import { InputWithLabel } from "../../../components/InputWithLabel";
import { SelectWithLabel } from "../../../components/SelectWithLabel";
import Papa from "papaparse";
import makeAnimated from "react-select/animated";

import MainCard from "../../../components/MainCard";
import Card from "../../../components/Card";

import iconUser from "../../../assets/noAvatar.svg";
import iconArronw from "../../../assets/Vector.png";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import IdentitySection from "../../PartnerScreens/Settings/Components/Identity/index";
import StrategySection from "../../PartnerScreens/Settings/Components/Strategy/index";
import ProductsSection from "../../PartnerScreens/Settings/Components/Products/index";

import "./PartnerClientsList.css";
import GeistTable, {
  TableUser,
} from "../../../components/GeistTable/GeistTable";
import GeistPagination from "../../../components/GeistPagination/GeistPagination";

const Section = ({ title, children, isOpen, onToggle, description }) => {
  return (
    <div className="settingsSection">
      <button type="button" onClick={onToggle} className="sectionHeader">
        <div>
          <span className="title">{title}</span>
          {description && <span className="description">{description}</span>}
        </div>
        <span className="arrow">{isOpen ? "▼" : "▶"}</span>
      </button>
      {isOpen && <div className="sectionContent">{children}</div>}
    </div>
  );
};

const FormField = ({ label, children, description }) => (
  <div className="formField">
    <label>{label}</label>
    {description && <p className="description">{description}</p>}
    {children}
  </div>
);

export const PartnerClientsList = () => {
  const topArray = [
    { label: "ID", value: "id" },
    { label: "Nome", value: "clientName" },
    { label: "Telefone", value: "phoneNumber" },
  ];
  const [openSections, setOpenSections] = useState({
    instructions: false,
    identity: false,
    business: false,
    products: false,
    strategy: false,
    files: false,
  });

  const [clients, setClients] = useState([]);
  const [partnerSettings, setPartnerSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [clientId, setClientId] = useState(null);
  const { id } = useParams();
  const [section, setSection] = useState("identity");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const [menuSelected, setMenuSelected] = useState({
    menu: "Dados Básicos",
    index: 0,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const animatedComponents = makeAnimated();

  const optionsArray = [
    "Agendar uma reunião",
    "Adquirir email do cliente",
    "Outro",
  ];

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const scrollToSection1 = () => {
    section1Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection2 = () => {
    section2Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection3 = () => {
    section3Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const menuArray = [
    "Dados Básicos",
    "",
    "Produtos e Serviços",
    "",
    "Estratégia de Atendimento",
  ];

  const toneArray = [
    "Respeitoso & Empático",
    "Animado & Engajador",
    "Profissional & Firme",
    "Atencioso & Calmo",
    "Encorajador & Motivador",
    "Compassivo & Compreensivo",
    "Divertido & Cativante",
    "Cortês & Gentil",
    "Solidário & Amigável",
    "Eficiente & Rápido",
    "Confiável & Preciso",
    "Assertivo & Decisivo",
    "Perspicaz & Observador",
    "Outro",
  ];

  // useEffect(() => {
  //   console.log(selectedClient);
  // }, [selectedClient]);

  const form = useFormik({
    initialValues: {
      attendantInstructions: "",
      attendanceGoals: "",
      keyDifferentials: "",
      forbiddenResponses: "",
      conversationFlows: "",
      nameAttendant: "",
      companyName: "",
      companyNiche: "",
      servedRegion: "",
      companyProducts: "",
      companyServices: "",
      companyContact: "",
      toneVoice: [],
      toneVoiceExtra: "",
      preferentialLanguage: "",
      catalogLink: "",
      instagramLink: "",
      facebookLink: "",
      twitterXLink: "",
      youtubeLink: "",
    },
  });

  const getSettings = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`admins/partners/${id}`);

      if (data) {
        setClients(
          data.data.attributes.partnerClients
            .map((item) => {
              return {
                id: Number(item.id),
                clientName: item?.name || "Nome não informado",
                phoneNumber: item?.phone || "Número não informado",
                ...item,
              };
            })
            .sort((a, b) => {
              return a.id - b.id;
            })
        );

        let valorExtra = "";
        const valoresFiltrados = data.data?.attributes?.partnerDetail
          ?.company_objectives
          ? Object.keys(
              data.data.attributes.partnerDetail?.company_objectives
            ).reduce((acc, key) => {
              if (
                optionsArray.includes(
                  data.data.attributes.partnerDetail?.company_objectives[key]
                )
              ) {
                acc[key] =
                  data.data.attributes.partnerDetail?.company_objectives[key];
              } else {
                acc[key] = "Outro";
                valorExtra =
                  data.data.attributes.partnerDetail?.company_objectives[key];
              }

              return acc;
            }, [])
          : [];

        let toneExtra = "";
        const toneVoiceFiltrados = data.data?.attributes?.partnerDetail
          ?.tone_voice
          ? Object.keys(data.data.attributes.partnerDetail?.tone_voice).reduce(
              (acc, key) => {
                if (
                  toneArray.includes(
                    data.data.attributes.partnerDetail?.tone_voice[key]
                  )
                ) {
                  acc[key] =
                    data.data.attributes.partnerDetail?.tone_voice[key];
                } else {
                  acc[key] = "Outro";
                  toneExtra =
                    data.data.attributes.partnerDetail?.tone_voice[key];
                }

                return acc;
              },
              []
            )
          : [];

        form.setValues({
          ...data.data.attributes.partnerDetail,
          nameAttendant: data.data.attributes?.partnerDetail?.name_attendant,
          attendantInstructions:
            data.data.attributes?.partnerDetail?.attendant_instructions,
          companyName: data.data.attributes?.partnerDetail?.company_name,
          companyNiche: data.data.attributes?.partnerDetail?.company_niche,
          companyServices:
            data.data.attributes?.partnerDetail?.company_services,
          companyProducts:
            data.data.attributes?.partnerDetail?.company_products,
          catalogLink:
            data?.data?.attributes?.partnerDetail?.catalog_link || "",
          servedRegion: data.data.attributes?.partnerDetail?.served_region,
          keyDifferentials:
            data.data.attributes?.partnerDetail?.key_differentials,
          forbiddenResponses:
            data.data.attributes?.partnerDetail?.forbidden_responses,
          attendanceGoals: data.data.attributes?.partnerDetail?.attendanceGoals,
          companyContact: data.data.attributes?.partnerDetail?.company_contact,
          companyObjectives: valoresFiltrados,
          companyObjectivesExtra: valorExtra,
          marketingChannels:
            data.data.attributes?.partnerDetail?.marketing_channels,
          twitterXLink: data.data.attributes?.partnerDetail?.twitter_x_link,
          youtubeLink: data.data.attributes?.partnerDetail?.youtube_link,
          facebookLink: data.data.attributes?.partnerDetail?.facebook_link,
          instagramLink: data.data.attributes?.partnerDetail?.instagram_link,
          preferentialLanguage:
            data.data.attributes?.partnerDetail?.preferential_language,
          toneVoice: toneVoiceFiltrados,
          toneVoiceExtra: toneExtra,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = clients.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const selectedsNamesFilter = filterKeys.map((item) => item.value);

  const handleMenu = (item) => {
    setMenuSelected(item);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <AuthorizedLayout>
      <MainCard>
        <div className="settingsHeader">
          <h1 className="title">Perfil do parceiro</h1>
        </div>

        <div className="settingsContent">
          {/* Seção Principal: Instruções do Atendente */}
          <Section
            title="Instruções do Atendente"
            isOpen={openSections.instructions}
            onToggle={() => toggleSection("instructions")}
          >
            <FormField
              label="Instruções Principais"
              description="Descreva em detalhes como o atendente deve se comportar e responder"
            >
              <textarea
                name="attendantInstructions"
                value={form.values.attendantInstructions}
                className="formTextarea"
                disabled={true}
              />
            </FormField>

            <FormField
              label="Objetivos do Atendimento"
              description="Defina os principais objetivos que o atendente deve buscar alcançar"
            >
              <textarea
                name="attendanceGoals"
                value={form.values.attendanceGoals}
                className="formTextarea"
                disabled={true}
              />
            </FormField>

            <FormField
              label="Diferenciais do Negócio"
              description="Liste os principais pontos que fazem seu negócio se destacar e como o atendente deve enfatizá-los"
            >
              <textarea
                name="keyDifferentials"
                value={form.values.keyDifferentials}
                className="formTextarea"
                disabled={true}
              />
            </FormField>

            <FormField
              label="Respostas Proibidas"
              description="Liste temas ou tipos de respostas que o atendente deve evitar"
            >
              <textarea
                name="forbiddenResponses"
                value={form.values.forbiddenResponses}
                className="formTextarea"
                disabled={true}
              />
            </FormField>
            {/* 
                <FormField 
                  label="Fluxos de Conversa"
                  description="Defina os principais fluxos de conversa que o atendente deve seguir"
                >
                  <textarea
                    name="conversationFlows"
                    value={form.values.conversationFlows}
                    onChange={form.handleChange}
                    className="formTextarea"
                    placeholder="Ex: 1. Saudação inicial; 2. Identificar necessidade; 3. Oferecer solução..."
                  />
                </FormField> */}
          </Section>

          {/* Seção 1: Identidade e Comunicação */}
          <Section
            title="Identidade e Comunicação"
            isOpen={openSections.identity}
            onToggle={() => toggleSection("identity")}
          >
            <FormField
              label="Nome do Atendente"
              description="Nome que será usado pelo atendente virtual"
            >
              <div>
                <input
                  type="text"
                  name="nameAttendant"
                  value={form.values.nameAttendant}
                  disabled={true}
                  className={`formInput ${
                    form.touched.nameAttendant && form.errors.nameAttendant
                      ? "border-error"
                      : ""
                  }`}
                  // placeholder="Ex: Maria, João..."
                />
                {form.touched.nameAttendant && form.errors.nameAttendant && (
                  <div className="error">{form.errors.nameAttendant}</div>
                )}
              </div>
            </FormField>

            <FormField
              label="Tom de Voz"
              description="Escolha o estilo de comunicação do atendente"
            >
              <select
                name="toneVoice"
                value={form.values.toneVoice}
                disabled={true}
                multiple
                className="formInput"
              >
                {toneArray.map((tone, index) => (
                  <option key={index} value={tone}>
                    {tone}
                  </option>
                ))}
              </select>
            </FormField>

            {form.values.toneVoice.includes("Outro") && (
              <FormField
                label="Descrição do Tom de Voz Personalizado"
                description="Descreva o tom de voz específico desejado"
              >
                <input
                  type="text"
                  name="toneVoiceExtra"
                  value={form.values.toneVoiceExtra}
                  className="formInput"
                  disabled={true}
                />
              </FormField>
            )}

            <FormField label="Idioma de Resposta">
              <div>
                <select
                  name="preferentialLanguage"
                  value={form.values.preferentialLanguage}
                  disabled={true}
                  className={`formInput ${
                    form.touched.preferentialLanguage &&
                    form.errors.preferentialLanguage
                      ? "border-error"
                      : ""
                  }`}
                >
                  <option value="Português Brasileiro">
                    Português Brasileiro
                  </option>
                  <option value="Inglês">Inglês</option>
                  <option value="Japonês">Japonês</option>
                  <option value="Mandarim">Mandarim</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Espanhol">Espanhol</option>
                </select>
                {form.touched.preferentialLanguage &&
                  form.errors.preferentialLanguage && (
                    <div className="error">
                      {form.errors.preferentialLanguage}
                    </div>
                  )}
              </div>
            </FormField>
          </Section>

          {/* Seção 2: Informações do Negócio */}
          <Section
            title="Informações do Negócio"
            isOpen={openSections.business}
            onToggle={() => toggleSection("business")}
          >
            <FormField
              label="Nome Fantasia"
              description="Nome que o atendente usará para se referir ao seu negócio"
            >
              <div>
                <input
                  type="text"
                  name="companyName"
                  value={form.values.companyName}
                  disabled={true}
                  className={`formInput ${
                    form.touched.companyName && form.errors.companyName
                      ? "border-error"
                      : ""
                  }`}
                />
                {form.touched.companyName && form.errors.companyName && (
                  <div className="error">{form.errors.companyName}</div>
                )}
              </div>
            </FormField>

            {/* <FormField 
                  label="Campo de Atuação"
                  description="Área principal de atuação do seu negócio"
                >
                  <input
                    type="text"
                    name="servedRegion"
                    value={form.values.servedRegion}
                    onChange={form.handleChange}
                    className="formInput"
                    placeholder="Ex: Tecnologia, Saúde..."
                  />
                </FormField> */}

            <FormField
              label="Nicho Específico"
              description="Especificação detalhada do seu nicho de mercado"
            >
              <div>
                <input
                  type="text"
                  name="companyNiche"
                  value={form.values.companyNiche}
                  className={`formInput ${
                    form.touched.companyNiche && form.errors.companyNiche
                      ? "border-error"
                      : ""
                  }`}
                  disabled={true}
                />
                {form.touched.companyNiche && form.errors.companyNiche && (
                  <div className="error">{form.errors.companyNiche}</div>
                )}
              </div>
            </FormField>

            <FormField
              label="Site do Negócio"
              description="Endereço do seu site principal"
            >
              <div>
                <input
                  type="url"
                  name="companyContact"
                  value={form.values.companyContact}
                  disabled={true}
                  className={`formInput ${
                    form.touched.companyContact && form.errors.companyContact
                      ? "border-error"
                      : ""
                  }`}
                />
                {form.touched.companyContact && form.errors.companyContact && (
                  <div className="error">{form.errors.companyContact}</div>
                )}
              </div>
            </FormField>
          </Section>

          {/* Seção 3: Produtos e Serviços */}
          <Section
            title="Produtos e Serviços"
            isOpen={openSections.products}
            onToggle={() => toggleSection("products")}
          >
            <FormField
              label="Produtos"
              description="Liste os principais produtos do seu negócio"
            >
              <textarea
                name="companyProducts"
                value={form.values.companyProducts}
                disabled={true}
                className="formTextarea"
              />
            </FormField>

            <FormField
              label="Serviços"
              description="Descreva os serviços oferecidos"
            >
              <textarea
                name="companyServices"
                value={form.values.companyServices}
                disabled={true}
                className="formTextarea"
              />
            </FormField>
          </Section>

          {/* Seção 4: Canais de Comunicação */}
          <Section
            title="Canais de Comunicação"
            isOpen={openSections.strategy}
            onToggle={() => toggleSection("strategy")}
          >
            <FormField label="Redes Sociais" description="">
              <div className="space-y-3">
                <FormField label="Instagram">
                  <input
                    type="url"
                    name="instagramLink"
                    value={form.values.instagramLink}
                    disabled={true}
                    className="formInput"
                  />
                </FormField>

                <FormField label="Facebook">
                  <input
                    type="url"
                    name="facebookLink"
                    value={form.values.facebookLink}
                    disabled={true}
                    className="formInput"
                  />
                </FormField>

                <FormField label="X (Twitter)">
                  <input
                    type="url"
                    name="twitterXLink"
                    value={form.values.twitterXLink}
                    disabled={true}
                    className="formInput"
                  />
                </FormField>

                <FormField label="YouTube">
                  <input
                    type="url"
                    name="youtubeLink"
                    value={form.values.youtubeLink}
                    disabled={true}
                    className="formInput"
                  />
                </FormField>
              </div>
            </FormField>
          </Section>
        </div>

        <Card title="Lista de Clientes">
          <div className="cardClients">
            {currentItems.length <= 0 && !loading && (
              <div className="containerItens">
                <div className="client">
                  <div>Nenhum cliente encontrado.</div>
                </div>
              </div>
            )}

            {loading ? (
              <Loader size={100} />
            ) : (
              <GeistTable
                columns={[
                  {
                    field: "id",
                    label: "ID",
                    sortable: true,
                    render: (value) => value,
                  },
                  {
                    field: "clientName",
                    label: "Nome",
                    sortable: true,
                    render: (value) => value,
                  },

                  {
                    field: "phoneNumber",
                    label: "Telefone",
                    sortable: true,
                    render: (value) => parsePhoneAdmin(value),
                  },
                ]}
                data={currentItems}
                loading={loading}
                emptyText="Nenhum cliente encontrado"
                className="geist-table"
              />
            )}
          </div>

          <Grid xs={24}>
            <GeistPagination
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </Grid>
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default PartnerClientsList;
