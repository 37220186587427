import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FormControl, NativeSelect, Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import Papa from "papaparse";

import iconUser from "../../../assets/noAvatar.svg";
import iconArronw from "../../../assets/Vector.png";
import iconLUpa from "../../../assets/MagnifyingGlass.png";
import iconFilter from "../../../assets/Funnel.png";
import iconExport from "../../../assets/Export.png";

import "./AfiliateList.css";
import { FileUploadOutlined } from "@mui/icons-material";
import MainCard from "../../../components/MainCard";
import { Card, Grid, Loading } from "@geist-ui/core";
import GeistTableFilter from "../../../components/GeistTableFilter/GeistTableFilter";
import GeistTable, { TableUser } from "../../../components/GeistTable/GeistTable";
import Button from "../../../components/Button";
import GeistPagination from "../../../components/GeistPagination/GeistPagination";

const animatedComponents = makeAnimated();

const topArray = [
  { label: "ID", value: "id" },
  { label: "Nome", value: "clientName" },
  { label: "Email", value: "clientEmail" },
  { label: "Telefone", value: "phoneNumber" },
  { label: "Uso de Tokens", value: "tokenUsage" },
  { label: "Whatsapp", value: "wppConnected" },
  { label: "Google", value: "connectedWithGoogle" },
];

const AfiliateList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState("");

  const handlePlan = (value) => {
    if (value?.id) {
      return value?.name;
    } else {
      switch (value) {
        case "Nenhum plano ativo":
          return "Gratuito";

        default:
          return value;
      }
    }
  };

  const handleStatus = (value) => {
    if (value?.active) {
      switch (value.status) {
        case "Dados Preenchidos":
          return "Inativo";

        case "Cliente Ativo":
          return "Ativo";
        default:
          return value;
      }
    } else {
      return "Cadastrado";
    }
  };

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/admins/affiliates");
      if (data) {
        console.log(data);
        setClients(
          data.data.map((item) => {
            return {
              id: Number(item.id),
              clientName: item.attributes?.name || "Nome não informado",
              phoneNumber:
                item.attributes?.contactNumber || "Número não informado",
              clientEmail: item.attributes?.email || "Email não informado",
              tokenUsage: `${item.attributes?.montlyTokensConsumed || 0} / ${
                item.attributes?.montlyTokensConsumed +
                item.attributes?.monthlyTokensLeft
              }`,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = clients.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const selectedsNamesFilter = filterKeys.map((item) => item.value);

  const generateCSV = (data) => {
    // const formattedData = data.map((item) => ({
    //   ID: Number(item.id),
    //   Nome: item?.clientName || "Nome não informado",
    //   Email: item?.clientEmail || "Email não informado",
    //   Telefone: parsePhoneAdmin(item?.phoneNumber) || "Telefone não informado",
    //   Plano: item?.subscriptionT || "Plano não informado",
    //   Tokens: item?.tokenUsage || "Tokens não informado",
    //   Status: item?.statusT || "Status não informado",
    // }));

    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      Nome: item?.clientName || "Nome não informado",
      Email: item?.clientEmail || "N/A",
      Telefone: parsePhoneAdmin(item?.phoneNumber) || "Número não informado",
      Documento: item?.document || "N/A",
      Google: item?.connectedWithGoogle ? "Vinculado" : "Não Vinculado",
      Status: item?.active ? "Ativa" : "Inativa",
      "Data de Cadastro": item?.createdAt
        ? moment(item?.createdAt).format("DD/MM/YYYY")
        : "N/A",
      "Uso de Tokens": `${item?.montlyTokensConsumed || 0} / ${
        item?.montlyTokensConsumed + item?.monthlyTokensLeft
      }`,
    }));

    const csv = Papa.unparse(formattedData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `SacGPT Afiliados ${moment().format("DD-MM-YYYY")}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClose = () => {
    setTypeFilter("");
    setSearchTerm("");
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const truncateEmail = (email, maxLength) => {
    if (email.length <= maxLength) return email;

    const start = email.slice(0, 5); // Exibir os primeiros 5 caracteres
    const end = email.slice(-10); // Exibir os últimos 10 caracteres

    return `${start}...${end}`;
  };

  function extrairNomeAtePrimeiroEspaco(nomeCompleto) {
    const primeiroEspacoIndex = nomeCompleto.indexOf(" ");

    if (primeiroEspacoIndex === -1) {
      return nomeCompleto;
    }

    const nomeAtePrimeiroEspaco = nomeCompleto.substring(
      0,
      primeiroEspacoIndex
    );

    return nomeAtePrimeiroEspaco;
  }

  return (
    <AuthorizedLayout>
     <MainCard>
        <Card title="Lista de Clientes">
          <Grid.Container gap={0}>
            <Grid xs={24} style={{ marginBottom: "var(--spacing-lg)" }}>
              <GeistTableFilter
                searchTerm={searchTerm}
                onSearchChange={(value) => setSearchTerm(value)}
                onSearchClear={() => {
                  setSearchTerm("");
                  setTypeFilter("");
                }}
                filterKeys={filterKeys}
                selectedFilters={filterKeys.map((k) => k.value)}
                onFilterChange={(values) => {
                  setFilterKeys(
                    filterKeys.filter((item) => values.includes(item.value))
                  );
                }}
                onExport={generateCSV}
                exportData={currentItems}
              />
            </Grid>

            <Grid
              xs={24}
              style={{ marginTop: 0, width: "100%", display: "flex" }}
            >
              {loading ? (
                <div className="loading-container">
                  <Loading>Carregando afiliados...</Loading>
                </div>
              ) : currentItems.length === 0 ? (
                <div className="empty-state">Nenhum cliente encontrado</div>
              ) : (
                <GeistTable
                  columns={[
                    {
                      field: "id",
                      label: "ID",
                      sortable: true,
                      render: (value) => value,
                    },
                    {
                      field: "name",
                      label: "Nome / E-MAIl",
                      sortable: true,
                      render: (value, rowData) => (
                        <button
                          // onClick={() => {
                          //   setSelectedPartner(rowData);
                          //   setModal(true);
                          // }}
                        className="hover-button"
                        >
                          <TableUser
                            name={rowData.name}
                            email={rowData.email}
                            avatar={rowData.avatar || iconUser}
                          />
                        </button>
                      ),
                    },
                    {
                      field: "phoneNumber",
                      label: "Telefone",
                      sortable: true,
                      render: (value) => parsePhoneAdmin(value),
                    },
                  

                 
                    {
                      field: "tokenUsage",
                      label: "Uso de Tokens",
                      sortable: true,
                      render: (value) => value,
                    },
                    {
                      field: "wppConnected",
                      label: "Whatsapp",
                      sortable: true,
                      render: (value) =>
                        `${value?.wppConnected ? "Conectado" : "Desconectado"}`,
                    },
                    {
                      field: "connectedWithGoogle",
                      label: "Google",
                      sortable: true,
                      render: (value) =>
                        `${
                          value?.connectedWithGoogle
                            ? "Conectado"
                            : "Desconectado"
                        }`,
                    },

            
                  ]}
                  data={currentItems}
                  loading={loading}
                  emptyText="Nenhum cliente encontrado"
                  className="geist-table"
                />
              )}
            </Grid>

            <Grid xs={24}>
              <GeistPagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </Grid>
          </Grid.Container>
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default AfiliateList;
