import React, { useContext, useState } from "react";
import "./Step3.css";
import { FluxoAddProviderContext } from "./FluxoAddProvider";
import { MessageSquare, User, Clock, CheckCircle, Calendar, AlertTriangle } from '@geist-ui/icons';
import DoNotDisturb from "../../../../components/DoNotDisturb/DoNotDisturb";

import Stepper from './components/Stepper';
import Button from "../../../../components/Button";

const WeekDayButton = ({ day, label, selected, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`weekday-button ${selected ? 'selected' : ''}`}
  >
    {label}
  </button>
);

const Step3 = () => {
  const {
    formSchedules,
    setCurrentStep,
    loading,
    getSumary
  } = useContext(FluxoAddProviderContext);

  const [scheduleType, setScheduleType] = useState(formSchedules.values.triggerType || 'manual');

  const dias = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

  const handleScheduleTypeChange = (type) => {
    setScheduleType(type);
    formSchedules.setFieldValue('triggerType', type);
  };

  const handleDoNotDisturbChange = (checked) => {
    formSchedules.setFieldValue('dontDisturb', checked);
  };

  const handleDaySelect = (index) => {
    const dayStr = index.toString();
    const currentDays = formSchedules.values.dontDisturbWeekDays || [];
    
    if (currentDays.includes(dayStr)) {
      formSchedules.setFieldValue('dontDisturbWeekDays', 
        currentDays.filter(day => day !== dayStr)
      );
    } else {
      formSchedules.setFieldValue('dontDisturbWeekDays', 
        [...currentDays, dayStr]
      );
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading-container">
          <div className="loading-content">
            <div>Conectando...</div>
          </div>
        </div>
      ) : (
        <div className="container">
          <Stepper currentStep={3} />
          <div className="card">
            <div className="card-content">
              <div className="space-y-6">
                <div>
                  <h3 className="section-title">
                    <Calendar className="section-icon" />
                    Quando enviar as mensagens?
                  </h3>

                  <div className="schedule-options">
                    <button
                      className={`schedule-option ${scheduleType === 'manual' ? 'selected' : ''}`}
                      onClick={() => handleScheduleTypeChange('manual')}
                    >
                      <div className="option-content">
                        <div className="option-title">Enviar agora</div>
                        <div className="option-description">
                          Iniciar envio das mensagens imediatamente
                        </div>
                      </div>
                    </button>

                    <button
                      className={`schedule-option ${scheduleType === 'scheduled' ? 'selected' : ''}`}
                      onClick={() => handleScheduleTypeChange('scheduled')}
                    >
                      <div className="option-content">
                        <div className="option-title">Agendar envio</div>
                        <div className="option-description">
                          Programar data e hora específicas
                        </div>
                      </div>
                    </button>
                  </div>

                  {scheduleType === 'scheduled' && (
                    <div className="datetime-inputs">
                      <div className="input-group">
                        <label>Data de envio</label>
                        <input
                          type="date"
                          className="input"
                          value={formSchedules.values.triggerTime ? new Date(formSchedules.values.triggerTime).toISOString().split('T')[0] : ''}
                          onChange={(e) => {
                            const date = new Date(e.target.value);
                            formSchedules.setFieldValue('triggerTime', date);
                          }}
                        />
                      </div>
                      <div className="input-group">
                        <label>Horário</label>
                        <input
                          type="time"
                          className="input"
                          value={formSchedules.values.triggerTime ? new Date(formSchedules.values.triggerTime).toTimeString().slice(0,5) : ''}
                          onChange={(e) => {
                            const [hours, minutes] = e.target.value.split(':');
                            const date = formSchedules.values.triggerTime ? new Date(formSchedules.values.triggerTime) : new Date();
                            date.setHours(hours, minutes);
                            formSchedules.setFieldValue('triggerTime', date);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Alerta de Envio Gradual */}
                <div className="alert-box">
                  <AlertTriangle className="alert-icon" />
                  <div>
                    <h4 className="alert-title">Envio Gradual</h4>
                    <p className="alert-message">
                      Para evitar bloqueios do WhatsApp, as mensagens serão enviadas gradualmente, 
                      respeitando limites de tempo entre cada envio.
                    </p>
                  </div>
                </div>

                {/* Não Perturbe */}
                <DoNotDisturb
                  enabled={formSchedules.values.dontDisturb}
                  onEnabledChange={handleDoNotDisturbChange}
                  startTime={formSchedules.values.dontDisturbDispatchStart ? 
                    new Date(formSchedules.values.dontDisturbDispatchStart).toTimeString().slice(0,5) : 
                    '22:00'
                  }
                  onStartTimeChange={(value) => {
                    const [hours, minutes] = value.split(':');
                    const date = new Date();
                    date.setHours(hours, minutes);
                    formSchedules.setFieldValue('dontDisturbDispatchStart', date);
                  }}
                  endTime={formSchedules.values.dontDisturbDispatchEnd ? 
                    new Date(formSchedules.values.dontDisturbDispatchEnd).toTimeString().slice(0,5) : 
                    '08:00'
                  }
                  onEndTimeChange={(value) => {
                    const [hours, minutes] = value.split(':');
                    const date = new Date();
                    date.setHours(hours, minutes);
                    formSchedules.setFieldValue('dontDisturbDispatchEnd', date);
                  }}
                  selectedDays={formSchedules.values.dontDisturbWeekDays}
                  onDaySelect={(days) => formSchedules.setFieldValue('dontDisturbWeekDays', days)}
                />
              </div>

              {/* Botões de Navegação */}
              <div className="navigation-buttons">
 


                <Button
                  variant="outline"
                  onClick={() => {
                    setCurrentStep("step2");
                  }}
                >
                  Voltar
                </Button>

                <Button
                  variant="filled"
                  onClick={async () => {
                    await getSumary();
                    setCurrentStep("step4");
                  }}
                >
                  Próximo
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Step3;
