import React from 'react';
import './Card.css';

const Card = ({ children, title, width }) => {
  const style = width ? { width } : {};
  
  return (
    <div className="card" style={style}>
      {title && <h3 className="card-title">{title}</h3>}
      <div className="card-content">
        {children}
      </div>
    </div>
  );
};

export default Card;