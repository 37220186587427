import React from "react";
import "./LeadsGraph.css";
import moment from "moment";
import YearSelector from "./YearSelector";

const LeadsGraph = ({ selectedYear, onYearChange, monthLeads }) => {
  const labelMonth = {
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dez",
  };

  const totalLeads = monthLeads?.reduce(
    (sum, data) => sum + data?.leadCount,
    0
  );

  return (
    <div className="leads-graph">
      <div className="header">
        <div className="header-with-selector">
          <span className="text">Gráfico de novos leads / mês:</span>
          {/* <YearSelector 
            selectedYear={selectedYear}
            onChange={onYearChange}
          /> */}
        </div>
      </div>
      <div className="graph-container">
        {monthLeads?.map((data, index) => {
          const height =
            totalLeads > 0 ? (data?.leadCount / totalLeads) * 168 : 4;
          return (
            <div key={index} className="bar">
              <div className="fill">
                <span
                  className={`leads ${data?.leadCount === 0 ? "empty" : ""}`}
                  style={{ height: `${height}px` }}
                >
                  {data?.leadCount > 0 ? data.leads : ""}
                </span>
              </div>
              <div className="label">{labelMonth[data?.month]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeadsGraph;
