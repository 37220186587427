import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from "@mui/material";

import MainCard from "../../../../../components/MainCard";
import Card from "../../../../../components/Card";
import Button from "../../../../../components/Button";

import api from "../../../../../utils/api";
import arrow from "../../../../../assets/Vector.png";
import CpuIcon from "../../../../../assets/Cpu.png";

import "./SimulatorSection.css";

const SimulatorSection = ({ saveLoading, menuSelected, sectionSimulator }) => {
  const currentMonth = parseInt(moment().format("M"));
  const currentYear = moment().year();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingMenssage, setLoadingMessage] = useState(false);
  const [plans, setPlans] = useState([]);
  const [modalWarning, setModalWarning] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [countToken, setCountToken] = useState(0);
  const [countTokenLoading, setCountTokenLoading] = useState(false);
  const [tokensDate, setTokensDate] = useState({
    month: currentMonth,
    year: currentYear,
  });
  const [reports, setReports] = useState({});
  const navigate = useNavigate();

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const getMetrics = async (month, year) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/partners/partner_reports`, {
        params: { month, year },
      });

      if (data) {
        setReports(data.data.reports);
      }
    } catch (error) {
      toast.error("Não foi possível carregar os dados.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetrics(tokensDate.month, tokensDate.year);
  }, [tokensDate]);

  const formatDate = (dateStr) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Cria o objeto Date usando o formato ISO para evitar problemas de fuso horário
    const inputDate = new Date(dateStr);

    // Ajusta a data para o fuso horário local
    const localInputDate = new Date(
      inputDate.toLocaleString("en-US", { timeZone: "UTC" })
    );

    // Define a data de hoje ajustada para o fuso horário local
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Define uma função para comparar datas sem considerar a hora
    const isSameDay = (d1, d2) => {
      return d1.toDateString() === d2.toDateString();
    };

    if (isSameDay(localInputDate, today)) {
      return "hoje";
    }

    return localInputDate.toLocaleDateString("pt-BR", options);
  };

  const checkTokens = async () => {
    try {
      setCountTokenLoading(true);
      const { data } = await api.get(
        `/partners/partner_test_bot/test_bot_lead`
      );
      setCountToken(data.data.attributes.tokenCount);
      setCountTokenLoading(false);
    } catch (error) {
      setCountTokenLoading(false);
    } finally {
      setCountTokenLoading(false);
    }
  };

  const toggleObjective = async () => {
    if (newMessage?.trim()) {
      const text = newMessage;
      try {
        setNewMessage("");
        const { data } = await api.post(`/partners/partner_test_bot/messages`, {
          data: {
            attributes: {
              message: text,
            },
          },
        });
        if (data) {
          // console.log(data);
          setMessages([
            ...messages,
            {
              date: moment(data.data?.attributes?.createdAt).format(
                "YYYY-MM-DD"
              ),
              time: moment(data.data?.attributes?.createdAt).format("hh:mm A"),
              send: data.data?.attributes?.message || "",
              received: data.data?.attributes?.automaticResponse || "",
              createdAt: data.data?.attributes?.createdAt,
            },
          ]);
        }
        checkTokens();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const groupedMessages = messages.reduce((group, msg) => {
    const { date } = msg;
    if (!group[date]) {
      group[date] = [];
    }
    group[date].push(msg);
    return group;
  }, {});

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
    setLoadingMessage(false);
  }, [messages]);

  useEffect(() => {
    if (loadingMenssage) {
      scrollToBottom();
    }
  }, [loadingMenssage]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`partners/payment_plans`);
      console.log(data);
      if (data) {
        setPlans(
          data.data
            .filter((item) => !item.attributes.disable)
            .map((item) => {
              return {
                title: item.attributes.name,
                tokens: item?.attributes?.maxTokenCount,
              };
            })
        );
        //console.log(data.data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados do parceiro.");
    } finally {
      setLoading(false);
    }
  };

  const getChatHistory = async () => {
    try {
      const { data } = await api.get(`/partners/partner_test_bot/messages`);
      if (data?.data) {
        setMessages(
          data?.data?.map((item) => {
            return {
              date: moment(item?.attributes?.createdAt).format("YYYY-MM-DD"),
              time: moment(item?.attributes?.createdAt).format("hh:mm A"),
              send: item?.attributes?.message || "",
              received: item?.attributes?.automaticResponse || "",
              createdAt: item?.attributes?.createdAt,
            };
          })
        );
        // console.log(data.data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar as mensagens.");
    } finally {
    }
  };

  useEffect(() => console.log(messages), [messages]);

  const fetchRef = useRef();

  useEffect(() => {
    const getLatestMessage = async () => {
      try {
        const { data } = await api.get(
          `/partners/partner_test_bot/last_message`
        );
        if (data?.data) {
          const now = moment();
          const createdAt = moment(data.data?.attributes?.createdAt);
          const timeDifference = now.diff(createdAt, "minutes");

          if (
            data.data?.attributes?.automaticResponse === null &&
            data.data?.attributes?.message !== null &&
            timeDifference < 1
          ) {
            setLoadingMessage(true);
          } else if (timeDifference > 1) {
            setLoadingMessage(false);
          }

          if (timeDifference > 1) {
            setLoadingMessage(false);
          }

          if (
            data.data?.attributes?.createdAt ===
            messages[messages.length - 1]?.createdAt
          ) {
            console.log("equal timestamp");
            if (
              (data.data?.attributes?.automaticResponse || "") !==
              messages[messages.length - 1]?.received
            ) {
              console.log("different responses");
              setMessages((prevMessages) => {
                const newArray = [...prevMessages];
                newArray[newArray.length - 1] = {
                  date: moment(data.data?.attributes?.createdAt).format(
                    "YYYY-MM-DD"
                  ),
                  time: moment(data.data?.attributes?.createdAt).format(
                    "hh:mm A"
                  ),
                  send: data.data?.attributes?.message || "",
                  received: data.data?.attributes?.automaticResponse || "",
                  createdAt: data.data?.attributes?.createdAt,
                };

                return newArray;
              });
            }
          } else {
            console.log(
              "different timestamp",
              `timestamp 1: ${data.data?.attributes?.createdAt}, timestamp 2: ${
                messages[messages.length - 1]?.createdAt
              }`
            );
            console.log("messages -> ", messages);
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                date: moment(data.data?.attributes?.createdAt).format(
                  "YYYY-MM-DD"
                ),
                time: moment(data.data?.attributes?.createdAt).format(
                  "hh:mm A"
                ),
                send: data.data?.attributes?.message || "",
                received: data.data?.attributes?.automaticResponse || "",
                createdAt: data.data?.attributes?.createdAt,
              },
            ]);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    fetchRef.current = getLatestMessage;
  }, [messages]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchRef.current();
    }, 5000);

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    checkTokens();
    getData();
    getChatHistory();
  }, []);

  const sortedPlans = plans.sort((a, b) => Number(a.tokens) - Number(b.tokens));

  const resetChat = async () => {
    setLoadingReset(true);
    try {
      await api.delete(`/partners/partner_test_bot/destroy_all_messages`);
      toggleObjective();
      // getLatestMessage();
      getChatHistory();
      setModalWarning(false);
      setCountToken(0);
    } catch (error) {
    } finally {
      setLoadingReset(false);
    }
  };

  return (
    <>
      <MainCard title="Simulador do Atendente" ref={sectionSimulator} flex>
        <Card title="Tokens e Planos" width={isMobileDevice() ? "100%" : "28%"}>
          <div className="tokens-used">
            <img src={CpuIcon} alt="arrow" />
            <div style={{ textAlign: "left" }}>
              <div className="subtitle" style={{ fontWeight: "400" }}>
                Tokens utilizados no simulador:
              </div>
              <div className="subtitle">
                {countTokenLoading ? (
                  <div style={{ height: "36px" }}>
                    <span className="typing">{""}</span>
                  </div>
                ) : (
                  `${countToken} tokens`
                )}
              </div>

              <div
                className="subtitle"
                style={{ fontWeight: "400", marginTop: "5px" }}
              >
                Tokens consumidos:
              </div>
              <div className="subtitle">
                <strong>
                  {(
                    reports?.usageStatistics?.montlyTokensConsumed || 0
                  ).toLocaleString()}
                </strong>
                {" / "}
                {(
                  (reports?.usageStatistics?.montlyTokensConsumed || 0) +
                  (reports?.usageStatistics?.monthlyTokensLeft || 0)
                ).toLocaleString()}
              </div>
            </div>
          </div>
          <div className="plans">
            {!loading &&
              sortedPlans.map((plan, index) => (
                <div key={index} className="containerText">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/financeiro/step1")}
                  >
                    <div
                      className="cicle"
                      style={{
                        position: "absolute",
                        left: "-14px",
                        top: "4px",
                      }}
                    >
                      o
                    </div>
                    <div className="text">{plan?.title}</div>
                  </div>
                  <div className="subText">{`${Number(
                    plan?.tokens
                  ).toLocaleString()} tokens`}</div>
                </div>
              ))}
          </div>
        </Card>

        <Card title="Chat" width={isMobileDevice() ? "100%" : "70%"}>
          <div className="chat-box">
            <div className="containerGroupMensagens">
              {messages.length <= 0 && (
                <div className="dateHeader">
                  Envie uma mensagem para iniciar a sua conversa!
                </div>
              )}

              <div className="containerAllBoxMessages" ref={chatContainerRef}>
                {Object.keys(groupedMessages).map((date, index) => (
                  <div key={index} className="containerBoxMenssagens">
                    <div className="dateHeader">{formatDate(date)}</div>
                    {groupedMessages[date].map((item, msgIndex) => (
                      <div
                        key={msgIndex}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "30px",
                          width: "100%",
                        }}
                      >
                        {item.send !== "" && (
                          <div className="message sent">
                            <div>{item.send}</div>
                            <span className="time">{item.time}</span>
                          </div>
                        )}

                        {item.received !== "" && (
                          <div className="message received">
                            <div>{item.received}</div>
                            <span className="time">{item.time}</span>
                          </div>
                        )}
                      </div>
                    ))}
                    {messages.length > 0 && loadingMenssage && (
                      <div className="message received">
                        <div style={{ minHeight: "36px" }}>
                          <span className="typing">{""}</span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <form
              className="containerIputMenssage"
              onSubmit={(e) => {
                e.preventDefault();
                toggleObjective();
              }}
            >
              <input
                type="text"
                placeholder="Digite uma mensagem..."
                className="inputMenssage"
                onChange={(event) => setNewMessage(event.target.value)}
                value={newMessage}
              />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  toggleObjective();
                }}
                variant="primary"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "100px",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={arrow} alt="arrow" />
              </Button>
            </form>
          </div>

          <div
            className="containerButtons"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              onClick={() => setModalWarning(true)}
              variant="outline"
              style={{
                border: "2px solid red",
                color: "#102649",
                marginTop: "20px",
              }}
            >
              Reiniciar conversa
            </Button>
          </div>
        </Card>
      </MainCard>

      <Modal
        open={modalWarning}
        onClose={() => setModalWarning(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobileDevice() ? "90%" : 400,
            backgroundColor: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            padding: "24px",
          }}
        >
          <h2
            id="modal-title"
            style={{
              color: "#e52207",
              textAlign: "center",
              marginBottom: "16px",
            }}
          >
            ATENÇÃO
          </h2>
          <p
            id="modal-description"
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "16px",
              marginBottom: "24px",
            }}
          >
            O histórico de conversa atual será perdido e a contagem de tokens da
            conversa será reiniciada. No entanto, os tokens já consumidos não
            serão repostos.
          </p>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "16px" }}
          >
            <Button
              onClick={resetChat}
              variant="primary"
              disabled={saveLoading}
              style={{ backgroundColor: "#e52207" }}
            >
              {loadingReset ? "APAGANDO..." : "APAGAR"}
            </Button>
            <Button
              onClick={() => setModalWarning(false)}
              variant="secondary"
              disabled={saveLoading}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SimulatorSection;
