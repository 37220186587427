import React, { useContext, useState } from "react";
import { ClickAwayListener, Grow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  ArrowForward,
  Notifications,
  NotificationsOff,
  PersonAdd,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { Loader } from "../../Loader";

import "./ContentContainer.css";
import Button from "../../Button";

const ContentContainer = ({
  children,
  notifications,
  setNotifications,
  loading,
  notificationsVisible,
  setNotificationsVisible,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const handleNotificationTitle = (title, name) => {
    switch (title) {
      case "Novo Lead Recebido":
        return (
          <>
            <strong>{name}</strong> agora é sua Lead. Veja o resumo e
            classificação de interesse.
          </>
        );

      default:
        return <>{title}</>;
    }
  };

  const handleClick = async (notification) => {
    try {
      if (notification.title === "Novo Lead Recebido" && notification.id) {
        navigate(`/chat/${notification.id}`);
      }

      await api.put(`partners/notifications/${notification.notificationId}`);

      setNotifications(
        notifications.filter((item) => {
          return item.notificationId !== notification.notificationId;
        })
      );
    } catch (error) {}
  };

  const clearNotifications = async () => {
    setLoadingNotifications(true);
    try {
      const requests = notifications.map((notification) =>
        api.put(`partners/notifications/${notification.notificationId}`)
      );

      await Promise.all(requests);
      setNotifications([]);
    } catch (error) {
      console.error("Erro ao atualizar notificações:", error);
    } finally {
      setLoadingNotifications(false);
    }
  };

  const NotificationCard = ({ notification }) => {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          marginBottom: "5px",
          display: "flex",
          minHeight: "50px",
          justifyContent: "space-around",
          alignItems: "center",
          border: "1px solid #122137",
          width: "100%",
          padding: "5px",
          cursor: "pointer",
          ":hover": {
            opacity: "75%",
          },
        }}
        onClick={() => {
          handleClick(notification);
        }}
      >
        <div
          style={{ display: "flex", width: "15%", justifyContent: "center" }}
        >
          {notification.title === "Novo Lead Recebido" ? (
            <PersonAdd color="primary" />
          ) : (
            <Notifications color="primary" />
          )}
        </div>
        <div
          style={{ display: "flex", width: "70%", justifyContent: "center" }}
        >
          <Typography fontSize={14} textAlign={"justify"}>
            {handleNotificationTitle(
              notification.title,
              notification.clientName
            )}
          </Typography>
        </div>
        {notification.title === "Novo Lead Recebido" ? (
          <div
            style={{ display: "flex", width: "15%", justifyContent: "center" }}
          >
            <ArrowForward color="primary" />
          </div>
        ) : (
          <div
            style={{ display: "flex", width: "15%", justifyContent: "center" }}
          ></div>
        )}
      </Box>
    );
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let widthModal = isMobileDevice() ? 5 : 380;

  return (
    <>
      {user.userType === "admin" ? null : (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            pointerEvents: "none",
          }}
        >
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={() =>
              notificationsVisible ? setNotificationsVisible(false) : null
            }
          >
            <Grow
              in={notificationsVisible}
              style={{ transformOrigin: "right top" }}
              {...(notificationsVisible ? { timeout: 1000 } : {})}
            >
              <Box
                sx={{
                  zIndex: 5,
                  width: "400px",
                  height: "500px",
                  backgroundColor: "#e6e9ed",
                  marginRight: `${widthModal}px`,
                  marginTop: "80px",
                  borderRadius: "10px",
                  border: "2px solid #122137",
                  padding: "15px",
                  boxShadow: 16,

                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor: "transparent transparent",
                  "&::-webkit-scrollbar": {
                    width: 6,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#122137",
                    borderRadius: "0px 10px 10px 0px",
                  },

                  pointerEvents: "auto",
                }}
              >
                {loading ? (
                  <Loader size={100} />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    {notifications && notifications?.length > 0 ? (
                      notifications.map((item, index) => (
                        <NotificationCard notification={item} key={index} />
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "15px",
                        }}
                      >
                        <NotificationsOff fontSize="large" />
                        <Typography
                          color={"#122137"}
                          fontSize={18}
                          fontWeight={600}
                          textAlign={"center"}
                          marginTop={"15px"}
                        >
                          {"Nenhuma notificação encontrada"}
                        </Typography>
                      </div>
                    )}

                    {notifications.length > 0 && (
                      <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="filled"
                        onClick={() => clearNotifications()}
                      >
                        {loadingNotifications ? 'Limpando...' : 'Limpar todas as notificações'}
                      </Button>
                    </div>
                    )}
                  </div>
                )}
              </Box>
            </Grow>
          </ClickAwayListener>
        </Box>
      )}

      <div className="contentContainer">{children}</div>
    </>
  );
};

export default ContentContainer;
