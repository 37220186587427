import React from 'react';
import './Button.css';

const Button = ({ children, onClick, variant = 'default', icon, disabled = false }) => {
  const getButtonClass = () => {
    let className = 'button';
    if (variant === 'outline') className += ' button-outline';
    if (variant === 'filled') className += ' button-filled';
    if (icon) className += ' button-with-icon';
    return className;
  };

  return (
    <button className={getButtonClass()} onClick={onClick} disabled={disabled}>
      {icon && <span className="button-icon">{icon}</span>}
      <span className="button-text">{children}</span>
    </button>
  );
};

export default Button;