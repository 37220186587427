/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import api from "../utils/api";
import moment from "moment";
import { toast } from "react-toastify";
import unmask from "../utils/unmask";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState("");
  const [loading, setLoading] = useState(true);
  const [checkLoading, setCheckLoading] = useState(false);
  const [checkList, setCheckList] = useState({});

  const checkWIzard = localStorage.getItem("@sacgpt:checkWizard");

  useEffect(() => {
    const token = localStorage.getItem("@sacgpt:token");
    const userStorage = localStorage.getItem("@sacgpt:user");

    if (userStorage) {
      setAuthData(JSON.parse(userStorage));
      setCheckList({
        profileFilled: JSON.parse(userStorage).profileFilled,
        partnerDetailsFilled: JSON.parse(userStorage).partnerDetailsFilled,
        activePlan: JSON.parse(userStorage).activePlan,
        wizard:
          JSON.parse(userStorage).partnerDetailsFilled ||
          checkWIzard === "true",
      });
    }
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
    setLoading(false);
  }, []);

  const updateUser = async (data) => {
    const user = data.data.attributes;
    localStorage.setItem(
      "@sacgpt:user",
      JSON.stringify({
        ...user,
        id: data.data.id,
        userType: "partner",
        authToken: authData.authToken,
        expiresAt: authData.expiresAt,
      })
    );
    setAuthData({
      ...user,
      id: data.data.id,
      userType: "partner",
      authToken: authData.authToken,
      expiresAt: authData.expiresAt,
    });
  };

  const checkComplete = async (id) => {
    setCheckLoading(true);
    try {
      const { data } = await api.get(`partners/partners/${id}`);

      if (data) {
        setCheckList({
          profileFilled: data?.data?.attributes?.profileFilled,
          partnerDetailsFilled: data?.data.attributes?.partnerDetailsFilled,
          activePlan: data?.data?.attributes?.activePlan,
          wizard: checkWIzard === "true",
        });

        updateUser(data);
      }
    } catch (error) {
    } finally {
      setCheckLoading(false);
    }
  };

  const signIn = async ({ email, password }) => {
    try {
      const { data } = await api.post(`/partners/authenticate`, {
        email: email.toLowerCase().trim(),
        password: password.trim(),
      });
      const user = data.data.attributes;
      user.id = data.data.id;

      if (user.authToken) {
        // Adding it's token to all requests for now on.
        api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
        localStorage.setItem("@sacgpt:token", user.authToken);
        localStorage.setItem(
          "@sacgpt:user",
          JSON.stringify({ ...user, id: data.data.id, userType: "partner" })
        );
        setAuthData({ ...user, id: data.data.id, userType: "partner" });
        setCheckList({
          profileFilled: data?.data?.attributes?.profileFilled,
          partnerDetailsFilled: data?.data.attributes?.partnerDetailsFilled,
          activePlan: data?.data?.attributes?.activePlan,
          wizard:
            data?.data.attributes?.partnerDetailsFilled ||
            checkWIzard === "true",
        });
        // checkComplete(user.id);
      } else {
        throw new Error("Token not found");
      }
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const signInAdmin = async ({ email, password }) => {
    try {
      const { data } = await api.post(`/admins/authenticate`, {
        email: email.toLowerCase().trim(),
        password: password.trim(),
      });
      const user = data.data.attributes;
      user.id = data.data.id;

      if (user.authToken) {
        // Adding it's token to all requests for now on.
        api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
        localStorage.setItem("@sacgpt:token", user.authToken);
        localStorage.setItem(
          "@sacgpt:user",
          JSON.stringify({ ...user, id: data.data.id, userType: "admin" })
        );
        setAuthData({ ...user, id: data.data.id, userType: "admin" });
      } else {
        throw new Error("Token not found");
      }
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const signInSales = async ({ email, password }) => {
    try {
      const { data } = await api.post(`/affiliates/authenticate`, {
        email: email.toLowerCase().trim(),
        password: password.trim(),
      });

      const user = data.data.attributes;
      user.id = data.data.id;

      // const user = {
      //   name: "Julio Caldas Serviços Imobiliários",
      //   email: "suporte@juliocaldas.com",
      //   serviceNumber: "22992776050",
      //   contactNumber: "12982106031",
      //   document: "22009031000146",
      //   partnerDetailId: 20,
      //   active: true,
      //   createdAt: "2023-08-30T14:28:50.391Z",
      //   updatedAt: "2024-03-15T19:43:27.449Z",
      //   authToken:
      //     "eyJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2lkIjoyNiwiZXhwIjoxNzE0MTM5NDI0LCJtb2RlbF9uYW1lIjoiTWFuYWdlciJ9.Bb8xV7WkulMy4L07gNvusfIO9PTJJCjczboJq2Qy-z8",
      //   expiresAt: "2024-04-26T13:50:24.960Z",
      //   id: "26",
      //   userType: "sales",
      // };

      if (user.authToken) {
        // Adding it's token to all requests for now on.
        api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
        localStorage.setItem("@sacgpt:token", user.authToken);
        localStorage.setItem(
          "@sacgpt:user",
          JSON.stringify({ ...user, id: user.id, userType: "sales" })
        );
        setAuthData({ ...user, id: user.id, userType: "sales" });
      } else {
        throw new Error("Token not found");
      }
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const signUp = async ({ values }) => {
    //console.log(values);
    try {
      const { data } = await api.post("/partners/partners", {
        data: {
          attributes: {
            name: values.name.trim(),
            email: values.email.toLowerCase().trim(),
            contactNumber: unmask(values.phone.trim()),
            // serviceNumber: unmask(values.phone.trim()),
            password: values.password.trim(),
            passwordConfirmation: values.passwordConfirmation.trim(),
            // document:
            //   values.documentType === "cpf"
            //     ? unmask(values.cpf)
            //     : values.documentType === "cnpj"
            //     ? unmask(values.cnpj)
            //     : null,
            // affiliateId: values?.affiliatedId
            //   ? Number(values?.affiliatedId)
            //   : null,
          },
        },
      });

      // const { data } = await api.post("/partners/partners", {
      //   data: {
      //     attributes: {
      //       name: "Confiança planejamento financeiro",
      //       email: "producao@agathaejoaquimconstrucoesme.com.br",
      //       serviceNumber: "6139649902",
      //       contactNumber: "6139649902",
      //       document: "71937238040",
      //       password: "12345678",
      //       passwordConfirmation: "12345678",
      //       affiliateId: 7,
      //     },
      //   },
      // });

      if (data) {
        toast.success("Cadastro realizado com sucesso!");

        //console.log(data.data);

        const user = data.data.attributes;
        user.id = data.data.id;

        if (user.authToken) {
          // Adding it's token to all requests for now on.
          api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
          localStorage.setItem("@sacgpt:token", user.authToken);
          setCheckList({
            profileFilled: data?.data?.attributes?.profileFilled,
            partnerDetailsFilled: data?.data.attributes?.partnerDetailsFilled,
            activePlan: data?.data?.attributes?.activePlan,
            wizard:
              data?.data.attributes?.partnerDetailsFilled ||
              checkWIzard === "true",
          });
          localStorage.setItem(
            "@sacgpt:user",
            JSON.stringify({ ...user, id: data.data.id, userType: "partner" })
          );
          setAuthData({ ...user, id: data.data.id, userType: "partner" });

          return "success";
        }
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        if (
          Array(...error?.response?.data?.errors).some((item) => {
            return item?.title === "Email has already been taken";
          })
        ) {
          toast.error("O email inserido já está em uso.");
        } else toast.error("Ocorreu um erro ao tentar realizar o cadastro.");
      } else toast.error("Ocorreu um erro ao tentar realizar o cadastro.");

      throw new Error(error);
    }
  };

  const signUpSales = async ({ values }) => {
    //console.log(values);
    try {
      const { data } = await api.post(`/affiliates/affiliates/`, {
        data: {
          attributes: {
            name: values.name.trim(),
            email: values.email.toLowerCase().trim(),
            contactNumber: unmask(values.phone.trim()),
            // serviceNumber: unmask(values.phone.trim()),
            password: values.password.trim(),
            passwordConfirmation: values.passwordConfirmation.trim(),
            document:
              values.documentType === "cpf"
                ? unmask(values.cpf)
                : values.documentType === "cnpj"
                ? unmask(values.cnpj)
                : null,
          },
        },
      });

      if (data) {
        toast.success("Cadastro realizado com sucesso!");

        //console.log(data.data);

        const user = data.data.attributes;
        user.id = data.data.id;

        if (user.authToken) {
          // Adding it's token to all requests for now on.
          api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
          localStorage.setItem("@sacgpt:token", user.authToken);
          localStorage.setItem(
            "@sacgpt:user",
            JSON.stringify({ ...user, id: data.data.id, userType: "sales" })
          );
          setAuthData({ ...user, id: data.data.id, userType: "sales" });

          return "success";
        }
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        if (
          Array(...error?.response?.data?.errors).some((item) => {
            return item?.title === "Email has already been taken";
          })
        ) {
          toast.error("O email inserido já está em uso.");
        } else toast.error("Ocorreu um erro ao tentar realizar o cadastro.");
      } else toast.error("Ocorreu um erro ao tentar realizar o cadastro.");

      throw new Error(error);
    }
  };

  const updateAffiliated = async (data) => {
    const user = data.data.attributes;
    localStorage.setItem(
      "@sacgpt:user",
      JSON.stringify({
        ...user,
        id: data.data.id,
        userType: "sales",
        authToken: authData.authToken,
        expiresAt: authData.expiresAt,
      })
    );
    setAuthData({
      ...user,
      id: data.data.id,
      userType: "sales",
      authToken: authData.authToken,
      expiresAt: authData.expiresAt,
    });
  };

  const updatePartnerDetail = async (data) => {
    //console.log(data);
    localStorage.setItem(
      "@sacgpt:user",
      JSON.stringify({
        ...authData,
        partnerDetailId: data.data.id,
      })
    );
    setAuthData({
      ...authData,
      partnerDetailId: data.data.id,
    });
  };

  const signOutIfTokenExpired = async () => {
    const user = await localStorage.getItem("@sacgpt:user");
    if (user) {
      const parsedUser = JSON.parse(user);

      if (moment(parsedUser.expiresAt).isBefore(new Date())) {
        signOut();
      } else {
        api.defaults.headers.Authorization = `Bearer ${parsedUser.authToken}`;
        setAuthData(parsedUser);
      }
    } else {
      signOut();
    }
  };

  useEffect(() => {
    signOutIfTokenExpired();
  }, []);

  useEffect(() => {
    const checkWhatsConeccted = async () => {
      try {
        const { data } = await api.get("/partners/partners/wpp_connected", {
          headers: {
            "Cache-Control": "no-cache",
          },
        });
        if (!data.wppConnected && authData?.partnerDetailId) {
          const { data } = await api.get(`partners/partners/${authData?.partnerDetailId}`);
          await updateUser(data);
        }
      } catch (error) {
        console.error("Erro ao verificar conexão do WhatsApp:", error);
      }
    };
  
    checkWhatsConeccted();
  }, [authData?.partnerDetailId]);

  const signOut = async () => {
    //   await AsyncStorage.multiRemove(['@Allmidz: user']);

    api.defaults.headers.Authorization = null;

    setAuthData("");
    localStorage.setItem("@sacgpt:token", "");
    localStorage.setItem("@sacgpt:user", "");
    localStorage.removeItem("@sacgpt:checkWizard");
  };

  if (loading) {
    return <h1>Loading ...</h1>;
  }

  return (
    <AuthContext.Provider
      value={{
        user: authData,
        loading,
        updateUser,
        updatePartnerDetail,
        updateAffiliated,
        signIn,
        signOut,
        signInAdmin,
        signInSales,
        signUp,
        signUpSales,
        checkList,
        setCheckList,
        checkComplete,
        checkLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
