import React from "react";
import PropTypes from 'prop-types';
import FormInput from "../FormInput";
import { Text } from '@geist-ui/core';

/**
 * SelectWithLabel - Componente legado que agora usa o novo FormInput
 *
 * Este componente mantém a mesma API para compatibilidade com código existente,
 * mas internamente usa o novo componente FormInput.
 *
 * @deprecated Use o componente FormInput diretamente
 */
export const SelectWithLabel = ({
  label,
  value,
  form,
  disabled = false,
  readOnly = false,
  validate = false,
  helperText = "",
  variant = "standard",
  multiple = false,
  renderValue = null,
  style,
  onChange,
  children,
  ...props
}) => (
  <div style={{ width: '100%' }}>
    <Text b style={{ color: "#539d65" }}>{label}</Text>
    {helperText && (
      <Text small style={{ color: "#566986", marginBottom: "5px" }}>
        {helperText}
      </Text>
    )}
    <FormInput
      label=""
      fieldName={value}
      form={form}
      disabled={disabled}
      readOnly={readOnly}
      validate={validate}
      width="100%"
      select
      SelectProps={{
        multiple: multiple,
        renderValue: renderValue,
        style: style,
        onChange: onChange
          ? onChange
          : undefined
      }}
      style={{ marginTop: '5px' }}
      {...props}
    >
      {children}
    </FormInput>
  </div>
);

SelectWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  validate: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
  style: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

// Aviso de depreciação no console
console.warn(
  'O componente SelectWithLabel está depreciado. Use o componente FormInput diretamente.'
);
