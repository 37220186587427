import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import api from "../../../utils/api";

export const useFinancePartner = () => {
  const [loading, setLoading] = useState(true);
  const [loadingCards, setLoadingCards] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [cards, setCards] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [metrics, setMetrics] = useState({});

  useEffect(() => {
    getCards();
    getTransactions();
    getSubscription();
  }, []);

  const getTransactions = async () => {
    setLoading(true);
    setLoadingTable(true);
    try {
      const { data } = await api.get(`partners/payment_transactions?page=1`);
      if (data) {
        setTransactions(
          data.map((item) => ({
            id: item.galaxPayId,
            description: item?.Subscription
              ? "Pagamento de Assinatura"
              : item?.Charge
              ? "Compra de Tokens"
              : "Não Informado",
            date: moment(item.createdAt).format("DD/MM/YYYY HH:mm"),
            type: "Saída",
            amount: item.value / 100,
            tStatus: handleStatus(item.status),
          }))
        );
      }
    } catch (error) {
      toast.error("Não foi possível carregar os dados das transações.");
    } finally {
      setLoadingTable(false);
      setLoading(false);
    }
  };

  const handleStatus = (status) => {
    const statusMap = {
      notSend: "Ainda não enviada para operadora de Cartão",
      authorized: "Autorizado",
      captured: "Capturada na Operadora de Cartão",
      denied: "Negada na Operadora de Cartão",
      reversed: "Estornada na Operadora de Cartão",
      cancel: "Cancelada manualmente",
      payExternal: "Paga fora do sistema",
      cancelByContract: "Cancelada ao cancelar a cobrança",
      free: "Gratuito",
    };
    return statusMap[status] || "Outro";
  };

  const getCards = async () => {
    setLoadingCards(true);
    try {
      const { data: cards } = await api.get(`/partners/credit_cards`);
      if (cards) {
        setCards(
          cards.data.map((item, index) => ({
            id: item.id,
            position: index,
            ...item.attributes,
          }))
        );
      }
    } catch (error) {
      toast.error("Não foi possível carregar os dados dos cartões.");
    } finally {
      setLoadingCards(false);
    }
  };

  const getSubscription = async () => {
    setLoadingSubscription(true);
    try {
      const [subscriptionData, plansData, cardsData] = await Promise.all([
        api.get(`partners/payment_subscriptions/last_active_subscription`),
        api.get(`partners/payment_plans`),
        api.get(`partners/credit_cards`),
      ]);

      if (subscriptionData?.data?.data !== null && plansData.data && cardsData.data) {
        const sub = plansData.data.data.find(
          (item) => Number(item.id) === Number(subscriptionData.data.data?.attributes?.paymentPlan.id)
        );

        const lastCard = cardsData.data.data[cardsData.data.data.length - 1]?.attributes;
        const subCardLastDigits = lastCard?.number.substr(-4);
        const maskedCard = subCardLastDigits ? `xxxx xxxx xxxx ${subCardLastDigits}` : "";

        const payDate = moment().diff(
          moment(subscriptionData.data.data?.attributes?.firstPayDayDate),
          "months"
        );

        if (subscriptionData.data.data) {
          const { data } = await api.get("partners/montly_usage_history");
          setMetrics(data?.data?.usageStatistics);
        }

        setSubscription({
          name: sub?.attributes?.name,
          periodicity: sub?.attributes?.periodicity,
          planPricePayment: sub?.attributes?.planPricePayment,
          planPriceValue: sub?.attributes?.planPriceValue,
          payValidate: moment(subscriptionData.data.data?.attributes?.firstPayDayDate).add(payDate + 1, "months"),
          payDate: moment(subscriptionData.data.data?.attributes?.firstPayDayDate),
          card: maskedCard,
          brand: lastCard?.brand,
          expiresAt: lastCard?.expiresAt,
        });
      }
    } catch (error) {
      toast.error("Não foi possível carregar os dados da assinatura.");
    } finally {
      setLoadingSubscription(false);
    }
  };

  const isCardExpired = (expiryDate) => {
    if (expiryDate) {
      const [year, month] = expiryDate?.split("-").map(Number);
      const expiry = new Date(year, month);
      const now = new Date();
      return expiry < now;
    }
    return "";
  };

  return {
    loading: loading || loadingCards || loadingTable || loadingSubscription,
    loadingTable,
    transactions,
    cards,
    subscription,
    metrics,
    isCardExpired,
  };
};