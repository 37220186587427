import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import Button from "../../../components/Button";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import cardBrand from "../../../utils/cardBrand";

import "./NewCreditCard.css";
import { toast } from "react-toastify";
import api from "../../../utils/api";
import unmask from "../../../utils/unmask";
import MainCard from "../../../components/MainCard";
import Card from "../../../components/Card";
import FormInput from "../../../components/FormInput";
import { Text } from "@geist-ui/core";

const NewCreditCard = () => {
  const navigate = useNavigate();
  const [saveCard, setSaveCard] = useState(false);

  const NewCreditCardForm = useFormik({
    initialValues: {
      cardFullName: "",
      cardNumber: "",
      cardExpirationDate: "",
      cardCVV: "",
    },
    validationSchema: yup.object({
      cardFullName: yup
        .string()
        .required("Nome no cartão é obrigatório")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      cardNumber: yup
        .string()
        .required("Número do cartão é obrigatório")
        .matches(
          /^[0-9\s]{19}$/,
          "Número do cartão deve estar no formato 9999 9999 9999 9999"
        ),
      cardExpirationDate: yup
        .string()
        .required("Data de expiração é obrigatória")
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Data de expiração deve estar no formato MM/AA"
        )
        .test("expDate", "Data de expiração inválida", (value) => {
          if (!value) return false;
          const today = new Date();
          const [month, year] = value.split("/").map(Number);
          const expDate = new Date(`20${year}`, month - 1);
          return expDate >= today;
        }),
      cardCVV: yup
        .string()
        .required("CVV é obrigatório")
        .matches(/^[0-9]{3,4}$/, "CVV deve ter 3 dígitos"),
    }),
    onSubmit: () => registerCard(),
  });

  const registerCard = async () => {
    setSaveCard(true);
    try {
      const { data } = await api.post("/partners/credit_cards", {
        data: {
          attributes: {
            cardNumber: NewCreditCardForm.values.cardNumber,
            cardHolderName: NewCreditCardForm.values.cardFullName,
            expiresAt:
              "20" +
              unmask(NewCreditCardForm.values.cardExpirationDate).slice(2, 4) +
              "-" +
              unmask(NewCreditCardForm.values.cardExpirationDate).slice(0, 2),
            cardCvv: NewCreditCardForm.values.cardCVV,
            brand: cardBrand(NewCreditCardForm.values.cardNumber),
          },
        },
      });

      if (data) {
        navigate(-1);
        return toast.success("Cartão alterado com sucesso.");
      }
    } catch (error) {
      if (error.response.data.errors[0].title) {
        toast.error(error.response.data.errors[0].title);
      }
      return toast.error("Ocorreu um erro ao tentar cadastrar o cartão.");
    } finally {
      setSaveCard(false);
    }
  };

  return (
    <AuthorizedLayout>
      <MainCard variant="minimal" flex>
        <Card>
          <Text h3>ALTERAR OPÇÃO DE PAGAMENTO</Text>
          <form
            onSubmit={NewCreditCardForm.handleSubmit}
            style={{ marginTop: "20px" }}
          >
            <FormInput
              label="Nome do Titular do Cartão"
              name="cardFullName"
              value={NewCreditCardForm.values.cardFullName}
              onChange={NewCreditCardForm.handleChange}
              onBlur={NewCreditCardForm.handleBlur}
              error={
                NewCreditCardForm.touched.cardFullName &&
                NewCreditCardForm.errors.cardFullName
                  ? NewCreditCardForm.errors.cardFullName
                  : null
              }
              validate={
                NewCreditCardForm.touched.cardFullName &&
                NewCreditCardForm.errors.cardFullName
              }
              placeholder="Digite o nome do Titular do Cartão"
              width="100%"
            />

            <FormInput
              label="Número do Cartão"
              name="cardNumber"
              value={NewCreditCardForm.values.cardNumber}
              onChange={NewCreditCardForm.handleChange}
              onBlur={NewCreditCardForm.handleBlur}
              error={
                NewCreditCardForm.touched.cardNumber &&
                NewCreditCardForm.errors.cardNumber
                  ? NewCreditCardForm.errors.cardNumber
                  : null
              }
              validate={
                NewCreditCardForm.touched.cardNumber &&
                NewCreditCardForm.errors.cardNumber
              }
              mask={"**** **** **** 9999"}
              placeholder="1234 1234 1234 1234"
              width="100%"
            />

            <FormInput
              label="Validade"
              name="cardExpirationDate"
              value={NewCreditCardForm.values.cardExpirationDate}
              onChange={NewCreditCardForm.handleChange}
              onBlur={NewCreditCardForm.handleBlur}
              error={
                NewCreditCardForm.touched.cardExpirationDate &&
                NewCreditCardForm.errors.cardExpirationDate
                  ? NewCreditCardForm.errors.cardExpirationDate
                  : null
              }
              validate={
                NewCreditCardForm.touched.cardExpirationDate &&
                NewCreditCardForm.errors.cardExpirationDate
              }
              mask={"99/99"}
              placeholder="10/32"
              width="100%"
            />

            <FormInput
              label="Código de Segurança"
              name="cardCVV"
              value={NewCreditCardForm.values.cardCVV}
              onChange={NewCreditCardForm.handleChange}
              onBlur={NewCreditCardForm.handleBlur}
              error={
                NewCreditCardForm.touched.cardCVV &&
                NewCreditCardForm.errors.cardCVV
                  ? NewCreditCardForm.errors.cardCVV
                  : null
              }
              validate={
                NewCreditCardForm.touched.cardCVV &&
                NewCreditCardForm.errors.cardCVV
              }
              mask={"999"}
              placeholder="999"
              width="100%"
            />

            <div className="buttonContainer">
              <Button variant="outline" onClick={() => navigate(-1)}>
                Voltar
              </Button>
              <Button variant="filled" onClick={NewCreditCardForm.handleSubmit}>
                {saveCard ? "Carregando..." : <>Salvar</>}
              </Button>
            </div>
          </form>
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default NewCreditCard;
