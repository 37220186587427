import React from "react";
import PropTypes from "prop-types";
import { Select, Text, Tooltip, Spacer, useTheme } from "@geist-ui/core";
import { Info } from "@geist-ui/icons";
import "./FormSelect.css";

/**
 * FormSelect - Componente unificado para selects com label
 *
 * Este componente segue o mesmo padrão do FormInput, oferecendo
 * funcionalidades de select com suporte a acessibilidade e validação,
 * seguindo os padrões da biblioteca Geist UI.
 */
export const FormSelect = ({
  // Propriedades básicas
  id,
  name,
  label,
  value,
  onChange,
  onBlur,

  // Propriedades de estilo e comportamento
  width = "100%",
  disabled = false,
  required = false,

  // Propriedades de validação
  error = null,
  validate = false,

  // Propriedades de ajuda e informação
  helperText = "",
  infoTooltip = "",

  // Propriedades específicas do select
  options = [],
  placeholder = "",
  multiple = false,

  // Integração com Formik (opcional)
  form = null,
  fieldName = "",

  // Propriedades de estilo
  className = "",
  style = {borderRadius :'0px', border: 'none', borderLeft :'1px solid #ddd'},

  // Outras propriedades
  ...props
}) => {
  const theme = useTheme();

  // Determinar se estamos usando Formik ou não
  const isFormik = form !== null && fieldName !== "";

  // Valores e handlers para Formik ou controlados diretamente
  const selectValue = isFormik ? form.values[fieldName] : value;
  const hasError = isFormik
    ? validate && form.touched[fieldName] && form.errors[fieldName]
    : validate && error;
  const errorMessage = isFormik ? form.errors[fieldName] : error;

  // Status do select para Geist UI
  const status = hasError ? "error" : "default";

  // Handlers para onChange e onBlur
  const handleChange = (val) => {
    if (isFormik) {
      form.setFieldValue(fieldName, val);
    } else if (onChange) {
      onChange(val);
    }
  };

  const handleBlur = (e) => {
    if (isFormik) {
      form.setFieldTouched(fieldName, true);
    } else if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className="form-select-container">
      {/* Label e tooltip de informação (se houver) */}
      {infoTooltip && (
        <div className="form-select-label-container">
          <Tooltip text={infoTooltip} placement="topStart">
            <div className="form-select-info-icon">
              <Info size={16} />
            </div>
          </Tooltip>
        </div>
      )}

      {/* Renderiza o select */}
      <div className="form-select-wrapper">
        <label className="form-select-label">{label}</label>
        <Select
          id={id || name || fieldName}
          width={width}
          value={selectValue}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          multiple={multiple}
          status={status}
          className={`form-select ${className}`}
          style={{ ...style }}
          aria-describedby={
            helperText ? `${id || name || fieldName}-helper-text` : undefined
          }
          {...props}
        >
          {options.map((option) => (
            <Select.Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* Mensagem de erro de validação */}
      {validate && hasError && (
        <Text
          type="error"
          small
          id={`${id || name || fieldName}-error-text`}
          className="form-select-error"
        >
          {errorMessage}
        </Text>
      )}

      {/* Helper text (quando não há erro) */}
      {helperText && !hasError && (
        <Text
          small
          type="secondary"
          id={`${id || name || fieldName}-helper-text`}
          className="form-select-helper"
        >
          {helperText}
        </Text>
      )}

      <Spacer h={0.5} />
    </div>
  );
};

FormSelect.propTypes = {
  // Propriedades básicas
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  // Propriedades de estilo e comportamento
  width: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,

  // Propriedades de validação
  error: PropTypes.string,
  validate: PropTypes.bool,

  // Propriedades de ajuda e informação
  helperText: PropTypes.string,
  infoTooltip: PropTypes.string,

  // Propriedades específicas do select
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      disabled: PropTypes.bool,
    })
  ),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,

  // Integração com Formik (opcional)
  form: PropTypes.object,
  fieldName: PropTypes.string,

  // Propriedades de estilo
  className: PropTypes.string,
  style: PropTypes.object,
};

export default FormSelect;
