import React from "react";
import "./DoNotDisturb.css";

const WeekDayButton = ({ day, label, selected, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`weekday-button ${selected ? "selected" : ""}`}
  >
    {label}
  </button>
);

const DoNotDisturb = ({
  enabled,
  onEnabledChange,
  startTime,
  onStartTimeChange,
  endTime,
  onEndTimeChange,
  selectedDays,
  onDaySelect,
  title = "Modo Não Perturbe",
  description = "Defina horários em que as mensagens não devem ser enviadas",
  weekDays = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ],
}) => {
  const handleDaySelect = (index) => {
    const dayStr = index.toString();
    const currentDays = selectedDays || [];

    if (currentDays.includes(dayStr)) {
      onDaySelect(currentDays.filter((day) => day !== dayStr));
    } else {
      onDaySelect([...currentDays, dayStr]);
    }
  };

  return (
    <div className="do-not-disturb-section">
      <div className="dnd-header">
        <div>
          <h3 className="dnd-title">{title}</h3>
          <p className="dnd-description">{description}</p>
        </div>
        <label className="switch">
          <input
            type="checkbox"
            checked={enabled}
            onChange={(e) => onEnabledChange(e.target.checked)}
          />
          <span className="slider"></span>
        </label>
      </div>

      {enabled && (
        <div className="dnd-settings">
          <div className="time-inputs">
            <div className="input-group">
              <label>Não enviar após</label>
              <input
                type="time"
                className="input"
                value={startTime}
                onChange={(e) => onStartTimeChange(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Retomar envios às</label>
              <input
                type="time"
                className="input"
                value={endTime}
                onChange={(e) => onEndTimeChange(e.target.value)}
              />
            </div>
          </div>

          <div className="weekdays-section">
            <label>Selecione os dias em que NÃO serão enviadas mensagens</label>
            <div className="weekdays-grid">
              {weekDays.map((dia, index) => (
                <WeekDayButton
                  key={index}
                  day={index.toString()}
                  label={dia}
                  selected={selectedDays?.includes(index.toString())}
                  onClick={() => handleDaySelect(index)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoNotDisturb;
