import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import parsePhoneAdmin from "../../../utils/parsePhone";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Box, FormControl, Modal, NativeSelect, Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import Papa from "papaparse";

import iconUser from "../../../assets/noAvatar.svg";
import iconArronw from "../../../assets/Vector.png";
import iconLUpa from "../../../assets/MagnifyingGlass.png";
import iconFilter from "../../../assets/Funnel.png";
import iconExport from "../../../assets/Export.png";

import "./PartnerList.css";
import unmask from "../../../utils/unmask";
import trimString from "../../../utils/trimString";
import { FileUpload, FileUploadOutlined } from "@mui/icons-material";
import MainCard from "../../../components/MainCard";
import { Card, Grid, Loading } from "@geist-ui/core";
import GeistTableFilter from "../../../components/GeistTableFilter/GeistTableFilter";
import GeistTable, {
  TableUser,
} from "../../../components/GeistTable/GeistTable";
import Button from "../../../components/Button";
import GeistPagination from "../../../components/GeistPagination/GeistPagination";

const animatedComponents = makeAnimated();

const topArray = [
  { label: "ID", value: "id" },
  { label: "Nome", value: "partnerName" },
  { label: "Nome do Negócio", value: "companyName" },
  { label: "Email", value: "email" },
  { label: "Telefone", value: "phoneNumber" },
  { label: "Assinatura", value: "subscription" },
  { label: "Data de Cadastro", value: "createdAt" },
  { label: "Uso de Tokens", value: "tokenUsage" },
  { label: "Whatsapp", value: "wppConnected" },
  { label: "Google", value: "connectedWithGoogle" },
];

const PartnerList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState({});

  const getPartners = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("admins/partners/");

      if (data) {
        // console.log(data);
        setPartners(
          data.data.map((item) => {
            return {
              id: Number(item.id),
              partnerName: item.attributes?.name || "Nome não informado",
              sNumber: item.attributes?.serviceNumber
                ? unmask(item.attributes?.serviceNumber)
                : "Número não informado",
              phoneNumber:
                item.attributes?.contactNumber || "Número não informado",
              subscription: handleSubscription(
                item.attributes?.active,
                item.attributes?.currentPlan
              ),
              tokenUsage: `${item.attributes?.montlyTokensConsumed || 0} / ${
                item.attributes?.montlyTokensConsumed +
                item.attributes?.monthlyTokensLeft
              }`,
              ...item.attributes,
            };
          })
          // .sort((a, b) => {
          //   return a.id - b.id;
          // })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar a lista de parceiros.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubscription = (active, plan) => {
    if (!active || !plan) {
      return "Plano Gratuito";
    } else {
      return plan?.name;
    }
  };

  useEffect(() => {
    getPartners();
  }, []);

  const handleClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = partners.filter((item) =>
    item.partnerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const selectedsNamesFilter = filterKeys.map((item) => item.value);

  const generateCSV = (data) => {
    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      "Nome do Parceiro": item?.partnerName || "Nome não informado",
      "Nome da Empresa": item?.name || "Nome não informado",
      Email: item?.email || "N/A",
      "Telefone de Contato":
        parsePhoneAdmin(item?.phoneNumber) || "Número não informado",
      "Telefone Vinculado":
        parsePhoneAdmin(item?.sNumber) || "Número não informado",
      Assinatura: item?.subscription || "N/A",
      Documento: item?.document || "N/A",
      Google: item?.connectedWithGoogle ? "Vinculado" : "Não Vinculado",
      WhatsApp: item?.wppConnected ? "Vinculado" : "Não Vinculado",
      Status: item?.active ? "Ativa" : "Inativa",
      "Data de Cadastro": item?.createdAt
        ? moment(item?.createdAt).format("DD/MM/YYYY")
        : "N/A",
      "Uso de Tokens": `${item?.montlyTokensConsumed || 0} / ${
        item?.montlyTokensConsumed + item?.monthlyTokensLeft
      }`,
    }));

    const csv = Papa.unparse(formattedData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `SacGPT Parceiros ${moment().format("DD-MM-YYYY")}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function formatCPF(cpf) {
    console.log(cpf);
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return cnpj;
  }

  const truncateEmail = (email, maxLength) => {
    if (email.length <= maxLength) return email;

    const start = email.slice(0, 5); // Exibir os primeiros 5 caracteres
    const end = email.slice(-10); // Exibir os últimos 10 caracteres

    return `${start}...${end}`;
  };

  function extrairNomeAtePrimeiroEspaco(nomeCompleto) {
    const primeiroEspacoIndex = nomeCompleto.indexOf(" ");

    if (primeiroEspacoIndex === -1) {
      return nomeCompleto;
    }

    const nomeAtePrimeiroEspaco = nomeCompleto.substring(
      0,
      primeiroEspacoIndex
    );

    return nomeAtePrimeiroEspaco;
  }

  // const cardsContainerRef = useRef(null);
  // const [isMouseDown, setIsMouseDown] = useState(false);
  // const [startX, setStartX] = useState(0);
  // const [scrollLeft, setScrollLeft] = useState(0);

  // const handleMouseDown = (e) => {
  //   setIsMouseDown(true);
  //   setStartX(e.pageX - cardsContainerRef.current.offsetLeft);
  //   setScrollLeft(cardsContainerRef.current.scrollLeft);
  //   cardsContainerRef.current.style.cursor = "grabbing";
  // };

  // const handleMouseLeave = () => {
  //   setIsMouseDown(false);
  //   cardsContainerRef.current.style.cursor = "grab";
  // };

  // const handleMouseUp = () => {
  //   setIsMouseDown(false);
  //   cardsContainerRef.current.style.cursor = "grab";
  // };

  // const handleMouseMove = (e) => {
  //   if (!isMouseDown) return;
  //   e.preventDefault();
  //   const x = e.pageX - cardsContainerRef.current.offsetLeft;
  //   const walk = (x - startX) * 2; // Ajuste a velocidade do scroll aqui
  //   cardsContainerRef.current.scrollLeft = scrollLeft - walk;
  // };

  return (
    <AuthorizedLayout>
      <MainCard>
        <Card title="Lista de Clientes">
          <Grid.Container gap={0}>
            <Grid xs={24} style={{ marginBottom: "var(--spacing-lg)" }}>
              <GeistTableFilter
                searchTerm={searchTerm}
                onSearchChange={(value) => setSearchTerm(value)}
                onSearchClear={() => {
                  setSearchTerm("");
                  setTypeFilter("");
                }}
                filterKeys={filterKeys}
                selectedFilters={filterKeys.map((k) => k.value)}
                onFilterChange={(values) => {
                  setFilterKeys(
                    filterKeys.filter((item) => values.includes(item.value))
                  );
                }}
                onExport={generateCSV}
                exportData={currentItems}
              />
            </Grid>

            <Grid
              xs={24}
              style={{ marginTop: 0, width: "100%", display: "flex" }}
            >
              {loading ? (
                <div className="loading-container">
                  <Loading>Carregando parceiros...</Loading>
                </div>
              ) : currentItems.length === 0 ? (
                <div className="empty-state">Nenhum cliente encontrado</div>
              ) : (
                <GeistTable
                  columns={[
                    {
                      field: "id",
                      label: "ID",
                      sortable: true,
                      render: (value) => value,
                    },
                    {
                      field: "name",
                      label: "Nome / E-MAIl",
                      sortable: true,
                      render: (value, rowData) => (
                        <button
                          onClick={() => {
                            setSelectedPartner(rowData);
                            setModal(true);
                          }}
                        className="hover-button"
                        >
                          <TableUser
                            name={rowData.name}
                            email={rowData.email}
                            avatar={rowData.avatar || iconUser}
                          />
                        </button>
                      ),
                    },
                    {
                      field: "phoneNumber",
                      label: "Telefone",
                      sortable: true,
                      render: (value) => parsePhoneAdmin(value),
                    },
                    {
                      field: "partnerName",
                      label: "Nome do Negócio",
                      sortable: true,
                      render: (value) => <div>{trimString(value, 20)}</div>,
                    },

                    {
                      field: "subscription",
                      label: "Assinatura",
                      sortable: true,
                      render: (value) => value,
                    },

                    {
                      field: "createdAt",
                      label: "Data de Cadastro",
                      sortable: true,
                      render: (value) => moment(value).format("DD/MM/YYYY"),
                    },
                    {
                      field: "tokenUsage",
                      label: "Uso de Tokens",
                      sortable: true,
                      render: (value) => value,
                    },
                    {
                      field: "wppConnected",
                      label: "Whatsapp",
                      sortable: true,
                      render: (value) =>
                        `${value?.wppConnected ? "Conectado" : "Desconectado"}`,
                    },
                    {
                      field: "connectedWithGoogle",
                      label: "Google",
                      sortable: true,
                      render: (value) =>
                        `${
                          value?.connectedWithGoogle
                            ? "Conectado"
                            : "Desconectado"
                        }`,
                    },

                    {
                      field: "actions",
                      label: "Ações",
                      render: (value, rowData) => (
                        <Button
                          // icon={<MessageSquare />}
                          variant="outline"
                          onClick={() =>
                            navigate(`/admin/parceiros/${rowData?.id}`)
                          }
                        >
                          Ver cliente
                        </Button>
                      ),
                    },
                  ]}
                  data={currentItems}
                  loading={loading}
                  emptyText="Nenhum cliente encontrado"
                  className="geist-table"
                />
              )}
            </Grid>

            <Grid xs={24}>
              <GeistPagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </Grid>
          </Grid.Container>
        </Card>
      </MainCard>

      {modal && (
        <Modal
          open
          onClose={() => {
            setModal(false);
            setSelectedPartner({});
          }}
          disableAutoFocus
        >
          <Box
            style={{
              background: "#f3f5f8",
              borderRadius: "20px",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",

              minHeight: "10vh",
              maxHeight: "50vh",
              p: 2.5,
            }}
          >
            <div className="containerProfile">
              <div className="headerProfile" style={{ maxWidth: "350px" }}>
                <div className="cardProfile">
                  <div className="containerImg">
                    <img
                      src={selectedPartner?.avatar || iconUser}
                      alt="usuario"
                      className="iconUser"
                    />
                  </div>
                  <div className="title">{selectedPartner?.name}</div>
                  <Tooltip title={selectedPartner?.email}>
                    <div className="containerText">
                      <strong>E-mail:</strong>
                      <span>{selectedPartner?.email}</span>
                    </div>
                  </Tooltip>
                  <div>
                    <strong>Telefone:</strong>{" "}
                    {`${
                      selectedPartner?.contactNumber
                        ? parsePhoneAdmin(selectedPartner?.contactNumber)
                        : "N/A"
                    }`}
                  </div>
                  <div>
                    <strong>CPF/CNPJ:</strong>{" "}
                    {selectedPartner?.document === null
                      ? "N/A"
                      : selectedPartner?.document?.length === 11
                      ? formatCPF(selectedPartner?.document)
                      : formatCNPJ(selectedPartner?.document)}
                  </div>
                  <div>
                    <strong>Data de Cadastro:</strong>{" "}
                    {moment(selectedPartner?.createdAt).format("DD/MM/YYYY")}
                  </div>
                </div>

                <div className="cardProfile">
                  <div>
                    <strong>Nome do Negócio:</strong>{" "}
                    {selectedPartner?.companyName || "N/A"}
                  </div>
                  <div>
                    <strong>Assinatura:</strong>{" "}
                    {selectedPartner?.subscription ||
                      "Nenhum plano selecionado"}
                  </div>
                  <div>
                    <strong>Tokens disponíveis:</strong>{" "}
                    {Number(
                      selectedPartner?.remainingTokens || 0
                    )?.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </AuthorizedLayout>
  );
};

export default PartnerList;
