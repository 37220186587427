import React, { useState, useContext, useEffect } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import LeadsGraph from "./components/LeadsGraph";
import LeadsCard from "./components/LeadsCard";
import CheckList from "./components/CheckScreens";
import { Loader } from "../../../components/Loader";
import MonthSelector from "./components/MonthSelector";
import api from "../../../utils/api";
import Card from "../../../components/Card";

import iconMensage from "../../../assets/Vector (5).png";
import iconUsers from "../../../assets/Vector (4).png";
import iconWarning from "../../../assets/Warning.png";
import arrow from "../../../assets/Vector.png";
import { CheckProvider } from "./components/CheckScreens/CheckProvider";

import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, checkList, checkLoading } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState({});
  const [clientScores, setClientScores] = useState([]);
  const [loadingClientScores, setLoadingClientScores] = useState(false);
  const { pathname } = useLocation();

  const currentMonth = parseInt(moment().format("M"));
  const currentYear = moment().year();

  // Month and year selectors for each section
  const [tokensDate, setTokensDate] = useState({ month: currentMonth, year: currentYear });
  const [leadsDate, setLeadsDate] = useState({ month: currentMonth, year: currentYear });
  const [messagesDate, setMessagesDate] = useState({ month: currentMonth, year: currentYear });
  const [graphYear, setGraphYear] = useState(currentYear);
  const [leadsListDate, setLeadsListDate] = useState({ month: currentMonth, year: currentYear });

  const getFirstName = (fullName) => {
    if (!fullName) return "";
    return fullName.split(" ")[0];
  };

  const getMetrics = async (month, year) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/partners/partner_reports`, {
        params: { month, year }
      });

      if (data) {
        setReports(data.data.reports);
      }
    } catch (error) {
      toast.error("Não foi possível carregar os dados.");
    } finally {
      setLoading(false);
    }
  };

  const fetchClientScores = async (month, year) => {
    setLoadingClientScores(true);
    try {
      const { data } = await api.get(`/partners/partner_clients`, {
        params: { month, year }
      });

      const newData = data.data.map((value) => ({
        client: {
          data: {
            id: value.id,
            type: value.type,
            attributes: { ...value.attributes },
          },
        },
      }));

      setClientScores(newData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClientScores(false);
    }
  };

  useEffect(() => {
    getMetrics(tokensDate.month, tokensDate.year);
  }, [tokensDate]);

  useEffect(() => {
    fetchClientScores(leadsListDate.month, leadsListDate.year);
  }, [leadsListDate]);


  console.log(reports);
  

  return (
    <>
      {(checkList.partnerDetailsFilled && checkList.wizard) &&
      pathname !== "/configuracoes/automatica" ? (
        loading && checkLoading ? (
          <AuthorizedLayout>
            <Loader size={150} />
          </AuthorizedLayout>
        ) : (
          <AuthorizedLayout>
            <div className="containerDashboard">
              <div className="containerLeft">
                <Card title={`Olá novamente, ${getFirstName(user.name)}!  👋`}>
                  <span className="text">
                    Aqui está um resumo da sua assistente virtual:
                  </span>
                </Card>

                {!user?.connectedWithGoogle && (
                  <Card>
                    <div className="containerAlertGoogle">
                      <div className="containerText">
                        <img src={iconWarning} alt="mensagens" />
                        <span className="text">
                          <strong>Atenção:</strong> O Calendário Google não está
                          vinculado.
                        </span>
                      </div>
                      <div
                        className="button"
                        onClick={() => navigate("/vincular")}
                      >
                        Vincular
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </Card>
                )}

                <Card title="Tokens do plano atual">
                  <div className="containerTokens">
                    <div className="containerHeaderTokens">
                      <div className="tokens-count">
                        <strong>
                          {(reports?.usageStatistics?.montlyTokensConsumed || 0).toLocaleString()}
                        </strong>
                        {" / "}
                        {((reports?.usageStatistics?.montlyTokensConsumed || 0) + (reports?.usageStatistics?.monthlyTokensLeft || 0)).toLocaleString()}
                      </div>
                      <div className="selector-container">
                        {/* <MonthSelector
                          selectedMonth={tokensDate.month}
                          selectedYear={tokensDate.year}
                          onChange={(month, year) => setTokensDate({ month, year })}
                        /> */}
                      </div>
                    </div>
                    <div className="bar">
                      <div className="fill">
                        <span
                          className="leads"
                          style={{
                            width: `${(((reports?.usageStatistics?.montlyTokensConsumed || 0) / ((reports?.usageStatistics?.montlyTokensConsumed || 0) + (reports?.usageStatistics?.monthlyTokensLeft || 0))) * 100).toFixed(2)}%`,
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Card>

                <Card title="Leads e Mensagens">
                  <div className="containerInfosLeadsSends">
                    <div className="containerInfo">
                      {/* <MonthSelector
                        selectedMonth={leadsDate.month}
                        selectedYear={leadsDate.year}
                        onChange={(month, year) => setLeadsDate({ month, year })}
                      /> */}
                      <div className="content">
                        <div className="textImg">
                          <img src={iconUsers} alt="users" />
                          {reports?.overview?.leadCount || 0}
                        </div>
                        <div>Total de Leads gerados pelo atendente virtual</div>
                      </div>
                    </div>
                    <div className="containerInfo">
                      {/* <MonthSelector
                        selectedMonth={messagesDate.month}
                        selectedYear={messagesDate.year}
                        onChange={(month, year) => setMessagesDate({ month, year })}
                      /> */}
                      <div className="content">
                        <div className="textImg">
                          <img src={iconMensage} alt="mensagens" />
                          {reports?.attendantPerformance?.answersCount || 0}
                        </div>
                        <div>Mensagens enviadas pelo seu atendente</div>
                      </div>
                    </div>
                  </div>
                </Card>

                <Card title="Gráfico de Leads">
                  <LeadsGraph
                    selectedYear={graphYear}
                    onYearChange={(year) => setGraphYear(year)}
                    monthLeads={reports?.overview?.monthLeads}
                  />
                </Card>
              </div>

              <div className="line"></div>

              <Card title="Leads mais recentes">
                <div className="containerLeadsRecentes">
                  <LeadsCard reports={reports} /> 
                </div>
              </Card>
            </div>
          </AuthorizedLayout>
        )
      ) : (
        <AuthorizedLayout disableHUD>
          <CheckProvider>
            <CheckList />
          </CheckProvider>
        </AuthorizedLayout>
      )}
    </>
  );
};

export default Dashboard;
