import React, { useContext, useEffect, useState } from "react";
import NewPlanContext from "../Context/NewPlanContext";
import { AuthContext } from "../../../../providers/AuthProvider";
import { toast } from "react-toastify";
import { Check } from "@mui/icons-material";
import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";
import api from "../../../../utils/api";

import iconFran from "../../../../assets/Vector (9).png";

import "./Step1.css";
import { useNavigate } from "react-router-dom";

const planTrial = {
  title: "Plano Gratuito",
  type: "paymentPlan",
  amount: 0,
  id: "7",
};

const PlanDescription = ({ description }) => {
  const splitDescription = (desc) => {
    const parts = desc.split(",");
    const beforeComma = parts[0] || "";
    const afterComma = parts.slice(1).join(",") || "";
    return { beforeComma, afterComma };
  };

  const { beforeComma, afterComma } = splitDescription(description);

  return (
    <div className="subtitle">
      {beforeComma}
      {", "}
      <strong>{afterComma}</strong>
    </div>
  );
};

const Step1 = () => {
  const navigate = useNavigate();

  const { step1Form } = useContext(NewPlanContext);
  const { checkList } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`partners/payment_plans`);
      if (data) {
        setPlans(
          data.data
            .filter((item) => !item.attributes.disable)
            .filter((item) => item.attributes.name !== "Plano Gratuito")
            .map((item) => {
              return {
                title: item.attributes.name,
                description: item.attributes.additionalInfo,
                amount: Number(item.attributes.planPriceValue) / 100,
                benefits:
                  item.attributes.name === "Plano Gratuito"
                    ? [
                        "Atendente virtual personalizável",
                        "Painel administrativo",
                        "Históricos de conversa com clientes",
                      ]
                    : [
                        "Atendente virtual personalizável",
                        "Painel administrativo",
                        "Históricos de conversa com clientes",
                        "Resumo inteligente de conversas",
                        "Classificação de interesse do lead",
                        "Agendamento automático de reuniões",
                      ],
                ...item,
              };
            })
        );
        //console.log(data.data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados do parceiro.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = async (value) => {
    if (!checkList.profileFilled) {
      navigate("/conta");
      toast.error("Atenção: primeiro preencha os dados do seu perfil!");
    } else {
      await step1Form.setFieldValue("title", value.title);
      await step1Form.setFieldValue("amount", value.amount);
      await step1Form.setFieldValue("id", value.id);
      await step1Form.setFieldValue("type", value.type);

      step1Form.submitForm();
    }
  };

  const sortedPlans = plans.sort(
    (a, b) =>
      Number(a?.attributes?.maxTokenCount) -
      Number(b?.attributes?.maxTokenCount)
  );

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="containerCardPlan">
          {sortedPlans.map((plan, index) => (
            <div key={index} className="cardPlan">
              <div className="title" style={{ textTransform: "uppercase" }}>
                {plan?.title}
              </div>
              <div className="franquia">
                <div className="containerIMg">
                  <img src={iconFran} alt="franc" />
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  Franquia mensal de{" "}
                  <strong>
                    {`${Number(
                      plan?.attributes?.maxTokenCount
                    ).toLocaleString()} tokens`}
                  </strong>
                </div>
              </div>

              <div className="containerBotton">
                <PlanDescription description={plan.description} />

                <div>
                  {plan.benefits.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        lineHeight: "30px",
                        gap: "15px",
                      }}
                    >
                      <Check
                        style={{
                          color: "#77E191",
                          height: "21px",
                          width: "21px",
                          marginRight: "5px",
                        }}
                      />
                      <div className="textList">{item}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="line"></div>
              <div className="containerBotton2">
                <div>
                  <div
                    className="containerValue"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="textValue">
                      <strong className="textCifran">R$</strong>
                      <strong>{Number(plan.amount).toFixed(2)}</strong>
                      <div>/mês</div>
                    </div>
                    <div className="subtextValue">
                      <strong>
                        {" "}
                        +R${" "}
                        {Number(
                          plan?.attributes?.costPerThousandToukens / 100
                        ).toLocaleString()}{" "}
                      </strong>
                      a cada mil tokens extra
                    </div>
                  </div>

                  <div
                    className="buttonSelectṔlan"
                    onClick={() => handleChange(plan)}
                  >
                    Selecionar Plano
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          className="buttonPlanTrial"
          onClick={() => handleChange(planTrial)}
        >
          Quero permanecer no Plano Trial{" "}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Step1;