import React from "react";
import arrow from "../../../../../assets/Vector.png";
import { ChanelSocial } from "../ChanelSocial";

import "./Step4.css";
import { toast } from "react-toastify";
import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import FormInput from "../../../../../components/FormInput";
import './FormInput.css';

const optionsArray = [
  "Agendar uma reunião",
  "Adquirir email do cliente",
  "Outro",
];

const toneArray = [
  "Respeitoso & Empático",
  "Animado & Engajador",
  "Profissional & Firme",
  "Atencioso & Calmo",
  "Encorajador & Motivador",
  "Compassivo & Compreensivo",
  "Divertido & Cativante",
  "Cortês & Gentil",
  "Solidário & Amigável",
  "Eficiente & Rápido",
  "Confiável & Preciso",
  "Assertivo & Decisivo",
  "Perspicaz & Observador",
  "Outro",
];

const preferentialLanguage = [
  "Português Brasileiro",
  "Inglês",
  "Japonês",
  "Mandarim",
  "Hindi",
  "Espanhol",
];

const navigateimulator = {
  menu: "Simulador do Atendente",
  index: 6,
};

const CheckboxObjectives = ({ label, isChecked, onChange }) => {
  return (
    <label className="checkbox-container">
      <div className="checkmarkBackground">
        <input type="checkbox" checked={isChecked} onChange={onChange} />
        <span className="checkmark"></span>
      </div>
      {`${label === "Outro" ? `${label} (descrever abaixo):` : label}`}
    </label>
  );
};

const Step4 = ({ form, saveLoading, setMenuSelected, checkList, scrollToSection1 }) => {
  const toggleObjective = (event) => {
    // Obtém o valor atual dos objetivos
    const prevObjectives = form?.values?.companyObjectives || [];

    // Verifica se o objetivo já está na lista
    const isObjectiveIncluded = prevObjectives.includes(event.target.name);

    form.setFieldValue(
      "companyObjectives",
      isObjectiveIncluded
        ? prevObjectives.filter((objective) => objective !== event.target.name)
        : [...prevObjectives, event.target.name]
    );
  };

  const handleChange = (event, field) => {
    let values = event.target.value;

    // Verifica se o valorEspecial está no array.
    const especialIndex = values.indexOf("Outro");

    // Se o valorEspecial estiver no array, remova-o e adicione-o de volta ao final.
    if (especialIndex !== -1) {
      values.splice(especialIndex, 1);
      values.push("Outro");
    }

    form.setFieldValue(field, values);
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const handleMenu = (item) => {
    if (
      item.menu === "Simulador do Atendente" &&
      !checkList
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      setMenuSelected(item);
      if (isMobileDevice()) {
        scrollToSection1();
      }
    }
  };

  return (
    <div className="containerStep4">
      <div className="titlePage">Estratégia de Atendimento</div>
      <div className="containerInputs">
        <div className="card">
          <div className="containerInput" style={{ height: "100%" }}>
            <div className="title">Canais de Comunicação</div>
            {/* <div className="subTitleInput">
              Liste os canais e seus respectivos links para promover seu negócio
            </div> */}
            <ChanelSocial
              label="Canais de Comunicação"
              form={form}
              helperText="Liste os canais e seus respectivos links para promover seu negócio e interagir com os clientes"
              variant="outlined"
            />
          </div>

          <div className="containerInput">
            <div className="title">Tom e Voz</div>
            {/* <div className="subTitleInput">
              Escolha o estilo e a atitude da comunicação que refletem a
              personalidade do negócio
            </div> */}
            {/* <select
          className="inputStyle"
          type="text"
          name="toneVoice"
          onChange={(event) => handleChange(event, "toneVoice")}
          onBlur={form.handleBlur}
          value={form.values.toneVoice}
        >
          {toneArray.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select> */}

            <FormInput variant="outlined"
              form={form}
              fieldName="toneVoice"
              renderValue={(selected) => selected.join(", ")}
              className="migrated-form-input"
              width="100%"
              onChange={(event) => handleChange(event, "toneVoice")}
              multiple
              disabled
            >
              {toneArray.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    <Checkbox
                      checked={form.values.toneVoice.indexOf(item) > -1}
                    />
                    <ListItemText>{item}</ListItemText>
                  </MenuItem>
                );
              })}
            </FormInput>
          </div>

          {/* <div className="containerInput">
            <div className="title">Diferenciais Chave</div>
            <div className="subTitleInput">
              Destaque os principais aspectos que tornam seu negócio único no
              mercado
            </div>
            <textarea
              className="inputStyle"
              type="text"
              name="keyDifferentials"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.keyDifferentials}
              rows="4"
              style={{ height: "100%" }}
            />
          </div> */}
        </div>

        <div className="card">
          <div className="containerInput">
            <div className="title">Objetivos do Atendente</div>
            {/* <div className="subTitleInput">
              Defina as principais intenções do atendente durante a conversa com
              o cliente
            </div> */}

            <div className="checkbox-group">
              {optionsArray.map((item) => (
                <CheckboxObjectives
                  label={item}
                  isChecked={form?.values?.companyObjectives?.includes(item)}
                  // onChange={(e) =>
                  //   toggleObjective({
                  //     target: { name: item },
                  //   })
                  // }
                />
              ))}
            </div>

            <textarea
              className="inputStyle"
              type="text"
              name="companyObjectivesExtra"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.companyObjectivesExtra}
              rows="4"
              style={{ height: "100%" }}
              disabled
            />
          </div>
          <div className="containerInput">
            <div className="title">Idioma de Resposta</div>
            {/* <div className="subTitleInput">
              Idioma que o atendente usará para responder o cliente
            </div> */}
            <select
              className="inputStyle"
              type="text"
              name="preferentialLanguage"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.preferentialLanguage}
              disabled
            >
              {preferentialLanguage.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="containerButtons">
        <div
          onClick={() => handleMenu(navigateimulator)}
          className={`buttonSimulator ${
            !checkList && "disabledMenus"
          }`}
        >
          Simular atendimento
        </div>

        {/* <div onClick={form.handleSubmit} className="buttonSave">
          {saveLoading ? (
            "Carregando..."
          ) : (
            <>
              Salvar alterações
              <img src={arrow} alt="arrow" />
            </>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Step4;
