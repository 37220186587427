import React from 'react';
import './GeistPagination.css';
import CaretDownIcon from '../../assets/CaretDown.png';

const GeistPagination = ({
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  totalPages
}) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="geist-pagination">
      <div className="geist-pagination-info">
        <span>Itens por página:</span>
        <select
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(Number(e.target.value))}
          className="geist-pagination-select"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        <img src={CaretDownIcon} alt="Select" className="geist-pagination-select-icon" />
      </div>
      <div className="geist-pagination-controls">
        <button
          className="geist-pagination-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span className="geist-pagination-pages">
          Página {currentPage} de {totalPages}
        </span>
        <button
          className="geist-pagination-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Próxima
        </button>
      </div>
    </div>
  );
};

export default GeistPagination;
