import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Card from "../../../../components/Card";
import Button from "../../../../components/Button";
import iconChat from "../../../../assets/CreditCard.png";
import arrow from "../../../../assets/Vector.png";

const CreditCardInfo = ({ subscription, isCardExpired, navigate }) => (
  <Card className="containerBox">
    <div className="title">
      Seu cartão cadastrado
    </div>
    <div className="containerCredCards">
      <div className="cardCredit">
        <strong className="text">Bandeira</strong>
        <div className="subtitle">{subscription?.brand}</div>
      </div>
      <div className="cardCredit">
        <strong className="text">Número</strong>
        <div className="subtitle">{subscription?.card}</div>
      </div>
      <div className="cardCredit">
        <strong className="text">Situação</strong>
        <div className="subtitle">
          {subscription?.brand
            ? isCardExpired(subscription?.expiresAt)
              ? "Expirado"
              : "Válido"
            : ""}
        </div>
      </div>
      <div className="cardCredit">
        <strong className="text">Renovação</strong>
        <div className="subtitle">
          {subscription?.brand && "Automática"}
        </div>
      </div>
      <div className="cardCredit"  style={{marginBottom: '10px'}}>
        <strong className="text">Data da Renovação</strong>
        <div className="subtitle">
          {subscription?.brand && moment(subscription?.payValidate).format("DD/MM/YYYY")}
        </div>
      </div>
    </div>
    <Button
      onClick={() => navigate("/financeiro/cartao-de-credito")}
      variant="outline"
    >
      Cadastrar Cartão
      {/* <img src={arrow} alt="arrow" style={{ width: '12px', height: '12px' }} /> */}
    </Button>
  </Card>
);

CreditCardInfo.propTypes = {
  subscription: PropTypes.shape({
    brand: PropTypes.string,
    card: PropTypes.string,
    expiresAt: PropTypes.string,
    payValidate: PropTypes.string,
  }),
  isCardExpired: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default CreditCardInfo;