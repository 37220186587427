import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { Loader } from "../../../components/Loader";
import ReactInputMask from "react-input-mask";

import logo from "../../../assets/407b23225039d193f5e539e2de0900f2.svg";
import unmask from "../../../utils/unmask";
import { useNavigate } from "react-router-dom";
import { SelectWithLabel } from "../../../components/SelectWithLabel";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Keyboard } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/keyboard";
import "./swiperStyling.css";

import saclogo from "../../../assets/altLogo.svg";
import moment from "moment";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormInput from "../../../components/FormInput";
import "../../../components/FormInput/FormInput.css";

export const SettingsSteps = () => {
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);

  const { user, updateUser, updatePartnerDetail, signOut } =
    useContext(AuthContext);

  const [modal, setModal] = useState(false);
  const [modalCompleted, setModalCompleted] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  const [step, setStep] = useState("0");
  const [phone, setPhone] = useState(user.serviceNumber || "");
  const [qrLoading, setQrLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const [tempPhone, setTempPhone] = useState(phone);
  const [schedulesID, setSchedulesID] = useState("");
  const navigate = useNavigate();

  const optionsArray = [
    "Agendar uma reunião",
    "Adquirir email do cliente",
    "Outro",
  ];

  const toneArray = [
    "Respeitoso & Empático",
    "Animado & Engajador",
    "Profissional & Firme",
    "Atencioso & Calmo",
    "Encorajador & Motivador",
    "Compassivo & Compreensivo",
    "Divertido & Cativante",
    "Cortês & Gentil",
    "Solidário & Amigável",
    "Eficiente & Rápido",
    "Confiável & Preciso",
    "Assertivo & Decisivo",
    "Perspicaz & Observador",
    "Outro",
  ];

  const daysArray = [
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
    "Domingo",
  ];

  const form = useFormik({
    initialValues: {
      nameAttendant: "",
      companyName: "",
      companyNiche: "",
      servedRegion: "",
      companyProducts: "",
      companyServices: "",
      companyContact: "",
      companyObjectives: [],
      companyObjectivesExtra: "",
      marketingChannels: "",
      keyDifferentials: "",
      toneVoice: [],
      toneVoiceExtra: "",
      preferentialLanguage: "",
      catalogLink: "",
    },

    // validationSchema: yup.object({
    //   money: yup.number("Deve ser um número"),
    //   ratio: yup.number("Deve ser um número").required("Campo obrigatório"),
    // }),

    onSubmit: () => updateSettings(),
  });

  const formSchedules = useFormik({
    initialValues: {
      // password: "",
      durationInMinutes: 0,
      weekDays: [],
      startTime: moment(new Date(2000, 0, 1, 8, 0, 0)),
      endTime: moment(new Date(2000, 0, 1, 20, 0, 0)),
      intervalMinutes: 0,
    },

    onSubmit: () => updateSchedules(),
  });

  const sortDays = (selected) => {
    return selected.sort((a, b) => {
      return daysArray.indexOf(a) - daysArray.indexOf(b);
    });
  };

  const updateSchedules = async () => {
    setSaveLoading(true);

    try {
      if (schedulesID) {
        const { data } = await api.put(
          `partners/schedule_settings/${schedulesID}`,
          {
            data: {
              attributes: {
                durationInMinutes: Number(
                  formSchedules?.values?.durationInMinutes
                ),
                weekDays: formSchedules?.values?.weekDays.join(", "),
                startTime: formSchedules?.values?.startTime.format("HH:mm"),
                endTime: formSchedules?.values?.endTime.format("HH:mm"),
                intervalMinutes: Number(formSchedules?.values?.intervalMinutes),
              },
            },
          }
        );

        if (data) {
          // Adding it's token to all requests for now on.
          // toast.success(
          //   "Configurações de agendamento atualizadas com sucesso!"
          // );
        }
      } else {
        const { data } = await api.post(`partners/schedule_settings`, {
          data: {
            attributes: {
              durationInMinutes: Number(
                formSchedules?.values?.durationInMinutes
              ),
              weekDays: formSchedules?.values?.weekDays.join(", "),
              startTime: formSchedules?.values?.startTime.format("HH:mm"),
              endTime: formSchedules?.values?.endTime.format("HH:mm"),
              intervalMinutes: Number(formSchedules?.values?.intervalMinutes),
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          // toast.success(
          //   "Configurações de agendamento atualizadas com sucesso!"
          // );
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error(
        "Não foi possível atualizar as configurações de agendamento."
      );
    } finally {
      setSaveLoading(false);
    }
  };

  const getSettings = async () => {
    setLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.get(
          `partners/partner_details/${user.partnerDetailId}`
        );

        if (data) {
          let valorExtra = "";
          const valoresFiltrados = Object.keys(
            data.data.attributes.companyObjectives
          ).reduce((acc, key) => {
            if (
              optionsArray.includes(data.data.attributes.companyObjectives[key])
            ) {
              acc[key] = data.data.attributes.companyObjectives[key];
            } else {
              acc[key] = "Outro";
              valorExtra = data.data.attributes.companyObjectives[key];
            }

            return acc;
          }, []);

          let toneExtra = "";
          const toneVoiceFiltrados = Object.keys(
            data.data.attributes.toneVoice
          ).reduce((acc, key) => {
            if (toneArray.includes(data.data.attributes.toneVoice[key])) {
              acc[key] = data.data.attributes.toneVoice[key];
            } else {
              acc[key] = "Outro";
              toneExtra = data.data.attributes.toneVoice[key];
            }

            return acc;
          }, []);
          form.setValues({
            ...data.data.attributes,
            companyObjectives: valoresFiltrados,
            companyObjectivesExtra: valorExtra,
            toneVoice: toneVoiceFiltrados,
            toneVoiceExtra: toneExtra,
            catalogLink: data?.data?.attributes?.catalogLink || "",
          });
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
    }
  };

  const updateSettings = async () => {
    setSaveLoading(true);

    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                nameAttendant: form.values.nameAttendant,
                companyName: form.values.companyName,
                companyNiche: form.values.companyNiche,
                servedRegion: form.values.servedRegion,
                companyServices: form.values.companyServices,
                companyProducts: form.values.companyProducts,
                companyContact: form.values.companyContact,
                companyObjectives: form?.values?.companyObjectives?.map(
                  (item) => {
                    if (item === "Outro") {
                      return form.values.companyObjectivesExtra;
                    } else {
                      return item;
                    }
                  }
                ),
                marketing_channels: form.values.marketingChannels,
                key_differentials: form.values.keyDifferentials,
                tone_voice: form?.values?.toneVoice?.map((item) => {
                  if (item === "Outro") {
                    return form.values.toneVoiceExtra;
                  } else {
                    return item;
                  }
                }),
                preferentialLanguage: form.values.preferentialLanguage,
                catalogLink: form.values.catalogLink,
              },
            },
          }
        );

        if (data) {
          if (form?.values?.companyObjectives.includes("Agendar uma reunião")) {
            updateSchedules();
          }
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          setModal(true);
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              nameAttendant: form.values.nameAttendant,
              companyName: form.values.companyName,
              companyNiche: form.values.companyNiche,
              servedRegion: form.values.servedRegion,
              companyServices: form.values.companyServices,
              companyProducts: form.values.companyProducts,
              companyContact: form.values.companyContact,
              companyObjectives: form?.values?.companyObjectives?.map(
                (item) => {
                  if (item === "Outro") {
                    return form.values.companyObjectivesExtra;
                  } else {
                    return item;
                  }
                }
              ),
              marketing_channels: form.values.marketingChannels,
              key_differentials: form.values.keyDifferentials,
              tone_voice: form?.values?.toneVoice?.map((item) => {
                if (item === "Outro") {
                  return form.values.toneVoiceExtra;
                } else {
                  return item;
                }
              }),
              preferentialLanguage: form.values.preferentialLanguage,
              catalogLink: form.values.catalogLink,
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          if (form?.values?.companyObjectives.includes("Agendar uma reunião")) {
            updateSchedules();
          }
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          setModal(true);
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    if (step === "1") {
      getQRCode();
    }
  }, [step]);

  useEffect(() => {
    setTempPhone(phone);
  }, [phone]);

  const getQRCode = async () => {
    setQrLoading(true);

    try {
      const { data } = await api.get("/partners/auth_whatsapp", {
        responseType: "arraybuffer",
      });
      if (data) {
        const blob = new Blob([data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);

        if (url) {
          setQrCode(url);
        } else {
          setQrCode("QRCodeError");
          throw Error("No URL");
        }
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar gerar o QR Code de validação.");
      if (error?.message !== "No URL") {
        setQrCode("GeneralError");
      }
    } finally {
      setQrLoading(false);
    }
  };

  const updatePhone = async () => {
    setSaveLoading(true);

    try {
      if (phone) {
        const { data } = await api.put(`/partners/partners/${user.id}`, {
          data: {
            attributes: {
              service_number: unmask(phone),
            },
          },
        });
        if (data) {
          setStep("1");
          updateUser(data);
          toast.success(
            "Número atualizado com sucesso! Conclua a validação para concluir a ativação."
          );
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar o número cadastrado.");
    } finally {
      setSaveLoading(false);
    }
  };

  const getScheduleSettings = async () => {
    setScheduleLoading(true);

    try {
      const { data } = await api.get("/partners/schedule_settings/my_settings");
      if (data.data) {
        setSchedulesID(data?.data?.id);
        formSchedules.setValues({
          durationInMinutes: data?.data?.attributes.durationInMinutes,
          weekDays: data?.data?.attributes.weekDays.split(", "),
          startTime: moment(data?.data?.attributes.startTime, "HH:mm"),
          endTime: moment(data?.data?.attributes.endTime, "HH:mm"),
          intervalMinutes: data?.data?.attributes?.intervalMinutes || 0,
        });
      }
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao tentar recuperar as configurações de agendamento"
      );
    } finally {
      setScheduleLoading(false);
    }
  };

  const handleChange = (event, field) => {
    let values = event.target.value;

    // Verifica se o valorEspecial está no array.
    const especialIndex = values.indexOf("Outro");

    // Se o valorEspecial estiver no array, remova-o e adicione-o de volta ao final.
    if (especialIndex !== -1) {
      values.splice(especialIndex, 1);
      values.push("Outro");
    }

    form.setFieldValue(field, values);
  };

  useEffect(() => {
    getSettings();
    getScheduleSettings();
  }, []);

  const setPrimary = () => {
    setModal(false);
    setModalWarning(false);
    setQrCode("");
    setStep("0");
  };

  const [checkConnected, setCheckConnected] = useState(false);

  const checkWhatsConeccted = async () => {
    try {
      const { data } = await api.get("partners/partners/wpp_connected", {
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
      setCheckConnected(data.wppConnected);
    } catch (error) {}
  };

  const CountdownTimer = () => {
    const [seconds, setSeconds] = useState(60);

    if (seconds === 0 && !checkConnected) {
      setModal(false);
      setModalWarning(false);
      setQrCode("");
      setStep("0");
      toast.error("Tempo expirado! Por favor, gere um novo QR Code.");
    } else if (checkConnected) {
      setModal(false);
      setModalWarning(false);
      setQrCode("");
      setStep("0");
      toast.success("Numero do atendente conectado com sucesso!");
      signOut();
    }

    useEffect(() => {
      const countdownInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      const checkInterval = setInterval(async () => {
        await checkWhatsConeccted();
        if (checkConnected) {
          clearInterval(countdownInterval);
          clearInterval(checkInterval);
        }
      }, 8000);

      return () => {
        clearInterval(countdownInterval);
        clearInterval(checkInterval);
      };
    }, [checkConnected]);

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p
          style={{
            color: seconds <= 10 && "red",
            margin: 0,
            fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', "sans-serif"],
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
            alignSelf: "flex-start",
            marginTop: "15px",
            marginBottom: "15px",
            textAlign: "center",
            width: "100%",
          }}
        >
          Tempo restante: {minutes}:{remainingSeconds < 10 ? "0" : ""}
          {remainingSeconds}
        </p>
      </div>
    );
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let width = isMobileDevice() ? 350 : 515;

  return (
    <AuthorizedLayout>
      {loading ? (
        // <Typography variant="h6">Carregando... </Typography>
        <Loader size={150} />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              top: "10%",
              bgcolor: "white",
              minWidth: "650px",
              //height: "45%",
              flexDirection: "column",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 10,
              borderRadius: 5,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "white",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Swiper
                cssMode={true}
                pagination={{ clickable: true }}
                keyboard={true}
                navigation={true}
                modules={[Navigation, Pagination, Keyboard]}
                preventClicks={false}
                simulateTouch={false}
                wrapperClass="swiperCenterAlign"
              >
                <SwiperSlide style={{ alignItems: "center", width: "50px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      paddingX: "64px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "65%",
                      }}
                    >
                      <Typography
                        fontSize={20}
                        fontWeight={600}
                        marginBottom={"20px"}
                        alignSelf={"center"}
                      >
                        Hora de Preencher a Base de Dados do Seu Atendente!
                      </Typography>
                      <Typography fontSize={18}>
                        É momento de dar vida ao seu atendente virtual! As
                        informações inseridas nos campos são essenciais para o
                        funcionamento dele.
                      </Typography>
                      <Typography fontSize={18} fontWeight={600}>
                        Capriche em cada detalhe, pois esses dados são como o
                        cérebro do seu atendente.
                      </Typography>
                      <Typography fontSize={18} marginY={"20px"}>
                        Não se esqueça: copie e cole FAQs, guias, links e dados
                        importantes de PDFs e outros documentos. Seu atendente
                        utilizará essas informações durante as conversas para
                        oferecer o melhor atendimento possível.
                      </Typography>
                      <Typography fontSize={18}>
                        Vamos lá, vamos transformar esses dados em um
                        atendimento incrível!
                      </Typography>
                    </div>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      height: "100%",
                      paddingX: "64px",
                      marginBottom: "15px",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormInput label="Nome do/a Atendente"
                        fieldName="nameAttendant"
                        form={form}
                        helperText="Insira o nome do atendente responsável por interações com os clientes"
                        // multiline={true}
                        className="migrated-form-input"
                        width="100%"
                      />
                      {/* <FormInput label="Tom e voz"
                        fieldName="toneVoice"
                        form={form}
                        helperText="Escolha o estilo e a atitude da comunicação que refletem a personalidade do negócio"
                        multiline={true}
                      / className="migrated-form-input" width="100%"> */}
                      <SelectWithLabel
                        label="Tom e voz"
                        variant="outlined"
                        helperText="Escolha o estilo e a atitude da comunicação que refletem a personalidade do negócio"
                        form={form}
                        value={"toneVoice"}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={(event) => handleChange(event, "toneVoice")}
                        multiple
                      >
                        {toneArray.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              <Checkbox
                                checked={
                                  form.values.toneVoice.indexOf(item) > -1
                                }
                              />
                              <ListItemText>{item}</ListItemText>
                            </MenuItem>
                          );
                        })}
                      </SelectWithLabel>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {/* <FormInput label="Público-alvo"
                        fieldName="targetAudience"
                        form={form}
                        helperText="Defina o grupo de clientes que você deseja alcançar com a comunicação"
                        multiline={true}
                      / className="migrated-form-input" width="100%"> */}
                      <SelectWithLabel
                        label={"Idioma de Resposta"}
                        variant="outlined"
                        helperText="Idioma que o atendente usará para responder o cliente"
                        form={form}
                        value={"preferentialLanguage"}
                        style={{ marginBottom: "25px" }}
                      >
                        <MenuItem value={"Português Brasileiro"}>
                          Português Brasileiro
                        </MenuItem>
                        <MenuItem value={"Inglês"}>Inglês</MenuItem>
                        <MenuItem value={"Japonês"}>Japonês</MenuItem>
                        <MenuItem value={"Mandarim"}>Mandarim</MenuItem>
                        <MenuItem value={"Hindi"}>Hindi</MenuItem>
                        <MenuItem value={"Espanhol"}>Espanhol</MenuItem>
                      </SelectWithLabel>
                      {form?.values?.toneVoice.includes("Outro") && (
                        <FormInput label="Descrição do tom e voz"
                          fieldName="toneVoiceExtra"
                          form={form}
                          helperText="Ex: Enfatiza linguajar empresarial, fala de forma característica de alguma região, etc."
                          multiline={true}
                        className="migrated-form-input"
                        width="100%"
                      />
                      )}
                    </Grid>
                  </Grid>
                </SwiperSlide>
                <SwiperSlide>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      height: "100%",
                      paddingX: "64px",
                      marginBottom: "15px",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormInput label="Nome Fantasia do Negócio"
                        fieldName="companyName"
                        form={form}
                        helperText={
                          "Digite o nome que o atendente usará para se referir ao seu negócio"
                        }
                        className="migrated-form-input"
                        width="100%"
                      />
                      <FormInput label="Campo de atuação do Negócio"
                        fieldName="servedRegion"
                        form={form}
                        helperText="Ex: Serviços de acompanhamento escolar, Serviços financeiros, consultoria nutricional"
                        className="migrated-form-input"
                        width="100%"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormInput label="Nicho do Negócio"
                        fieldName="companyNiche"
                        form={form}
                        helperText="Especifique a área de atuação do seu negócio para melhor direcionamento"
                        className="migrated-form-input"
                        width="100%"
                      />
                      <FormInput label="Site do Negócio"
                        fieldName="companyContact"
                        form={form}
                        helperText="Informe o endereço web do seu negócio, caso já possua um site"
                        className="migrated-form-input"
                        width="100%"
                      />
                    </Grid>
                  </Grid>
                </SwiperSlide>

                <SwiperSlide>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      height: "100%",
                      paddingX: "64px",
                      marginBottom: "15px",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormInput label="Canais de Comunicação"
                        fieldName="marketingChannels"
                        form={form}
                        helperText="Liste os canais e seus respectivos links para promover seu negócio e interagir com os clientes"
                        multiline={true}
                      className="migrated-form-input"
                      width="100%"
                    />
                      <SelectWithLabel
                        label={"Proposta a fazer ao cliente"}
                        variant="outlined"
                        helperText="Escolha os objetivos do atendente durante a conversa com o cliente"
                        form={form}
                        value={"companyObjectives"}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={(event) =>
                          handleChange(event, "companyObjectives")
                        }
                        multiple
                      >
                        {optionsArray.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              <Checkbox
                                checked={
                                  form.values.companyObjectives.indexOf(item) >
                                  -1
                                }
                              />
                              <ListItemText>{item}</ListItemText>
                            </MenuItem>
                          );
                        })}
                      </SelectWithLabel>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormInput label="Diferenciais chave"
                        fieldName="keyDifferentials"
                        form={form}
                        helperText="Destaque os principais aspectos que tornam seu negócio único no mercado"
                        multiline={true}
                      className="migrated-form-input"
                      width="100%"
                    />

                      {form?.values?.companyObjectives.includes("Outro") && (
                        <FormInput label="Descrição da proposta a fazer ao cliente"
                          fieldName="companyObjectivesExtra"
                          form={form}
                          helperText="Ex: Agendar uma reunião online ou adquirir o email do cliente"
                          multiline={true}
                        className="migrated-form-input"
                        width="100%"
                      />
                      )}
                    </Grid>
                  </Grid>
                </SwiperSlide>
                {form?.values?.companyObjectives.includes(
                  "Agendar uma reunião"
                ) && (
                  <SwiperSlide>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          height: "100%",
                          paddingX: "64px",
                          marginBottom: "15px",
                        }}
                      >
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <SelectWithLabel
                            label={
                              "Dias disponíveis para agendamento de reuniões"
                            }
                            variant="outlined"
                            helperText="Escolha os dias da semana em que o atendente poderá agendar reuniões com os clientes"
                            form={formSchedules}
                            value={"weekDays"}
                            renderValue={(selected) => selected.join(", ")}
                            onChange={(event) =>
                              formSchedules.setFieldValue(
                                "weekDays",
                                sortDays(event.target.value)
                              )
                            }
                            multiple
                          >
                            {daysArray.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item}>
                                  <Checkbox
                                    checked={
                                      formSchedules.values.weekDays.indexOf(
                                        item
                                      ) > -1
                                    }
                                  />
                                  <ListItemText>{item}</ListItemText>
                                </MenuItem>
                              );
                            })}
                          </SelectWithLabel>
                          <div style={{ marginBottom: "15px" }} />
                          <FormInput validate
                            label="Duração das reuniões"
                            fieldName="durationInMinutes"
                            helperText="Insira a duração, em minutos, das reuniões a serem marcadas pelo atendente"
                            form={formSchedules}
                            type={"number"}
                            InputProps={{ inputProps: { min: 0 } }}
                          className="migrated-form-input"
                          width="100%"
                        />
                          <FormInput validate
                            label="Intervalo entre as reuniões"
                            fieldName="intervalMinutes"
                            helperText="Insira a duração, em minutos, do intervalo entre os horários das reuniões a serem marcadas"
                            form={formSchedules}
                            type={"number"}
                            InputProps={{ inputProps: { min: 0 } }}
                          className="migrated-form-input"
                          width="100%"
                        />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <FormControl fullWidth>
                              <Typography
                                style={{ marginLeft: "2px" }}
                                fontSize={16}
                                fontWeight={600}
                                color={"#539d65"}
                              >
                                {"Hora de Início"}
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: "2px",
                                  marginBottom: "5px",
                                }}
                                fontSize={14}
                                color={"#566986"}
                              >
                                {
                                  "Escolha o horário de início do expediente disponível para reuniões nos dias selecionados"
                                }
                              </Typography>
                              <TimePicker
                                value={formSchedules.values.startTime}
                                onChange={(newValue) =>
                                  formSchedules.setFieldValue(
                                    "startTime",
                                    newValue
                                  )
                                }
                              />
                            </FormControl>

                            <div style={{ marginBottom: "15px" }} />

                            <FormControl fullWidth>
                              <Typography
                                style={{ marginLeft: "2px" }}
                                fontSize={16}
                                fontWeight={600}
                                color={"#539d65"}
                              >
                                {"Hora de Término"}
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: "2px",
                                  marginBottom: "5px",
                                }}
                                fontSize={14}
                                color={"#566986"}
                              >
                                {
                                  "Escolha o horário limite para iniciar uma reunião nos dias selecionados"
                                }
                              </Typography>
                              <TimePicker
                                value={formSchedules.values.endTime}
                                onChange={(newValue) =>
                                  formSchedules.setFieldValue(
                                    "endTime",
                                    newValue
                                  )
                                }
                              />
                            </FormControl>
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </div>
                  </SwiperSlide>
                )}

                <SwiperSlide>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      height: "100%",
                      paddingX: "64px",
                      marginBottom: "15px",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormInput label="Produtos do Negócio"
                        fieldName="companyProducts"
                        form={form}
                        helperText="Descreva os produtos ou itens distintos que seu negócio disponibiliza no mercado"
                        multiline={true}
                      className="migrated-form-input"
                      width="100%"
                    />
                      <FormInput label="Catálogo de Produtos/Serviços"
                        fieldName="catalogLink"
                        form={form}
                        helperText="Insira o link do seu catálogo de produtos/serviços para o atendente fornecer ao cliente sempre que solicitado"
                        className="migrated-form-input"
                        width="100%"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormInput label="Serviços do Negócio"
                        fieldName="companyServices"
                        form={form}
                        helperText="Liste os principais serviços ou soluções oferecidos pelo seu negócio"
                        multiline={true}
                      className="migrated-form-input"
                      width="100%"
                    />
                    </Grid>
                  </Grid>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      paddingX: "64px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        src={saclogo}
                        style={{
                          width: "500px",
                          marginBottom: "-50px",
                          marginTop: "-75px",
                        }}
                        alt="Logo da empresa SacGPT"
                      />
                    </div>
                    <Typography fontSize={20} fontWeight={"600"}>
                      Estamos quase lá!
                    </Typography>
                    <Typography fontSize={18}>
                      Agora é só salvar suas configurações e vincular um número
                      de telefone do atendente.
                    </Typography>
                    <Button
                      sx={{
                        height: "35px",
                        p: 2,
                        marginTop: "25px",
                        width: "150px",
                      }}
                      variant="contained"
                      onClick={() => {
                        form.handleSubmit();
                      }}
                      disabled={saveLoading}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        Salvar
                      </Typography>
                    </Button>
                  </Box>
                </SwiperSlide>
              </Swiper>
            </Box>
          </Box>
          {modal && (
            <Modal
              open
              onClose={() => {
                setModal(false);
                setQrLoading(true);
                if (step === "1") {
                  setModalWarning(true);
                  setStep("0");
                } else {
                  setStep("0");
                }
              }}
            >
              <Box
                style={{
                  background:
                    "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                  borderRadius: "20px",
                }}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: width,
                  // height: 200,
                  p: 2.5,
                }}
              >
                {step === "0" && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ReactInputMask
                      mask={
                        tempPhone.replace(/\s/g, "").length > 13
                          ? "(99) 9 9999-9999"
                          : "(99) 9999-99999"
                      }
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      maskChar={""}
                    >
                      {() => (
                        <TextField
                          label="Insira o número de telefone desejado"
                          value={phone}
                          style={{ marginTop: "15px", marginBottom: "5px" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={logo}
                                  alt="test"
                                  style={{ width: "35px" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </ReactInputMask>
                    <Button
                      sx={{
                        height: "35px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        updatePhone();
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {saveLoading ? "Carregando" : "Salvar"}
                      </Typography>
                    </Button>
                    <Typography fontSize={11} sx={{ marginLeft: 1 }}>
                      *Tenha seu número de telefone ao alcance para realizar a
                      validação na próxima etapa
                    </Typography>
                  </div>
                )}

                {step === "1" && (
                  <div>
                    {qrLoading ? (
                      <Loader size={150} />
                    ) : (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{
                            margin: 0,
                            fontFamily: [
                              '"Roboto"',
                              '"Helvetica"',
                              '"Arial"',
                              "sans-serif",
                            ],
                            fontWeight: 500,
                            fontSize: "1.25rem",
                            lineHeight: 1.6,
                            letterSpacing: "0.0075em",
                            alignSelf: "flex-start",
                            marginTop: "15px",
                            marginBottom: "15px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {qrCode === "QRCodeError" ||
                          qrCode === "GeneralError" ? (
                            <Typography
                              fontSize={24}
                              fontWeight={600}
                              color={"#539d65"}
                            >
                              Erro
                            </Typography>
                          ) : (
                            "Leia o QRCode abaixo para realizar a validação"
                          )}
                        </Typography>
                        {console.log(qrCode)}
                        {qrCode === "QRCodeError" ||
                        qrCode === "GeneralError" ? (
                          <Typography
                            textAlign={"center"}
                            style={{ marginBottom: "25px" }}
                          >
                            {qrCode === "QRCodeError"
                              ? "Ocorreu um erro ao gerar o QRCode, verifique se o número registrado está disponível para vinculação."
                              : "Ocorreu um erro ao gerar o QRCode, por favor tente novamente."}
                          </Typography>
                        ) : (
                          <img
                            src={qrCode}
                            alt="QR Code"
                            style={{ borderRadius: "20px" }}
                          />
                        )}
                        <CountdownTimer />
                      </div>
                    )}
                  </div>
                )}

                {step === "2" && (
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          margin: 0,
                          fontFamily: [
                            '"Roboto"',
                            '"Helvetica"',
                            '"Arial"',
                            "sans-serif",
                          ],
                          fontWeight: 500,
                          fontSize: "1.25rem",
                          lineHeight: 1.6,
                          letterSpacing: "0.0075em",
                          alignSelf: "flex-start",
                          marginTop: "15px",
                          marginBottom: "15px",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          fontSize={28}
                          fontWeight={600}
                          color={"red"}
                        >
                          ATENÇÃO
                        </Typography>
                      </Typography>

                      <Typography
                        textAlign={"center"}
                        fontWeight={"600"}
                        style={{ marginBottom: "25px" }}
                        fontSize={20}
                      >
                        {"Telefone já conectado"}
                      </Typography>
                      <Typography
                        textAlign={"center"}
                        style={{ marginBottom: "25px" }}
                        fontSize={18}
                      >
                        {
                          "Para vincular novamente, desconecte-o primeiro do seu telefone atual. Em seguida, saia da conta e faça login novamente para atualizar as informações."
                        }
                      </Typography>

                      <Button
                        sx={{
                          height: "35px",
                          p: 2,
                          m: 1,
                          alignSelf: "center",
                        }}
                        variant="contained"
                        disabled={saveLoading}
                        onClick={() => {
                          setPrimary();
                        }}
                      >
                        <Typography color={"white"} variant={"h6"}>
                          {"OK, ENTENDI"}
                        </Typography>
                      </Button>
                    </div>
                  </div>
                )}
              </Box>
            </Modal>
          )}

          {modalWarning && (
            <Modal
              open
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  setModalWarning("false");
                }
              }}
            >
              <Box
                style={{
                  background:
                    "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                  borderRadius: "20px",
                }}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: isMobileDevice() ? 350 : 700,
                  // height: 200,
                  p: 2.5,
                }}
              >
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        margin: 0,
                        fontFamily: [
                          '"Roboto"',
                          '"Helvetica"',
                          '"Arial"',
                          "sans-serif",
                        ],
                        fontWeight: 500,
                        fontSize: "1.25rem",
                        lineHeight: 1.6,
                        letterSpacing: "0.0075em",
                        alignSelf: "flex-start",
                        marginTop: "15px",
                        marginBottom: "15px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        fontSize={28}
                        fontWeight={600}
                        color={"#539d65"}
                      >
                        Aviso
                      </Typography>
                    </Typography>

                    <Typography
                      textAlign={"center"}
                      fontWeight={"600"}
                      style={{ marginBottom: "25px" }}
                      fontSize={20}
                    >
                      {"Não responda diretamente pelo WhatsApp"}
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      style={{ marginBottom: "25px" }}
                      fontSize={18}
                    >
                      {
                        "Após configurar seu atendente, evite responder diretamente pelo WhatsApp. Qualquer interação direta pode afetar o funcionamento do atendente e a experiência do cliente."
                      }
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      style={{ marginBottom: "25px" }}
                      fontSize={18}
                    >
                      {
                        "Para modificações, use os campos de texto e seleção da nossa plataforma."
                      }
                    </Typography>
                    <Button
                      sx={{
                        height: "35px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        setModalWarning(false);
                        setModalCompleted(true);
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {"OK, ENTENDI"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          )}

          {modalCompleted && (
            <Modal
              open
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  setModalCompleted("false");
                }
              }}
            >
              <Box
                style={{
                  background:
                    "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                  borderRadius: "20px",
                }}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: isMobileDevice() ? 350 : 700,
                  // height: 200,
                  p: 2.5,
                }}
              >
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        margin: 0,
                        fontFamily: [
                          '"Roboto"',
                          '"Helvetica"',
                          '"Arial"',
                          "sans-serif",
                        ],
                        fontWeight: 500,
                        fontSize: "1.25rem",
                        lineHeight: 1.6,
                        letterSpacing: "0.0075em",
                        alignSelf: "flex-start",
                        marginTop: "15px",
                        marginBottom: "15px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        fontSize={28}
                        fontWeight={600}
                        color={"#539d65"}
                      >
                        Tudo feito!
                      </Typography>
                    </Typography>

                    <Typography
                      textAlign={"center"}
                      fontWeight={"600"}
                      style={{ marginBottom: "25px" }}
                      fontSize={20}
                    >
                      {
                        "Você já pode começar a aproveitar o serviços do SacGPT!"
                      }
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      style={{ marginBottom: "25px" }}
                      fontSize={18}
                    >
                      {
                        "Use o painel de Clientes para ver gerenciar seus leads, ver resumos de conversas e suas classificações de interesse."
                      }
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      style={{ marginBottom: "25px" }}
                      fontSize={18}
                    >
                      {
                        "Quer alterar o comportamento do seu atendente? Vá no painel de Configurações e personalize-o da forma que quiser!"
                      }
                    </Typography>
                    <Button
                      sx={{
                        height: "35px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {"INICIAR"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          )}
        </>
      )}
    </AuthorizedLayout>
  );
};
