import React, { useContext } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

import { CheckContext } from "./CheckProvider";

import "./CheckScreens.css";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../providers/AuthProvider";

const CheckScreens = () => {
  const { currentStep } = useContext(CheckContext);
  const { signOut } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const renderContent = () => {
    switch (currentStep) {
      case "step2":
        return <Step2 />;
      case "step3":
        return <Step3 />;
      case "step4":
        return <Step4 />;
      case "step5":
        return <Step5 />;
      default:
        return <Step1 />;
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  return (
    <div className="containerCheckScreens">
      {pathname === "/configuracoes/automatica" ? (
        <div
          className="containerButtons"
          style={{
            justifyContent: isMobileDevice() ? "center" : "flex-end",
            marginTop: isMobileDevice() && "20px",
          }}
        >
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="buttonMOdoWizard"
            style={{
              border: "1px solid red",
              position: !isMobileDevice() && "absolute",
              right: "10px",
              top: "10px",
            }}
          >
            Sair do modo automático
          </div>
        </div>
      ) : (
        <div
          className="containerButtons"
          style={{
            justifyContent: isMobileDevice() ? "center" : "flex-end",
            marginTop: isMobileDevice() && "20px",
          }}
        >
          <div
            onClick={() => signOut()}
            className="buttonMOdoWizard"
            style={{
              border: "1px solid red",
              position: !isMobileDevice() && "absolute",
              right: "10px",
              top: "10px",
              padding: "8px 20px",
            }}
          >
            Sair
          </div>
        </div>
      )}

      {renderContent()}
    </div>
  );
};

export default CheckScreens;
