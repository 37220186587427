import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MessageSquare } from "@geist-ui/icons";
import GeistTable from "../../../../components/GeistTable/GeistTable";
import parsePhoneAdmin from "../../../../utils/parsePhoneAdmin";

import "./LeadsCard.css";

const LeadsCard = ({ reports, selectedMonth }) => {
  const navigate = useNavigate();

  const columns = [
    {
      label: "NOME",
      field: "name",
      sortable: true,
    },
    {
      label: "TELEFONE",
      field: "phone",
      sortable: true,
      render: (value) => parsePhoneAdmin(value),
    },
    {
      label: "INTERESSE",
      field: "score",
      sortable: true,
      render: (value) => <div className="scoreValue">{value}</div>,
    },
    {
      label: "ÚLTIMO CONTATO",
      field: "lastMessage",
      sortable: true,
    },
    {
      label: "AÇÕES",
      field: "actions",
      render: (_, rowData) => (
        <button
          className="chatButton"
          onClick={() => navigate(`/chat/${rowData.id}`)}
        >
          <MessageSquare size={20} />
        </button>
      ),
    },
  ];

  const tableData = useMemo(() => {
    if (!reports?.overview?.clientScores?.length) return [];

    return reports.overview.clientScores
      .sort((a, b) => {
        const dateA = moment(a?.client?.data?.attributes?.createdAt);
        const dateB = moment(b?.client?.data?.attributes?.createdAt);
        return dateB - dateA;
      })
      .slice(0, 13)
      .map((data) => ({
        id: data?.client?.data?.id,
        name: data?.client?.data?.attributes?.name || "Não identificado",
        phone: data?.client?.data?.attributes?.phone || "Não informado",
        score: data?.clientScore || "0",
        lastMessage: data?.client?.data?.attributes?.createdAt
          ? moment(data.client.data.attributes.createdAt).format("DD/MM/YYYY")
          : "Sem mensagens",
      }));
  }, [reports]);

  return (
    <div className="containerCardLeads">
      <GeistTable
        columns={columns}
        data={tableData}
        loading={!reports}
        emptyText="Nenhum lead em evidência no momento"
      />
    </div>
  );
};

export default LeadsCard;
