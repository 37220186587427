import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import { Box, Button as MuiButton, Modal, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import SimulatorSection from "./Components/Simulator";
import MainCard from "../../../components/MainCard";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import "./Attendant.css";

const Attendant = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [menuSelected, setMenuSelected] = useState({
    menu: "Simulador do Atendente",
    index: 6,
  });

  // Função para verificar se é um dispositivo móvel (opcional)
  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  // Se houver alguma configuração necessária específica para o simulador, você pode adicionar aqui.
  useEffect(() => {
    // Carregar configurações necessárias para o simulador, se houver.
  }, []);

  // Renderiza o componente Step5, que é o Simulador do Atendente
  const handleResetConversation = () => {
    // Implementar a lógica para reiniciar a conversa
    console.log("Reiniciar conversa");
    // Você pode adicionar aqui a lógica para limpar o estado da conversa ou chamar uma função do SimulatorSection
  };

  return (
    <AuthorizedLayout>
      <SimulatorSection
        saveLoading={saveLoading}
        setMenuSelected={setMenuSelected}
        menuSelected={menuSelected}
      />

    </AuthorizedLayout>
  );
};

export default Attendant;
