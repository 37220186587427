import React, { useState } from "react";
import ModalAlert from "../../../components/ModalAlert";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../utils/api";
import { Trash2 } from "@geist-ui/icons";
import { Modal, Text } from "@geist-ui/core";

const DeleteModal = ({ item, getClients }) => {
  const [modalAlert, setModalAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`partners/automatic_messages/${id}`);
      toast.success("Disparo deletado com sucesso!");
      getClients();
    } catch (error) {
      if (error?.response?.data?.errors[0]?.title)
        toast.error(
          `Erro ao deletar! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error("Erro ao deletar!");
    } finally {
      setLoading(false);
      setModalAlert(false);
    }
  };

  return (
    <>
      <Trash2
        size={18}
        className="geist-table-action-icon"
        onClick={() => setModalAlert(true)}
      />

      <Modal visible={modalAlert} onClose={() => setModalAlert(false)}>
        <Modal.Title>ATENÇÃO</Modal.Title>
        <Modal.Content>
          <Text>
            Tem certeza que deseja excluir esse disparo? Essa ação é
            irreversível.
          </Text>
        </Modal.Content>
        <Modal.Action passive onClick={() => setModalAlert(false)}>
          Cancelar
        </Modal.Action>
        <Modal.Action onClick={() => handleDelete(item.id)} loading={loading}>
          {loading ? "Excluindo..." : "Excluir"}
        </Modal.Action>
      </Modal>
    </>
  );
};

export default DeleteModal;
