import MainCard from "../../../../../components/MainCard";
import Card from "../../../../../components/Card";

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { Calendar, Clock } from "@geist-ui/icons";
import api from "../../../../../utils/api";
import DoNotDisturb from "../../../../../components/DoNotDisturb/DoNotDisturb";
import "./styles.css";
import Button from "../../../../../components/Button";

const Section = ({ icon: Icon, title, description, children }) => (
  <div className="schedule-config-section">
    <div className="schedule-config-section-header">
      <div className="schedule-config-section-icon">
        <Icon className="schedule-config-icon" />
      </div>
      <div>
        <h3 className="schedule-config-section-title">{title}</h3>
        <p className="schedule-config-section-description">{description}</p>
      </div>
    </div>
    {children}
  </div>
);

const validationSchema = yup.object({
  weekDays: yup
    .array()
    .min(1, "Selecione pelo menos um dia da semana")
    .required("Dias da semana são obrigatórios"),
  startTime: yup.string().required("Hora de início é obrigatória"),
  endTime: yup.string().required("Hora de término é obrigatória"),
  durationInMinutes: yup
    .string()
    .required("Duração das reuniões é obrigatória"),
  intervalMinutes: yup
    .string()
    .required("Intervalo entre reuniões é obrigatório"),
});

const dayLabels = {
  "Segunda-feira": "Segunda",
  "Terça-feira": "Terça",
  "Quarta-feira": "Quarta",
  "Quinta-feira": "Quinta",
  "Sexta-feira": "Sexta",
  Sábado: "Sábado",
  Domingo: "Domingo",
};

const ScheduleConfig = () => {
  const [schedulesID, setSchedulesID] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [doNotDisturb, setDoNotDisturb] = useState(false);

  const handleHoursToMinutes = (hours, minutes) => {
    return Number(hours) * 60 + Number(minutes);
  };

  const handleMinutesToHours = (minutes) => {
    const hours = Math.trunc(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes}`;
  };

  const handleSubmit = async (values) => {
    setSaveLoading(true);
    try {
      const scheduleData = {
        data: {
          attributes: {
            weekDays: values.weekDays.join(", "),
            startTime: values.startTime.format("HH:mm"),
            endTime: values.endTime.format("HH:mm"),
            intervalMinutes: Number(
              handleHoursToMinutes(
                values.intervalMinutes.format("HH"),
                values.intervalMinutes.format("mm")
              )
            ),
            durationInMinutes: Number(
              handleHoursToMinutes(
                values.durationInMinutes.format("HH"),
                values.durationInMinutes.format("mm")
              )
            ),
          },
        },
      };

      if (schedulesID) {
        await api.put(
          `partners/schedule_settings/${schedulesID}`,
          scheduleData
        );
      } else {
        await api.post(`partners/schedule_settings`, scheduleData);
      }

      toast.success("Configurações de agendamento atualizadas com sucesso!");
    } catch (error) {
      toast.error(
        `Não foi possível atualizar as configurações de agendamento: ${error.message}`
      );
    } finally {
      setSaveLoading(false);
    }
  };

  const formSchedules = useFormik({
    initialValues: {
      durationInMinutes: moment(new Date(2000, 0, 1, 0, 0, 0)),
      weekDays: [],
      startTime: moment(new Date(2000, 0, 1, 8, 0, 0)),
      endTime: moment(new Date(2000, 0, 1, 20, 0, 0)),
      intervalMinutes: moment(new Date(2000, 0, 1, 0, 0, 0)),
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const getScheduleSettings = async () => {
    setScheduleLoading(true);
    try {
      const { data } = await api.get("/partners/schedule_settings/my_settings");
      if (data.data) {
        setSchedulesID(data?.data?.id);
        formSchedules.setValues({
          weekDays: data?.data?.attributes.weekDays.split(", "),
          startTime: moment(data?.data?.attributes.startTime, "HH:mm"),
          endTime: moment(data?.data?.attributes.endTime, "HH:mm"),
          durationInMinutes: data?.data?.attributes?.durationInMinutes
            ? moment(
                handleMinutesToHours(data?.data?.attributes?.durationInMinutes),
                "HH:mm"
              )
            : moment(new Date(2000, 0, 1, 0, 0, 0)),
          intervalMinutes: data?.data?.attributes?.intervalMinutes
            ? moment(
                handleMinutesToHours(data?.data?.attributes?.intervalMinutes),
                "HH:mm"
              )
            : moment(new Date(2000, 0, 1, 0, 0, 0)),
        });
      }
    } catch (error) {
      toast.error(
        `Ocorreu um erro ao tentar recuperar as configurações de agendamento: ${error.message}`
      );
    } finally {
      setScheduleLoading(false);
    }
  };

  useEffect(() => {
    getScheduleSettings();
  }, []);

  const weekDays = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];
  const selectedDays = formSchedules.values.weekDays;

  const handleDaySelect = (index) => {
    const dayStr = index.toString();
    const currentDays = selectedDays || [];

    if (currentDays.includes(dayStr)) {
      formSchedules.setFieldValue(
        "weekDays",
        currentDays.filter((day) => day !== dayStr)
      );
    } else {
      formSchedules.setFieldValue("weekDays", [...currentDays, dayStr]);
    }
  };

  const WeekDayButton = ({ day, label, selected, onClick }) => (
    <button
      type="button"
      onClick={onClick}
      className={`weekday-button ${selected ? "" : "selected"}`}
    >
      {label}
    </button>
  );

  return (
    <MainCard variant="minimal" flex>
      <Card title="Configuração de agendamento">
        <div className="schedule-config-sections">
          {/* Dias da Semana */}
          <Section
            icon={Calendar}
            title="Disponibilidade Semanal"
            description="Selecione os dias em que você está disponível para reuniões"
          >
            <div className="weekdays-grid">
              {weekDays.map((dia, index) => (
                <WeekDayButton
                  key={index}
                  day={index.toString()}
                  label={dia}
                  selected={selectedDays?.includes(index.toString())}
                  onClick={() => handleDaySelect(index)}
                />
              ))}
            </div>
            {/* <div className="schedule-config-weekdays">
              {Object.entries(dayLabels).map(([fullName, shortName]) => (
                <button
                  key={fullName}
                  onClick={() => {
                    const newWeekDays = formSchedules.values.weekDays.includes(
                      fullName
                    )
                      ? formSchedules.values.weekDays.filter(
                          (day) => day !== fullName
                        )
                      : [...formSchedules.values.weekDays, fullName];
                    formSchedules.setFieldValue("weekDays", newWeekDays);
                  }}
                  className={`schedule-config-weekday-button ${
                    formSchedules.values.weekDays.includes(fullName)
                      ? "schedule-config-weekday-selected"
                      : "schedule-config-weekday-unselected"
                  }`}
                >
                  {shortName}
                </button>
              ))}
            </div> */}
            {formSchedules.touched.weekDays &&
              formSchedules.errors.weekDays && (
                <div className="schedule-config-error">
                  {formSchedules.errors.weekDays}
                </div>
              )}
          </Section>

          {/* Horários */}
          <Section
            icon={Clock}
            title="Horários de Atendimento"
            description="Configure seu horário de trabalho"
          >
            <div className="schedule-config-grid-cols-2">
              <div className="schedule-config-input-group">
                <label className="schedule-config-label">
                  Horário de início
                </label>
                <input
                  type="time"
                  className="schedule-config-input"
                  value={formSchedules.values.startTime.format("HH:mm")}
                  onChange={(e) => {
                    const [hours, minutes] = e.target.value.split(":");
                    const newValue = moment().hours(hours).minutes(minutes);
                    formSchedules.setFieldValue("startTime", newValue);
                  }}
                />
                {formSchedules.touched.startTime &&
                  formSchedules.errors.startTime && (
                    <div className="schedule-config-error">
                      {formSchedules.errors.startTime}
                    </div>
                  )}
              </div>
              <div className="schedule-config-input-group">
                <label className="schedule-config-label">
                  Horário de término
                </label>
                <input
                  type="time"
                  className="schedule-config-input"
                  value={formSchedules.values.endTime.format("HH:mm")}
                  onChange={(e) => {
                    const [hours, minutes] = e.target.value.split(":");
                    const newValue = moment().hours(hours).minutes(minutes);
                    formSchedules.setFieldValue("endTime", newValue);
                  }}
                />
                {formSchedules.touched.endTime &&
                  formSchedules.errors.endTime && (
                    <div className="schedule-config-error">
                      {formSchedules.errors.endTime}
                    </div>
                  )}
              </div>
            </div>
          </Section>

          {/* Configurações de Reunião */}
          <Section
            icon={Clock}
            title="Configurações de Reunião"
            description="Defina a duração e intervalo entre as reuniões"
          >
            <div className="schedule-config-grid-cols-2">
              <div className="schedule-config-input-group">
                <label className="schedule-config-label">Duração padrão</label>
                <div className="schedule-config-input-with-unit">
                  <input
                    type="time"
                    className="schedule-config-time-input"
                    value={formSchedules.values.durationInMinutes.format(
                      "HH:mm"
                    )}
                    onChange={(e) => {
                      const [hours, minutes] = e.target.value.split(":");
                      const newValue = moment().hours(hours).minutes(minutes);
                      formSchedules.setFieldValue(
                        "durationInMinutes",
                        newValue
                      );
                    }}
                  />
                  <span className="schedule-config-input-unit">minutos</span>
                </div>
                {formSchedules.touched.durationInMinutes &&
                  formSchedules.errors.durationInMinutes && (
                    <div className="schedule-config-error">
                      {formSchedules.errors.durationInMinutes}
                    </div>
                  )}
              </div>
              <div className="schedule-config-input-group">
                <label className="schedule-config-label">
                  Intervalo entre reuniões
                </label>
                <div className="schedule-config-input-with-unit">
                  <input
                    type="time"
                    className="schedule-config-time-input"
                    value={formSchedules.values.intervalMinutes.format("HH:mm")}
                    onChange={(e) => {
                      const [hours, minutes] = e.target.value.split(":");
                      const newValue = moment().hours(hours).minutes(minutes);
                      formSchedules.setFieldValue("intervalMinutes", newValue);
                    }}
                  />
                  <span className="schedule-config-input-unit">minutos</span>
                </div>
                {formSchedules.touched.intervalMinutes &&
                  formSchedules.errors.intervalMinutes && (
                    <div className="schedule-config-error">
                      {formSchedules.errors.intervalMinutes}
                    </div>
                  )}
              </div>
            </div>
          </Section>

          {/* Modo Não Perturbe */}
          {/* <DoNotDisturb
              enabled={doNotDisturb}
              onEnabledChange={setDoNotDisturb}
              startTime="22:00"
              onStartTimeChange={() => {}}
              endTime="08:00"
              onEndTimeChange={() => {}}
              selectedDays={[]}
              onDaySelect={() => {}}
            /> */}
        </div>
      </Card>

      {/* Botões */}
      <Card>
        {/* <button
              onClick={formSchedules.handleSubmit}
              disabled={saveLoading}
              className="schedule-config-button"
            >
              {saveLoading ? (
                <div className="schedule-config-button-content">
                  <div className="schedule-config-loading-spinner" />
                  <span>Salvando...</span>
                </div>
              ) : (
                'Salvar Configurações'
              )}
            </button> */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={formSchedules.handleSubmit}
            disabled={saveLoading}
            style={{ marginTop: "32px" }}
            variant="filled"
          >
            {saveLoading ? "Salvando..." : "Salvar Configurações"}
          </Button>
        </div>
      </Card>
    </MainCard>
  );
};

export default ScheduleConfig;
