import React, { useEffect, useRef } from "react";

import arrow from "../../../../../assets/Vector.png";

import "./IdentitySection.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const IdentitySection = ({
  form,
  saveLoading,
  setMenuSelected,
  checkList,
  disabled,
  setCheckSave,
  scrollSimulator,
  user,
  setCheckList
}) => {
  const navigate = useNavigate();

  const navigateimulator = {
    menu: "Simulador do Atendente",
    index: 6,
  };

  const handleMenu = (item) => {
    if (
      item.menu === "Simulador do Atendente" &&
      !checkList.partnerDetailsFilled
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      navigate("/simulador");
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    console.log("Campo alterado:", name, value); // Adicione este log
    form.setFieldValue(name, value);
    setCheckSave(false);
  };

  const SettingsButton = () => {
    return (
      // <Tooltip title={"Configurações Automáticas"}>
      //   <IconButton
      //     style={{
      //       padding: "12px",
      //       position: "absolute",
      //       top: 0,
      //       right: 0,
      //       borderRadius: "0px 20px 0px 20px",
      //     }}
      //     onClick={() => {
      //       localStorage.setItem("@sacgpt:checkWizard", 'false');
      //       setCheckList({
      //         profileFilled: user?.profileFilled,
      //         partnerDetailsFilled: user?.partnerDetailsFilled,
      //         activePlan: user?.activePlan,
      //         wizard: false,
      //       });
      //       navigate("/configuracoes/automatica");
      //     }}
          
      //   >
      //     <Settings style={{ color: "#539d65" }} />
      //   </IconButton>
      // </Tooltip>
      <div
            className="containerButtons"
            style={{ justifyContent: "flex-end" }}
          >
            <div
             onClick={() => {
              localStorage.setItem("@sacgpt:checkWizard", 'false');
              setCheckList({
                profileFilled: user?.profileFilled,
                partnerDetailsFilled: user?.partnerDetailsFilled,
                activePlan: user?.activePlan,
                wizard: false,
              });
              navigate("/configuracoes/automatica");
            }}
              className="buttonMOdoWizard"
            >
              Entrar em modo automático
            </div>
            </div>
    );
  };

  return (
    <div className="containerIdentitySection" style={{ position: "relative" }}>
      {!disabled && <SettingsButton />}
      <div className="titlePage">Dados Básicos</div>

      <div className="containerInputs">
        {/* Coluna 1 - Dados básicos */}
        <div className="leftColumn">
          <div className="containerInput">
            <div className="title">Nome do/a Atendente</div>
            <div className="subTitleInput">
              Insira o nome do atendente responsável por interações com os
              clientes
            </div>
            <input
              className="inputStyle"
              type="text"
              name="nameAttendant"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.nameAttendant}
              disabled={disabled}
            />
            {form.touched.nameAttendant && form.errors.nameAttendant ? (
              <div style={{ color: "red" }}>{form.errors.nameAttendant}</div>
            ) : null}
          </div>

          <div className="containerInput">
            <div className="title">Nome Fantasia do Negócio</div>
            <div className="subTitleInput">
              Digite o nome que o atendente usará para se referir ao seu negócio
            </div>
            <input
              className="inputStyle"
              type="text"
              name="companyName"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.companyName}
              disabled={disabled}
            />
            {form.touched.companyName && form.errors.companyName ? (
              <div style={{ color: "red" }}>{form.errors.companyName}</div>
            ) : null}
          </div>

          <div className="containerInput">
            <div className="title">Nicho do Negócio</div>
            <div className="subTitleInput">
              Selecione o nicho que melhor representa seu negócio
            </div>
            <input
              className="inputStyle"
              type="text"
              name="companyNiche"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.companyNiche}
              disabled={disabled}
            />
            {form.touched.companyNiche && form.errors.companyNiche ? (
              <div style={{ color: "red" }}>{form.errors.companyNiche}</div>
            ) : null}
          </div>
        </div>

        {/* Coluna 2 - Instruções do Atendente */}
        <div className="rightColumn">
          <div className="containerInput instructionsInput">
            <div className="title">Instruções do Atendente</div>
            <div className="subTitleInput">
              É aqui que você dá vida para o seu atendente virtual
            </div>
            <textarea
              className="inputStyle"
              type="text"
              name="attendantInstructions"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.attendantInstructions}
              rows="12"
              disabled={disabled}
            />
            {form.touched.attendantInstructions &&
            form.errors.attendantInstructions ? (
              <div style={{ color: "red" }}>
                {form.errors.attendantInstructions}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {!disabled && (
        <div className="containerButtons">
          <div
            onClick={() => handleMenu(navigateimulator)}
            className={`buttonSimulator ${
              !checkList?.partnerDetailsFilled && "disabledMenus"
            }`}
          >
            Simular atendimento
          </div>

          <div onClick={form.handleSubmit} className="buttonSave">
            {saveLoading ? (
              "Carregando..."
            ) : (
              <>
                Salvar alterações
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default IdentitySection;
