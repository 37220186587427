import React, { useContext } from "react";
import "./Step1.css";
import { FluxoAddProviderContext } from "./FluxoAddProvider";
import { Info, Plus } from "@geist-ui/icons";
import Stepper from "./components/Stepper";
import Button from "../../../../components/Button";

const Step1 = () => {
  const {
    formSchedules,
    navigate,
    handleInput,
    insertParamIntoMessage,
    setCurrentStep,
    loading,
    saveLoading,
    handleInputParams,
  } = useContext(FluxoAddProviderContext);

  const defaultParams = [
    { id: 1, name: "NOME", example: "João Silva" },
    { id: 2, name: "EMPRESA", example: "Tech Corp" },
  ];

  const customParams = [
    { name: "param1", placeholder: "Parâmetro 1" },
    { name: "param2", placeholder: "Parâmetro 2" },
    { name: "param3", placeholder: "Parâmetro 3" },
    { name: "param4", placeholder: "Parâmetro 4" },
    { name: "param5", placeholder: "Parâmetro 5" },
  ];

  return (
    <>
      {loading ? (
        <div className="loading-container">
          <div className="loading-content">
            <div>Conectando...</div>
          </div>
        </div>
      ) : (
        <div className="container">
          <Stepper currentStep={1} />
          <div className="card">
            <div className="card-content">
              <div className="flex-container">
                {/* Coluna da Mensagem */}
                <div className="message-column">
                  <div className="input-group">
                    <label className="label">Título da Mensagem</label>
                    <input
                      className="input"
                      type="text"
                      name="title"
                      placeholder="Ex: Mensagem de Boas-vindas"
                      value={formSchedules.values.title || ""}
                      onChange={handleInput}
                    />
                  </div>

                  <div className="input-group">
                    <label className="label">Mensagem</label>
                    <div className="textarea-container">
                      <textarea
                        className="textarea"
                        name="message"
                        placeholder="Digite sua mensagem aqui... Use os parâmetros à direita para personalizar"
                        value={formSchedules.values.message || ""}
                        onChange={handleInput}
                      />
                      <div className="char-counter">
                        {(formSchedules.values.message || "").length} caracteres
                      </div>
                    </div>
                  </div>
                </div>

                {/* Coluna dos Parâmetros */}
                <div className="params-column">
                  <div className="params-container">
                    <div className="info-box">
                      <Info className="info-icon" />
                      <p className="info-text">
                        Clique em + para inserir o parâmetro na posição atual do
                        cursor
                      </p>
                    </div>

                    <div className="params-section">
                      <h3 className="params-title">Parâmetros Padrão</h3>
                      <div className="params-list">
                        {defaultParams.map((param) => (
                          <div key={param.id} className="param-item">
                            <div>
                              <p className="param-name">{`{${param.name}}`}</p>
                              <p className="param-example">
                                Ex: {param.example}
                              </p>
                            </div>
                            <button
                              className="param-button"
                              onClick={() => insertParamIntoMessage(param.name)}
                            >
                              <Plus className="plus-icon" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="params-section">
                      <h3 className="params-title">
                        Parâmetros Personalizados
                      </h3>
                      <div className="params-list">
                        <div className="custom-param-input">
                          {customParams.map((param, index) => (
                            <div key={param.id} className="param-item">
                              <input
                                className="input"
                                placeholder="Nome do parâmetro"
                                name={param.name}
                                value={
                                  param.value ||
                                  formSchedules.values[param.name]
                                }
                                onChange={handleInputParams}
                              />
                              <button
                                className="param-button"
                                onClick={() =>
                                  insertParamIntoMessage(param.name)
                                }
                              >
                                <Plus className="plus-icon" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Botões de Navegação */}
              <div className="navigation-buttons">
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Voltar
                </Button>

                <Button
                  variant="filled"
                  onClick={() => {
                    formSchedules.handleSubmit();
                    setCurrentStep("step2");
                  }}
                >
                  {saveLoading ? "Carregando..." : "Próximo"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Step1;
