import React, { useContext, useEffect, useState } from "react";
import { Card, Grid, Loading, useToasts } from '@geist-ui/core';
import { Edit2, Trash2, Copy } from '@geist-ui/icons';
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import Papa from "papaparse";
import "./MessageBroadcast.css";
import GeistTable, { TableBadge } from '../../../components/GeistTable/GeistTable';
import GeistPagination from '../../../components/GeistPagination/GeistPagination';
import GeistTableFilter from '../../../components/GeistTableFilter/GeistTableFilter';
import DeleteModal from "./DeleteModal";

const ListAutoMensagem = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { setToast } = useToasts();
  
  // Estados
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterKeys, setFilterKeys] = useState([
    { label: "ID", value: "id", className: "id" },
    { label: "Título", value: "title", className: "title" },
    { label: "Status", value: "status", className: "status" },
    { label: "Tipo de Disparo", value: "triggerType", className: "trigger-type" },
    { label: "Disparo 24hrs", value: "isFullDayDispatch", className: "full-day" },
    { label: "Data de Criação", value: "createdAt", className: "created-at" },
  ]);

  const getMessages = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("partners/automatic_messages");
      if (data.data) {
        setMessages(
          data.data.map((item) => ({
            ...item.attributes,
            id: Number(item.id),
            title: item.attributes?.title || "Título não informado",
            status: item.attributes?.status || "waiting",
            triggerType: item.attributes?.triggerType || "manual",
            isFullDayDispatch: item.attributes?.isFullDayDispatch || false,
            createdAt: item.attributes?.createdAt ? moment(item.attributes.createdAt).format("DD/MM/YYYY HH:mm") : "Data não informada",
          }))
        );
      }
    } catch (error) {
      setToast({ 
        text: "Não foi possível carregar a lista de mensagens.",
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMessages();
  }, []);

  const filteredData = messages.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const generateCSV = (data) => {
    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      Título: item?.title || "Título não informado",
      Status: item?.status === 'waiting' ? 'Aguardando' :
              item?.status === 'in_progress' ? 'Em andamento' :
              item?.status === 'finished' ? 'Concluído' : item?.status,
      TipoDisparo: item?.triggerType === 'manual' ? 'Manual' : 'Agendado',
      Disparo24hrs: item?.isFullDayDispatch ? 'Sim' : 'Não',
      DataCriação: item?.createdAt || "Data não informada",
    }));

    const xlsx = Papa.unparse(formattedData);
    const blob = new Blob([xlsx], { type: "text/xlsx;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "mensagens.xlsx");
    link.click();
  };

  const copyDisparo = async (value) => {
    try {
      await api.post(`partners/automatic_messages`, {
        data: {
          attributes: {
            title: value?.title || "",
            message: value?.message || "",
            param1: value?.param1 || "",
            param2: value?.param2 || "",
            param3: value?.param3 || "",
            param4: value?.param4 || "",
            param5: value?.param5 || "",
            triggerType: value?.triggerType || "",
            triggerTime: value?.triggerTime || "",
            dontDisturb: value?.dontDisturb || "",
            dontDisturbWeekDays:
              value?.dontDisturbWeekDays || "",
            dontDisturbDispatchStart:
              moment(value?.dontDisturbDispatchStart)
                .utc()
                .format("HH:mm:ss") || "",
            dontDisturbDispatchEnd:
              moment(value?.dontDisturbDispatchEnd)
                .utc()
                .format("HH:mm:ss") || "",
            automaticMessagePhonesAttributes: [],
          },
        },
      });
    } catch (error) {

    } finally {
      getMessages();
    }
  }

  return (
    <AuthorizedLayout>
      <div className="message-broadcast-container">
        <Card className="message-broadcast-card">
          <div className="message-broadcast-card-content">
            <Grid.Container gap={0}>
              <Grid xs={24} style={{ marginBottom: 'var(--spacing-lg)' }}>
                <GeistTableFilter 
                  searchTerm={searchTerm}
                  onSearchChange={(value) => setSearchTerm(value)}
                  onSearchClear={() => {
                    setSearchTerm("");
                  }}
                  filterKeys={filterKeys}
                  selectedFilters={filterKeys.map(k => k.value)}
                  onFilterChange={(values) => {
                    setFilterKeys(filterKeys.filter(item => values.includes(item.value)));
                  }}
                  onExport={generateCSV}
                  exportData={currentItems}
                  addButton={{
                    onClick: () => navigate('/disparador/create'),
                    label: 'Adicionar'
                  }}
                />
              </Grid>

              <Grid xs={24} style={{ marginTop: 0, width: '100%', display: 'flex' }}>
                {loading ? (
                  <div className="loading-container">
                    <Loading>Carregando mensagens...</Loading>
                  </div>
                ) : currentItems.length === 0 ? (
                  <div className="empty-state">
                    Nenhuma mensagem encontrada
                  </div>
                ) : (
                  <GeistTable
                    columns={[
                      {
                        field: 'id',
                        label: 'ID',
                        sortable: true
                      },
                      {
                        field: 'title',
                        label: 'Título',
                        sortable: true
                      },
                      {
                        field: 'status',
                        label: 'Status',
                        sortable: true,
                        render: (value) => (
                          <div className="scoreValue" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 'auto',
                            minWidth: '28px',
                            height: '28px',
                            backgroundColor: value === 'waiting' ? '#FFF8E1' : '#eafbe7',
                            color: value === 'waiting' ? '#F9A825' : '#539d65',
                            borderRadius: '50px',
                            fontWeight: 600,
                            fontSize: '14px',
                            padding: '0 12px'
                          }}>
                            {value === 'waiting' ? 'Aguardando' :
                             value === 'in_progress' ? 'Em andamento' :
                             value === 'finished' ? 'Concluído' : value}
                          </div>
                        )
                      },
                      {
                        field: 'triggerType',
                        label: 'Tipo de Disparo',
                        sortable: true,
                        render: (value) => (
                          <div className="scoreValue" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 'auto',
                            minWidth: '28px',
                            height: '28px',
                            backgroundColor: value === 'manual' ? '#E3F2FD' : '#E8F5E9',
                            color: value === 'manual' ? '#1976D2' : '#2E7D32',
                            borderRadius: '50px',
                            fontWeight: 600,
                            fontSize: '14px',
                            padding: '0 12px'
                          }}>
                            {value === 'manual' ? 'Manual' : 'Agendado'}
                          </div>
                        )
                      },
                      {
                        field: 'isFullDayDispatch',
                        label: 'Disparo 24hrs',
                        sortable: true,
                        render: (value) => (
                          <div className="scoreValue" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 'auto',
                            minWidth: '28px',
                            height: '28px',
                            backgroundColor: value ? '#E8F5E9' : '#FFEBEE',
                            color: value ? '#2E7D32' : '#C62828',
                            borderRadius: '50px',
                            fontWeight: 600,
                            fontSize: '14px',
                            padding: '0 12px'
                          }}>
                            {value ? 'Sim' : 'Não'}
                          </div>
                        )
                      },
                      {
                        field: 'createdAt',
                        label: 'Data de Criação',
                        sortable: true
                      },
                      {
                        field: 'actions',
                        label: 'Ações',
                        align: 'center',
                        render: (value, rowData) => (
                          <div className="action-buttons">
                            <Edit2 
                              size={18} 
                              className="geist-table-action-icon" 
                              onClick={() => navigate(`/disparador/edite/${rowData.id}`)} 
                            />
                             <Copy 
                              size={18} 
                              className="geist-table-action-icon" 
                              onClick={() => copyDisparo(rowData)}
                            />
                            <DeleteModal item={rowData} getClients={getMessages} />
                          </div>
                        )
                      }
                    ]}
                    data={currentItems}
                    loading={loading}
                    emptyText="Nenhuma mensagem encontrada"
                    className="geist-table"
                  />
                )}
              </Grid>

              <Grid xs={24}>
                <GeistPagination 
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                />
              </Grid>
            </Grid.Container>
          </div>
        </Card>
      </div>
    </AuthorizedLayout>
  );
};

export default ListAutoMensagem;
