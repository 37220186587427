import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import './MonthSelector.css';

const MonthSelector = ({ selectedMonth, selectedYear, onChange }) => {
  moment.locale('pt-br');
  
  // Get current year and create a range of years (current year - 2 to current year + 1)
  const currentYear = moment().year();
  const years = Array.from(
    { length: 4 },
    (_, i) => currentYear - 2 + i
  );

  const months = moment.months().map((month, index) => ({
    value: index + 1,
    label: month.charAt(0).toUpperCase() + month.slice(1)
  }));

  const handleChange = (e) => {
    const [month, year] = e.target.value.split('-');
    onChange(parseInt(month), parseInt(year));
  };

  return (
    <select 
      className="month-selector"
      value={`${selectedMonth}-${selectedYear}`}
      onChange={handleChange}
    >
      {years.map(year => (
        months.map(month => (
          <option 
            key={`${month.value}-${year}`} 
            value={`${month.value}-${year}`}
          >
            {`${month.label} - ${year}`}
          </option>
        ))
      ))}
    </select>
  );
};

export default MonthSelector;
