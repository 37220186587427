import React, { useContext } from "react";
import "./Step4.css";
import { FluxoAddProviderContext } from "./FluxoAddProvider";
import { Calendar, Mail, MessageSquare, User } from "@geist-ui/icons";
import Stepper from "./components/Stepper";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";

const Section = ({ icon: Icon, title, children }) => (
  <div className="section-container">
    <div className="section-header">
      <div className="icon-container">
        <Icon className="section-icon" />
      </div>
      <h3 className="section-title">{title}</h3>
    </div>
    {children}
  </div>
);

const Step4 = () => {
  const { formSchedules, setCurrentStep, phoneNumbers, loading, sumary } =
    useContext(FluxoAddProviderContext);

  const navigate = useNavigate();

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return (
      d.toLocaleDateString("pt-BR") +
      " às " +
      d.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" })
    );
  };

  const formatDays = (days) => {
    const weekDays = [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ];
    return (
      days?.map((day) => weekDays[parseInt(day)]).join(", ") ||
      "Nenhum dia selecionado"
    );
  };

  const firstTenNumbers = sumary?.automaticMessagePhones.slice(0, 12);

  const remainingCount = sumary.automaticMessagePhonesCount - 12;

  return (
    <div className="container">
      <Stepper currentStep={4} />
      <div className="card">
        <div className="card-content">
          <div className="review-content">
            {/* Cabeçalho */}
            <div className="review-header">
              <h2 className="review-title">Revise suas configurações</h2>
              <p className="review-subtitle">
                Confira todos os detalhes antes de iniciar o envio
              </p>
            </div>

            <div className="sections-grid">
              {/* Mensagem */}
              <Section icon={MessageSquare} title="Mensagem">
                <div className="info-box">
                  <div className="info-title">
                    {formSchedules.values.title || "Sem título"}
                  </div>
                  <p className="info-text">
                    {formSchedules.values.message || "Sem mensagem"}
                  </p>
                </div>
              </Section>

              {/* Contatos */}
              <Section icon={User} title="Contatos">
                <div className="info-box">
                  <div className="info-row">
                    <div className="info-label">
                      Total de contatos para envio
                    </div>
                    <div className="info-value">
                      {sumary?.automaticMessagePhonesCount} contatos
                    </div>
                  </div>
                </div>

                {/* <div className="containerNumber">
                    {firstTenNumbers?.map((number, index) => (
                      <div
                        key={index}
                        className="textNumber"
                        style={{
                          opacity: 1 - index * 0.05,
                        }}
                      >
                        <img
                          src={phoneIcon}
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                        {number.phone}
                      </div>
                    ))}
                  </div>

                  {remainingCount > 0 && (
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={userIcon}
                        alt=""
                        style={{ width: "24px", height: "24px" }}
                      />
                      <div
                        className="title"
                        style={{
                          margin: "0px",
                          fontSize: "16px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        E mais{" "}
                        <span style={{ color: "#77E191" }}>
                          {remainingCount}
                        </span>{" "}
                        contatos
                      </div>
                    </div>
                  )} */}
              </Section>

              {/* Agendamento */}
              <Section icon={Calendar} title="Configurações de Envio">
                <div className="settings-group">
                  <div className="info-box">
                    <div className="info-row">
                      <span>Tipo de envio</span>
                      <span className="info-value">
                        {formSchedules.values.triggerType === "manual"
                          ? "Imediato"
                          : "Agendado"}
                      </span>
                    </div>
                    {formSchedules.values.triggerType === "scheduled" && (
                      <div className="info-detail">
                        {formatDate(formSchedules.values.triggerTime)}
                      </div>
                    )}
                  </div>

                  {formSchedules.values.dontDisturb && (
                    <div className="info-box">
                      <div className="info-row">
                        <span>Não Perturbe</span>
                        <span className="info-value">Ativado</span>
                      </div>
                      <div className="info-detail">
                        <div>
                          {new Date(
                            formSchedules.values.dontDisturbDispatchStart
                          ).toLocaleTimeString("pt-BR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                          às{" "}
                          {new Date(
                            formSchedules.values.dontDisturbDispatchEnd
                          ).toLocaleTimeString("pt-BR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </div>
                        <div>
                          {formatDays(formSchedules.values.dontDisturbWeekDays)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Section>
            </div>

            {/* Botões de Ação */}
            <div className="navigation-buttons">
              <Button
                variant="outline"
                onClick={() => {
                  setCurrentStep("step3");
                }}
              >
                Voltar e Editar
              </Button>

              <Button
                variant="filled"
                onClick={() => {
                  formSchedules.handleSubmit();
                  navigate(-1);
                  // setCurrentStep("step4");
                  // handleMenu({ menu: "Revisão", index: 6 });
                }}
                icon={<Mail />}
              >
                {formSchedules.values.triggerType === "manual"
                  ? "Iniciar Envios"
                  : "Iniciar Agendamento"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
