import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import logo from "../../../assets/altLogo.svg";

import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import api from "../../../utils/api";
import FormInput from "../../../components/FormInput";
import './FormInput.css';

export const RecoveryNewPassword = () => {
  const [loading, setLoading] = React.useState(false);
  const { hash } = useParams();
  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },

    validationSchema: yup.object({
      newPassword: yup
        .string()
        .min(8, "A nova senha deve conter no mínimo 8 caracteres.")
        .required("Nova senha é obrigatória!"),

      passwordConfirmation: yup
        .string()
        .required("Confirmação da nova senha é obrigatoria!")
        .oneOf(
          [yup.ref("newPassword"), ""],
          "As novas senhas devem ser iguais."
        ),
    }),

    onSubmit: (values) => handleContinue(values),
  });
  const handleContinue = async (values) => {
    try {
      setLoading(true);

      const { data } = await api.patch(`/partners/recover_password/${hash}`, {
        data: {
          attributes: {
            password: values.newPassword,
            password_confirmation: values.passwordConfirmation,
          },
        },
      });
      if (data) {
        toast.success("Recuperação de senha realizada com sucesso!");
        navigate("/");
      }

      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu um erro ao realizar a recuperação da senha.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "#f1fcf4",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={logo}
        alt={""}
        style={{ width: 600, marginBottom: "-50px", paddingRight: "15px" }}
      />
      <Typography
        color={"#122137"}
        fontSize={24}
        fontWeight={600}
        style={{
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          marginTop: "-50px",
          marginBottom: "15px",
        }}
      >{`Recuperação de Conta`}</Typography>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginBottom: "15%",
          width: "375px",
        }}
      >
        <form
          onSubmit={form.handleSubmit}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <FormInput
            validate
            label="Nova Senha"
            fieldName="newPassword"
            form={form}
            type="password"
            className="migrated-form-input"
            width="100%"
            placeholder="Digite sua nova senha"
          />
          <FormInput
            validate
            label="Confirmação da Nova Senha"
            fieldName="passwordConfirmation"
            form={form}
            type="password"
            className="migrated-form-input"
            width="100%"
            placeholder="Confirme sua nova senha"
          />
          <Button variant="contained" type="submit">
            <Typography color={"#fff"}>
              {loading ? "Carregando..." : "Entrar"}
            </Typography>
          </Button>
        </form>
      </div>
    </Box>
  );
};
