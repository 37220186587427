import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";

import arrow from "../../../../../assets/Vector.png";
import iconWhats from "../../../../../assets/Vector (21).png";

import "./Step2.css";
import moment from "moment";
import api from "../../../../../utils/api";
import { toast } from "react-toastify";
import { CheckContext } from "./CheckProvider";

const Step2 = ({ saveLoading, menuSelected, sectionSimulator }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingMenssage, setLoadingMessage] = useState(false);
  const [plans, setPlans] = useState([]);
  const [modalWarning, setModalWarning] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const { setCurrentStep } = useContext(CheckContext);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const formatDate = (dateStr) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Cria o objeto Date usando o formato ISO para evitar problemas de fuso horário
    const inputDate = new Date(dateStr);

    // Ajusta a data para o fuso horário local
    const localInputDate = new Date(
      inputDate.toLocaleString("en-US", { timeZone: "UTC" })
    );

    // Define a data de hoje ajustada para o fuso horário local
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Define uma função para comparar datas sem considerar a hora
    const isSameDay = (d1, d2) => {
      return d1.toDateString() === d2.toDateString();
    };

    if (isSameDay(localInputDate, today)) {
      return "hoje";
    }

    return localInputDate.toLocaleDateString("pt-BR", options);
  };

  const toggleObjective = async () => {
    if (newMessage?.trim()) {
      const text = newMessage;
      try {
        setNewMessage("");
        const { data } = await api.post(`/partners/partner_test_bot/messages`, {
          data: {
            attributes: {
              message: text,
            },
          },
        });
        if (data) {
          // console.log(data);
          setMessages([
            ...messages,
            {
              date: moment(data.data?.attributes?.createdAt).format(
                "YYYY-MM-DD"
              ),
              time: moment(data.data?.attributes?.createdAt).format("hh:mm A"),
              send: data.data?.attributes?.message || "",
              received: data.data?.attributes?.automaticResponse || "",
              createdAt: data.data?.attributes?.createdAt,
            },
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const groupedMessages = messages.reduce((group, msg) => {
    const { date } = msg;
    if (!group[date]) {
      group[date] = [];
    }
    group[date].push(msg);
    return group;
  }, {});

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
    setLoadingMessage(false);
  }, [messages]);

  useEffect(() => {
    if (loadingMenssage) {
      scrollToBottom();
    }
  }, [loadingMenssage]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`partners/payment_plans`);
      console.log(data);
      if (data) {
        setPlans(
          data.data
            .filter((item) => !item.attributes.disable)
            .map((item) => {
              return {
                title: item.attributes.name,
                tokens: item?.attributes?.maxTokenCount,
              };
            })
        );
        //console.log(data.data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados do parceiro.");
    } finally {
      setLoading(false);
    }
  };

  const getChatHistory = async () => {
    try {
      const { data } = await api.get(`/partners/partner_test_bot/messages`);
      if (data?.data) {
        setMessages(
          data?.data?.map((item) => {
            return {
              date: moment(item?.attributes?.createdAt).format("YYYY-MM-DD"),
              time: moment(item?.attributes?.createdAt).format("hh:mm A"),
              send: item?.attributes?.message || "",
              received: item?.attributes?.automaticResponse || "",
              createdAt: item?.attributes?.createdAt,
            };
          })
        );
        // console.log(data.data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar as mensagens.");
    } finally {
    }
  };

  useEffect(() => console.log(messages), [messages]);

  const fetchRef = useRef();

  useEffect(() => {
    const getLatestMessage = async () => {
      try {
        const { data } = await api.get(
          `/partners/partner_test_bot/last_message`
        );
        if (data?.data) {
          const now = moment();
          const createdAt = moment(data.data?.attributes?.createdAt);
          const timeDifference = now.diff(createdAt, "minutes");

          if (
            data.data?.attributes?.automaticResponse === null &&
            data.data?.attributes?.message !== null &&
            timeDifference < 1
          ) {
            setLoadingMessage(true);
          } else if (timeDifference > 1) {
            setLoadingMessage(false);
          }

          if (timeDifference > 1) {
            setLoadingMessage(false);
          }

          if (
            data.data?.attributes?.createdAt ===
            messages[messages.length - 1]?.createdAt
          ) {
            console.log("equal timestamp");
            if (
              (data.data?.attributes?.automaticResponse || "") !==
              messages[messages.length - 1]?.received
            ) {
              console.log("different responses");
              setMessages((prevMessages) => {
                const newArray = [...prevMessages];
                newArray[newArray.length - 1] = {
                  date: moment(data.data?.attributes?.createdAt).format(
                    "YYYY-MM-DD"
                  ),
                  time: moment(data.data?.attributes?.createdAt).format(
                    "hh:mm A"
                  ),
                  send: data.data?.attributes?.message || "",
                  received: data.data?.attributes?.automaticResponse || "",
                  createdAt: data.data?.attributes?.createdAt,
                };

                return newArray;
              });
            }
          } else {
            console.log(
              "different timestamp",
              `timestamp 1: ${data.data?.attributes?.createdAt}, timestamp 2: ${
                messages[messages.length - 1]?.createdAt
              }`
            );
            console.log("messages -> ", messages);
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                date: moment(data.data?.attributes?.createdAt).format(
                  "YYYY-MM-DD"
                ),
                time: moment(data.data?.attributes?.createdAt).format(
                  "hh:mm A"
                ),
                send: data.data?.attributes?.message || "",
                received: data.data?.attributes?.automaticResponse || "",
                createdAt: data.data?.attributes?.createdAt,
              },
            ]);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    fetchRef.current = getLatestMessage;
  }, [messages]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchRef.current();
    }, 5000);

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getData();
    getChatHistory();
  }, []);

  const resetChat = async () => {
    setLoadingReset(true);
    try {
      await api.delete(`/partners/partner_test_bot/destroy_all_messages`);
      toggleObjective();
      // getLatestMessage();
      getChatHistory();
      setModalWarning(false);
    } catch (error) {
    } finally {
      setLoadingReset(false);
    }
  };

  return (
    <div className="containerAllchatCheck">
      <div className="title">Pronto! Teste Seu Assistente! </div>
      <div className="containerStep2Check" ref={sectionSimulator}>
        <div className="chat-container">
          <div style={{ width: "100%" }}>
            <div className="chat-box">
              <div className="containerGroupMensagens">
                {messages.length <= 0 && (
                  <div className="dateHeader">
                    Envie uma mensagem para iniciar a sua conversa!
                  </div>
                )}

                {/* {messages.length > 0 && loadingMenssage && (
              <div
                className="dateHeader"
                style={{ position: "absolute", top: "30px", zIndex: 6 }}
              >
                Digitando<span className="typing">.</span>
                <span className="typing2">.</span>
                <span className="typing3">.</span>
              </div>
            )} */}

                <div className="containerAllBoxMessages" ref={chatContainerRef}>
                  {Object.keys(groupedMessages).map((date, index) => (
                    <div key={index} className="containerBoxMenssagens">
                      <div className="dateHeader">{formatDate(date)}</div>
                      {groupedMessages[date].map((item, msgIndex) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "30px",
                            width: "100%",
                          }}
                        >
                          {item.send !== "" && (
                            <div className="message sent">
                              <div>{item.send}</div>
                              <span className="time">{item.time}</span>
                            </div>
                          )}

                          {item.received !== "" && (
                            <div className="message received">
                              <div>{item.received}</div>
                              <span className="time">{item.time}</span>
                            </div>
                          )}
                        </div>
                      ))}
                      {messages.length > 0 && loadingMenssage && (
                        <div className="message received">
                          <div style={{ minHeight: "36px" }}>
                            <span className="typing">{""}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <form
                className="containerIputMenssage"
                onSubmit={(e) => {
                  e.preventDefault();
                  toggleObjective();
                }}
              >
                <input
                  type="text"
                  placeholder="Digite uma mensagem..."
                  className="inputMenssage"
                  onChange={(event) => setNewMessage(event.target.value)}
                  value={newMessage}
                  // style={{ borderRadius: '20px'}}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleObjective();
                  }}
                >
                  <img src={arrow} alt="arrow" />
                </button>
              </form>
            </div>

            {/* <div
            className="containerButtons"
            style={{ justifyContent: "flex-end" }}
          >
            <div
              onClick={() => {
                setModalWarning(true);
              }}
              className="buttonReset"
              style={{
                backgroundColor: "white",
                border: "2px solid red",
                color: "#102649",
                marginTop: "20px",
              }}
            >
              Reiniciar conversa
            </div>
          </div> */}
          </div>

          <div className="headerChatCheck" style={{ gap: "20px" }}>
            <div className="title">Seu assistente está pronto! </div>
            <div className="subtitle">
              Vincule seu WhatsApp agora, e comece a receber mensagens dos seus
              clientes.
            </div>
            <div className="subtitle">Vamos vincular seu whatsapp?</div>
            <div className="subtitle">
              <strong>Lembre-se:</strong> No dashboard, você terá controle total
              para refinar o conteúdo e definir como seu atendente vai interagir
              com seus clientes.
            </div>
            <button onClick={() => setCurrentStep("step3")}>
              <img src={iconWhats} alt="" />
              Vincular conta de WhatsApp
            </button>

            <button
              onClick={() => {
                setModalWarning(true);
              }}
              // className="buttonReset"
              style={{
                backgroundColor: "white",
                border: "1px solid red",
                color: "#102649",
              }}
            >
              Reiniciar conversa
            </button>

            <button
              onClick={() => setCurrentStep("step1")}
              className="buttonBackCheck"
            >
              Voltar
            </button>
          </div>
        </div>

        {modalWarning && (
          <Modal
            open
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setModalWarning("false");
              }
            }}
          >
            <Box
              style={{
                background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                borderRadius: "20px",
              }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: isMobileDevice() ? 350 : 700,
                // height: 200,
                p: 2.5,
              }}
            >
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      margin: 0,
                      fontFamily: [
                        '"Roboto"',
                        '"Helvetica"',
                        '"Arial"',
                        "sans-serif",
                      ],
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.0075em",
                      alignSelf: "flex-start",
                      marginTop: "15px",
                      marginBottom: "15px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={28}
                      fontWeight={600}
                      color={"#e52207"}
                    >
                      ATENÇÃO
                    </Typography>
                  </Typography>

                  <Typography
                    textAlign={"center"}
                    fontWeight={"600"}
                    style={{ marginBottom: "25px" }}
                    fontSize={20}
                  >
                    {
                      "O histórico de conversa atual será perdido e a contagem de tokens da conversa será reiniciada. No entanto, os tokens já consumidos não serão repostos."
                    }
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                        backgroundColor: "#e52207",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        resetChat();
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {loadingReset ? "APAGANDO..." : "APAGAR"}
                      </Typography>
                    </Button>

                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        setModalWarning(false);
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {"CANCELAR"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Step2;
