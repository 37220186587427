import React, { useState, useContext, useEffect } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import * as yup from "yup";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import validateCPF from "../../../utils/validateCPF";
import validateCNPJ from "../../../utils/validateCNPJ";
import unmask from "../../../utils/unmask";

import editIcon from "../../../assets/Frame 1 (1).png";
import arrow from "../../../assets/Vector.png";
import iconUser from "../../../assets/noAvatar.svg";

import "./Profile.css";
import MainCard from "../../../components/MainCard";
import Card from "../../../components/Card";
import { Modal, Text } from "@geist-ui/core";
import FormInput from "../../../components/FormInput";
import FormSelect from "../../../components/FormInput/FormSelect";
import Button from "../../../components/Button";

const Section = ({ title, children, isOpen, onToggle, description }) => {
  return (
    <div className="settingsSection">
      <button type="button" onClick={onToggle} className="sectionHeader">
        <div>
          <span className="title">{title}</span>
          {description && <span className="description">{description}</span>}
        </div>
        <span className="arrow">{isOpen ? "▼" : "▶"}</span>
      </button>
      {isOpen && <div className="sectionContent">{children}</div>}
    </div>
  );
};

const Profile = () => {
  const {
    user,
    updateUser,
    updateAffiliated,
    signOut,
    checkComplete,
    checkList,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState(false);
  const [menuSelected, setMenuSelected] = useState({
    menu: "Dados Básicos",
    index: 0,
  });

  const handleDigit = (input) => {
    if (input?.length >= 11 && !input.startsWith("55")) {
      let num;
      num = `55${input}`;
      return num;
    }
    return input;
  };

  const handleDigitAPI = (input) => {
    if (input.startsWith("55")) {
      let num;
      num = input.slice(2);
      return num;
    }
    return input;
  };

  const [tempPhone, setTempPhone] = useState(handleDigit(user.contactNumber));

  const form = useFormik({
    initialValues: {
      name: user.name,
      phone: parsePhoneAdmin(user.contactNumber),
      email: user.email,
      cpf: user?.document?.length
        ? user?.document?.length === 11
          ? user?.document
          : ""
        : "",
      cnpj: user?.document?.length
        ? user?.document?.length === 11
          ? ""
          : user?.document
        : "",
      documentType: user.document?.length
        ? user.document?.length === 11
          ? "cpf"
          : "cnpj"
        : "",

      bankCode: user.paymentAccount?.bankCode || "",
      agNumber: user.paymentAccount?.agNumber || "",
      agDv: user.paymentAccount?.agDv || "",
      accNumber: user.paymentAccount?.accNumber || "",
      accDv: user.paymentAccount?.accNumber || "",
      accountType: user.paymentAccount?.accountType || "",
    },

    validationSchema: yup.object({
      name: yup.string().required("Nome é obrigatório!"),
      phone: yup
        .string()
        .min(14, "Telefone inválido!")
        .required("Telefone de contato é obrigatório!"),

      // cpf: yup.string().when("documentType", {
      //   is: "cpf",
      //   then: () =>
      //     yup
      //       .string()
      //       .required("Número do documento é obrigatório!")
      //       .test({
      //         name: "isValid",
      //         exclusive: false,
      //         params: {},
      //         message: "CPF inválido",
      //         test: function (value) {
      //           return validateCPF(value || "");
      //         },
      //       }),
      // }),

      // cnpj: yup.string().when("documentType", {
      //   is: "cnpj",
      //   then: () =>
      //     yup
      //       .string()
      //       .required("Número do documento é obrigatório!")
      //       .test({
      //         name: "isValid",
      //         exclusive: false,
      //         params: {},
      //         message: "CNPJ inválido",
      //         test: function (value) {
      //           return validateCNPJ(value || "");
      //         },
      //       }),
      // }),

      documentType: yup
        .string()
        .required("Escolher um tipo de documento é obrigatório!"),
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => updateSettings(values),
  });

  const bankForm = useFormik({
    initialValues: {
      id: null,
      bankCode: "",
      agNumber: "",
      accNumber: "",
      accountType: "",
      responsible: "",
      document: "",
      pix: "",
    },

    onSubmit: (values) => updateBankData(values),
  });

  const updateSettings = async (values) => {
    setSaveLoading(true);

    const dataUser = values.avatar
      ? {
          data: {
            attributes: {
              name: values.name.trim(),
              // avatar: { data: values.avatar },
              // companyName: values.companyName.trim(),
              contact_number: handleDigitAPI(unmask(values.phone.trim())),
              document:
                values.documentType === "cpf"
                  ? unmask(values.cpf)
                  : values.documentType === "cnpj"
                  ? unmask(values.cnpj)
                  : null,
              password: values.newPassword ? values.newPassword : null,
              passwordConfirmation: values.passwordConfirmation
                ? values.passwordConfirmation
                : null,
            },
          },
        }
      : {
          data: {
            attributes: {
              name: values.name.trim(),
              // companyName: values.companyName.trim(),
              contact_number: handleDigitAPI(unmask(values.phone.trim())),
              document:
                values.documentType === "cpf"
                  ? unmask(values.cpf)
                  : values.documentType === "cnpj"
                  ? unmask(values.cnpj)
                  : null,
              password: values.newPassword ? values.newPassword : null,
              passwordConfirmation: values.passwordConfirmation
                ? values.passwordConfirmation
                : null,
            },
          },
        };

    try {
      const { data } = await api.put(`/affiliates/affiliates/${user.id}`, {
        ...dataUser,
      });

      if (data) {
        updateAffiliated(data);
        toast.success("Dados da conta atualizados com sucesso!");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.errors[0]?.title?.message) {
        toast.error(
          `${error?.response?.data?.errors[0]?.title?.message} Por favor entre em contato com o suporte.`
        );
      } else
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
    } finally {
      setSaveLoading(false);
    }
  };

  const updateBankData = async (values) => {
    setSaveLoading(true);

    try {
      if (values.id) {
        const { data } = await api.put(
          `/affiliates/affiliate_bank_details/${values.id}`,
          {
            data: {
              attributes: {
                responsible: values?.responsible,
                document_number: unmask(values?.document),
                bank_code: values?.bankCode,
                agency: values?.agNumber,
                account: values?.accNumber,
                account_type: values?.accountType === "corrente" ? 0 : 1,
                pix_code: values?.pix,
              },
            },
          }
        );
        if (data) {
          // updateAffiliated(data);
          toast.success("Dados bancários atualizados com sucesso!");
        }
      } else {
        const { data } = await api.post(
          `/affiliates/affiliate_bank_details/${user.id}`,
          {
            data: {
              attributes: {
                responsible: values?.responsible,
                document_number: unmask(values?.document),
                bank_code: values?.bankCode,
                agency: values?.agNumber,
                account: values?.accNumber,
                account_type: values?.accountType === "corrente" ? 1 : 2,
                pix_code: values?.pix,
              },
            },
          }
        );
        if (data) {
          // updateAffiliated(data);
          toast.success("Dados bancários atualizados com sucesso!");
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar os dados bancários.");
    } finally {
      setSaveLoading(false);
    }
  };
  const getBankData = async () => {
    try {
      const { data } = await api.get(
        `/affiliates/affiliate_bank_details/${user.id}`
      );
      console.log(data);
      if (data?.data) {
        // updateAffiliated(data);
        bankForm.setValues({
          id: data.data.id,
          accNumber: data.data.attributes.account,
          accountType:
            data.data.attributes.accountType === "conta_poupanca"
              ? "poupanca"
              : "corrente",
          agNumber: data.data.attributes.agency,
          bankCode: data.data.attributes.bankCode,
          document: data.data.attributes.documentNumber,
          responsible: data.data.attributes.responsible,
          pix: data.data.attributes.pixCode,
        });
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar carregar os dados bancários.");
    } finally {
    }
  };

  useEffect(() => {
    getBankData();
  }, []);

  function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return cnpj;
  }

  const getFirstName = (fullName) => {
    if (!fullName) return ""; // Verifica se a string é vazia ou null
    const names = fullName.split(" ");
    if (names.length < 2) return names[0]; // Verifica se há pelo menos dois nomes
    return `${names[0]} ${names[1]}`;
  };

  useEffect(() => {
    setTempPhone(form.values.phone);
  }, [form.values.phone]);

  const deleteAccount = async () => {
    setLoadingDeleteAccount(true);
    try {
      await api.delete(`/affiliates/affiliates/${user.id}`);
      setModalWarning(false);
      signOut(0);
    } catch (error) {
    } finally {
      setLoadingDeleteAccount(false);
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        // console.log("File Is", file);
        form.setFieldValue(`avatar`, result);
      })
      .catch((error) => {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(`${error.response.data.errors[0].title}`);
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      });
  };

  const [openSections, setOpenSections] = useState({
    dados: false,
    bank: false,
  });



  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <AuthorizedLayout>
      <MainCard variant="minimal" flex>
        <Card>
          {/* <div className="profile-avatar-container">
                    <Avatar
                      src={form.values.avatar || user?.avatar || ""}
                      text={getFirstName(form.values.name)}
                      width="50px"
                      height="50px"
                    />
                    <label htmlFor="fupload" className="profile-avatar-edit">
                      <Edit size={20} />
                    </label>
                    <input
                      type="file"
                      id="fupload"
                      name="fupload"
                      style={{ display: 'none' }}
                      accept=".jpeg, .png, .jpg"
                      onChange={handleFileInputChange}
                    />
                  </div> */}
          {/* <Text h3>{getFirstName(form.values.name)}</Text> */}
          <Text b>E-mail:</Text> <Text>{form.values.email}</Text>
          <Text b>Telefone:</Text> <Text>{form.values.phone}</Text>
          <Text b>CPF/CNPJ:</Text>
          <Text>
            {form.values.documentType === "cpf"
              ? formatCPF(form.values.cpf) || ""
              : formatCNPJ(form.values.cnpj) || ""}
          </Text>
          {/* <Button
          // icon={<ArrowRight />}
          onClick={() => navigate("/financeiro/step1")}
        >
          Alterar Plano
        </Button> */}
        </Card>

        {modalWarning && (
          <Modal visible={modalWarning} onClose={() => setModalWarning(false)}>
            <Modal.Title>ATENÇÃO</Modal.Title>
            <Modal.Content>
              <Text>
                Tem certeza de que deseja excluir sua conta? Esta ação é
                permanente e não pode ser desfeita.
              </Text>
            </Modal.Content>
            <Modal.Action passive onClick={() => setModalWarning(false)}>
              Cancelar
            </Modal.Action>
            <Modal.Action
              onClick={deleteAccount}
              loading={loadingDeleteAccount}
            >
              {loadingDeleteAccount ? "Excluindo..." : "Excluir"}
            </Modal.Action>
          </Modal>
        )}

        <Card>
          <Text h3></Text>
            <Section
              title="Dados Básicos"
              isOpen={openSections.dados}
              onToggle={() => toggleSection("dados")}
            >
        <form onSubmit={form.handleSubmit} >
              <FormInput
                label="Nome"
                name="name"
                value={form.values.name}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={
                  form.touched.name && form.errors.name
                    ? form.errors.name
                    : null
                }
                validate={form.touched.name && form.errors.name}
                placeholder="Digite seu nome completo"
                width="100%"
              />

              <FormInput
                label="E-mail"
                name="email"
                value={form.values.email}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={
                  form.touched.email && form.errors.email
                    ? form.errors.email
                    : null
                }
                validate={form.touched.email && form.errors.email}
                htmlType="email"
                placeholder="exemplo@email.com"
                width="100%"
              />

              <FormInput
                label="Telefone"
                name="phone"
                value={form.values.phone}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={
                  form.touched.phone && form.errors.phone
                    ? form.errors.phone
                    : null
                }
                validate={form.touched.phone && form.errors.phone}
                mask={"+99 99 99999-9999"}
                placeholder="(00) 00000-0000"
                width="100%"
              />

              <FormSelect
                label="Tipo de Documento"
                select
                value={form.values.documentType}
                onChange={(value) => form.setFieldValue("documentType", value)}
                width="100%"
                options={[
                  { label: "CPF", value: "cpf", disabled: false },
                  { label: "CNPJ", value: "cnpj", disabled: false },
                ]}
              />

              <FormInput
                fieldName={form.values.documentType === "cpf" ? "cpf" : "cnpj"}
                label={form.values.documentType === "cpf" ? "CPF" : "CNPJ"}
                name={form.values.documentType === "cpf" ? "cpf" : "cnpj"}
                value={
                  form.values.documentType === "cpf"
                    ? form.values.cpf
                    : form.values.cnpj
                }
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={
                  form.touched[form.values.documentType] &&
                  form.errors[form.values.documentType]
                    ? form.errors[form.values.documentType]
                    : null
                }
                validate={
                  form.touched[form.values.documentType] &&
                  form.errors[form.values.documentType]
                }
                mask={
                  form.values.documentType === "cpf"
                    ? "999.999.999-99"
                    : form.values.documentType === "cnpj"
                    ? "99.999.999/9999-99"
                    : ""
                }
                placeholder={
                  form.values.documentType === "cpf"
                    ? "000.000.000-00"
                    : "00.000.000/0000-00"
                }
                form={form}
                width="100%"
              />

              <FormInput
                label="Nova Senha"
                name="newPassword"
                value={form.values.newPassword}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={
                  form.touched.newPassword && form.errors.newPassword
                    ? form.errors.newPassword
                    : null
                }
                validate={form.touched.newPassword && form.errors.newPassword}
                type="password"
                helperText="Deixe em branco para manter a senha atual"
                width="100%"
              />

              <FormInput
                label="Confirmar Nova Senha"
                name="passwordConfirmation"
                value={form.values.passwordConfirmation}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={
                  form.touched.passwordConfirmation &&
                  form.errors.passwordConfirmation
                    ? form.errors.passwordConfirmation
                    : null
                }
                validate={
                  form.touched.passwordConfirmation &&
                  form.errors.passwordConfirmation
                }
                type="password"
                width="100%"
              />

              <Button htmlType="submit" loading={saveLoading}>
                {saveLoading ? "Salvando..." : "Salvar alterações"}
              </Button>
          </form>
            </Section>

            <Section
              title="Dados Bancários"
              isOpen={openSections.bank}
              onToggle={() => toggleSection("bank")}
            >
          <form onSubmit={bankForm.handleSubmit}>
              <FormInput
                label="Código do Banco"
                name="bankCode"
                value={bankForm.values.bankCode}
                onChange={bankForm.handleChange}
                onBlur={bankForm.handleBlur}
                error={
                  bankForm.touched.bankCode && bankForm.errors.bankCode
                    ? bankForm.errors.bankCode
                    : null
                }
                validate={bankForm.touched.bankCode && bankForm.errors.bankCode}
                placeholder="Digite o código identificador do serviço bancário utilizado"
                width="100%"
              />

              <FormInput
                label="Agência"
                name="agNumber"
                value={bankForm.values.agNumber}
                onChange={bankForm.handleChange}
                onBlur={bankForm.handleBlur}
                error={
                  bankForm.touched.agNumber && bankForm.errors.agNumber
                    ? bankForm.errors.agNumber
                    : null
                }
                validate={bankForm.touched.agNumber && bankForm.errors.agNumber}
                placeholder="Digite o número da agência"
                width="100%"
              />

              <FormInput
                label="Conta"
                name="accNumber"
                value={bankForm.values.accNumber}
                onChange={bankForm.handleChange}
                onBlur={bankForm.handleBlur}
                error={
                  bankForm.touched.accNumber && bankForm.errors.accNumber
                    ? bankForm.errors.accNumber
                    : null
                }
                validate={
                  bankForm.touched.accNumber && bankForm.errors.accNumber
                }
                placeholder="Digite o número da conta bancária"
                width="100%"
              />

              <FormInput
                label="Chave Pix"
                name="pix"
                value={bankForm.values.pix}
                onChange={bankForm.handleChange}
                onBlur={bankForm.handleBlur}
                error={
                  bankForm.touched.pix && bankForm.errors.pix
                    ? bankForm.errors.pix
                    : null
                }
                validate={bankForm.touched.pix && bankForm.errors.pix}
                placeholder="Digite a sua chave Pix"
                width="100%"
              />

              <FormSelect
                label="Tipo de Conta"
                select
                value={bankForm.values.accountType}
                onChange={(value) =>
                  bankForm.setFieldValue("accountType", value)
                }
                width="100%"
                options={[
                  { label: "Corrente", value: "corrente", disabled: false },
                  { label: "Poupança", value: "poupanca", disabled: false },
                ]}
              />

              <FormInput
                fieldName="document"
                label="Documento do Responsável"
                name="document"
                value={bankForm.values.document}
                onChange={bankForm.handleChange}
                onBlur={bankForm.handleBlur}
                error={
                  bankForm.touched.document && bankForm.errors.document
                    ? bankForm.errors.document
                    : null
                }
                validate={bankForm.touched.document && bankForm.errors.document}
                mask={"999.999.999-99"}
                placeholder={
                  "  Digite o CPF do responsável pela conta bancária"
                }
                form={bankForm}
                width="100%"
              />

              <Button htmlType="submit" loading={saveLoading}>
                {saveLoading ? "Salvando..." : "Salvar alterações"}
              </Button>
          </form>
            </Section>
        </Card>

        <Card width={isMobileDevice() ? "100%" : "100%"}>
          <Text h3>Excluir Conta</Text>
          <Text>
            Atenção: A exclusão da conta é uma ação permanente e não pode ser
            desfeita. Todos os seus dados serão removidos permanentemente.
          </Text>
          <Button
            type="error"
            ghost
            onClick={() => setModalWarning(true)}
            mt={1}
          >
            Excluir conta
          </Button>
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default Profile;
