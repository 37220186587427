import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../utils/api";
import AccountLinksSection from "./Components/AccountLinks";
import MainCard from "../../../components/MainCard";
import Card from "../../../components/Card";
import "./Attendant.css";

const LinkAccounts = () => {
  const navigate = useNavigate();
  const { user, checkList, checkComplete, updatePartnerDetail } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkSave, setCheckSave] = useState(true);
  const [modalWarning, setModalWarning] = useState(false);

  // Fetch settings from API
  const getSettings = async () => {
    setLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.get(
          `partners/partner_details/${user.partnerDetailId}`
        );

        if (data) {
          formStep6.setValues({
            connectedWithGoogle: data.data.attributes?.connectedWithGoogle,
          });
        }
      }
    } catch (error) {
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
      setCheckSave(true);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  // Formik for Step 6 (Vincular Contas)
  const formStep6 = useFormik({
    initialValues: {
      connectedWithGoogle: false,
    },
    validationSchema: yup.object({}),
    onSubmit: (values) => updateStep6(values),
  });

  const updateStep6 = async (values) => {
    setSaveLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                connectedWithGoogle: values.connectedWithGoogle,
              },
            },
          }
        );

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              connectedWithGoogle: values.connectedWithGoogle,
            },
          },
        });

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
        }
      }
    } catch (error) {
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  const handleNavigate = () => {
    // Implement navigation logic if needed
  };

  const handleMenu = () => {
    if (!checkSave) {
      setModalWarning(true);
    } else {
      // Implement menu selection logic if needed
    }
  };

  return (
    <AuthorizedLayout>
      <MainCard>
        <Card title="Vincular Contas">
        
              <AccountLinksSection
                form={formStep6}
                saveLoading={saveLoading}
                setMenuSelected={handleMenu}
              />
  
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default LinkAccounts;
