import { GeistProvider } from "@geist-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { AuthProvider } from "./providers/AuthProvider";
import { Routes } from "./routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import NewPlanProvider from "./screens/PartnerScreens/Finance/Context/NewPlanProvider";
import TagManager from "react-gtm-module";
import NewPlanProvider from "./screens/PartnerScreens/Finance/Context/NewPlanProvider";
// import Hotjar from "./utils/hotjar";

const tagManagerArgs = {
  gtmId: "GTM-K83X34XT",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID}>
      <GeistProvider
        theme={{
          type: "custom",
          palette: {
            primary: "#539d65",
            accents_1: "#fafafa",
            accents_2: "#eaeaea",
            accents_3: "#999",
            accents_4: "#888",
            accents_5: "#666",
            accents_6: "#444",
            accents_7: "#333",
            accents_8: "#111",
            background: "#fff",
            foreground: "#000",
            border: "#eaeaea",
            selection: "#539d65",
            secondary: "#122137",
            success: "#77E191",
            warning: "#f5a623",
            error: "#ff0000",
            link: "#0070f3",
            accent: "#102649",
            color: "#102649",
            input: {
              color: "#102649",
              borderColor: "#eaeaea",
              hoverBorder: "#666",
              background: "#fff",
            },
          },
          expressiveness: {
            linkStyle: "none",
            linkHoverStyle: "none",
            dropdownBoxShadow: "0 0 0 1px #333",
            scrollerStart: "#fff",
            scrollerEnd: "#000",
            shadowSmall: "0 5px 10px rgba(0, 0, 0, 0.12)",
            shadowMedium: "0 8px 30px rgba(0, 0, 0, 0.12)",
            shadowLarge: "0 30px 60px rgba(0, 0, 0, 0.12)",
            portalOpacity: 0.25,
          },
          font: {
            sans: "'Inter', sans-serif",
            mono: "Menlo, Monaco, monospace",
          },
          layout: {
            gap: "16pt",
            radius: "6px",
          },
        }}
      >
        <AuthProvider>
          <NewPlanProvider>
            {/* <Hotjar /> */}
            <Routes />
            <ToastContainer />
          </NewPlanProvider>
        </AuthProvider>
      </GeistProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
