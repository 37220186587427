import React, { useContext, useEffect, useState } from "react";
import NewPlanContext from "../Context/NewPlanContext";
import { useNavigate } from "react-router-dom";
import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";

import "./Step3.css";
import Button from "../../../../components/Button";

const Step3 = () => {
  const navigate = useNavigate();
  const { step1Form, step2Form, step3Form, subscribeLoading } =
    useContext(NewPlanContext);

  useEffect(() => {
    if (step1Form.values.title === "Plano Gratuito") {
    } else if (
      step1Form.values.title === "" &&
      step2Form.values.cardNumber === ""
    ) {
      navigate("/financeiro/step1");
    } else if (
      step1Form.values.title !== "" &&
      step2Form.values.cardNumber === ""
    ) {
      navigate("/financeiro/step2");
    }
  }, [navigate, step1Form.values.title, step2Form.values.cardNumber]);

  function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return cnpj;
  }

  const backHabdle = () => {
    if (step1Form.values.title === "Plano Gratuito") {
      navigate("/financeiro/step1");
    } else {
      navigate("/financeiro/step2");
    }
  };

  const handleTYpePlan = () => {
    if (step1Form.values.title === "Plano Gratuito") {
      navigate("/dashboard");
    } else {
      step3Form.handleSubmit();
    }
  };

  const Section = ({ icon: Icon, title, children }) => (
    <div className="section-container">
      <div className="section-header">
        <div className="icon-container">
          {Icon && <Icon className="section-icon" />}
        </div>
        <h3 className="section-title">{title}</h3>
      </div>
      {children}
    </div>
  );

  return (
    <AuthorizedLayout>
      {/* <div className="containerSummary">
        <div className="title">
          <img src={iconCheck} alt="card" />
          RESUMO DOS DADOS
        </div>
        <div className="subTitle">
          Confira os dados e finalize o seu cadastro:
        </div>

        <div className="containerCard">
          <div className="card">
            <div className="containerInput">
              <div className="title">Nome do Negócio</div>
              <div className="subTitle">{step3Form.values.name}</div>
            </div>

            <div className="containerInput">
              <div className="title">Telefone</div>
              <div className="subTitle">{step3Form.values.phone}</div>
            </div>

            <div className="containerInput">
              <div className="title">E-mail</div>
              <div className="subTitle">{step3Form.values.email}</div>
            </div>

            <div className="containerInput">
              <div className="title">CPF/CNPJ</div>
              <div className="subTitle">
                {step3Form.values.documentType === "cpf"
                  ? formatCPF(step3Form.values.cpf)
                  : formatCNPJ(step3Form.values.cnpj)}
              </div>
            </div>
          </div>

          <div className="line"></div>

          <div className="card">
            <div className="containerInput">
              <div className="title">Plano Escolhido</div>
              <div className="subTitle">{step1Form.values.title}</div>
            </div>

            {step1Form.values.title !== "Plano Gratuito" && (
              <>
                <div className="containerInput">
                  <div className="title">Tipo de Assinatura</div>
                  <div className="subTitle">Mensal</div>
                </div>

                <div className="containerInput">
                  <div className="title">Tipo de Pagamento</div>
                  <div className="subTitle">Cartão de Crédito</div>
                </div>
              </>
            )}

            <div className="containerInput">
              <div className="title">Valor da Cobrança</div>
              <div className="subTitle">{`R$${step1Form.values.amount}`}</div>
            </div>
          </div>
        </div>

        <div className="containerButtonsSumary">
          <div onClick={() => backHabdle()} className="buttonBackCRedCard">
            <img src={arrow} alt="arrow" />
            Anterior
          </div>

          <div onClick={() => handleTYpePlan()} className="buttonNextCRedCard">
            {step1Form.values.title === "Plano Gratuito"
              ? "Escolher Plano"
              : subscribeLoading
              ? "Carregando..."
              : "Contratar Plano"}
          </div>
        </div>
      </div> */}

      <div className="container">
        <div className="card">
          <div className="card-content">
            <div className="review-content">
              {/* Cabeçalho */}
              <div className="review-header">
                <h2 className="review-title">RESUMO DOS DADOS</h2>
                <p className="review-subtitle">
                  Confira os dados e finalize o seu cadastro:
                </p>
              </div>

              <div className="sections-grid">
                <Section icon={""} title="Nome do Negócio">
                  <div className="info-box">
                    <div className="info-title">
                      {step3Form.values.name || "Sem título"}
                    </div>
                  </div>
                </Section>

                <Section icon={""} title="Telefone">
                  <div className="info-box">
                    <div className="info-title">
                      {step3Form.values.phone || "Sem título"}
                    </div>
                  </div>
                </Section>

                <Section icon={""} title="E-Mail">
                  <div className="info-box">
                    <div className="info-title">
                      {step3Form.values.email || "Sem título"}
                    </div>
                  </div>
                </Section>

                <Section icon={""} title="CPF/CNPJ">
                  <div className="info-box">
                    <div className="info-title">
                      {step3Form.values.documentType === "cpf"
                        ? formatCPF(step3Form.values.cpf)
                        : formatCNPJ(step3Form.values.cnpj) || "Sem título"}
                    </div>
                  </div>
                </Section>

                <Section icon={""} title="Plano escolhido">
                  <div className="info-box">
                    <div className="info-title">
                      {step1Form.values.title || "Sem título"}
                    </div>
                  </div>
                </Section>

                <Section icon={""} title="Valor da Cobrança">
                  <div className="info-box">
                    <div className="info-title">
                      {`R$${step1Form.values.amount}` || "Sem título"}
                    </div>
                  </div>
                </Section>
              </div>

              {/* Botões de Ação */}

              <div className="buttonContainer">
                <Button variant="outline" onClick={() => backHabdle()}>
                  Voltar
                </Button>
                <Button
                  variant="filled"
                  onClick={() => handleTYpePlan()}
                  className="buttonNextCRedCard"
                >
                  {step1Form.values.title === "Plano Gratuito"
                    ? "Escolher Plano"
                    : subscribeLoading
                    ? "Carregando..."
                    : "Contratar Plano"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Step3;
