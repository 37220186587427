import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";
import Papa from "papaparse";
import {
  Table,
  Input,
  Pagination,
  Select,
  Button,
  Card,
  Grid,
  Loading,
} from "@geist-ui/core";
import { Loader } from "../../../components/Loader";
import { Search, Download } from "@geist-ui/icons";
import "./Clients.css";
import MainCard from "../../../components/MainCard";
import GeistTableFilter from "../../../components/GeistTableFilter/GeistTableFilter";
import GeistTable, {
  TableUser,
} from "../../../components/GeistTable/GeistTable";
import GeistPagination from "../../../components/GeistPagination/GeistPagination";

import iconUser from "../../../assets/noAvatar.svg";
import unmask from "../../../utils/unmask";

const topArray = [
  { label: "ID", value: "id" },
  { label: "Nome", value: "clientName" },
  { label: "Email", value: "clientEmail" },
  { label: "Telefone", value: "phoneNumber" },
  { label: "Plano Atual", value: "subscriptionT" },
  { label: "Uso de Tokens", value: "tokenUsage" },
  { label: "Status", value: "statusT" },
  { label: "Whatsapp", value: "wppConnected" },
  { label: "Google", value: "connectedWithGoogle" },
];

const Clients = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterKeys, setFilterKeys] = useState(topArray);
  const [typeFilter, setTypeFilter] = useState("");

  const handleSubscription = (active, plan) => {
    if (!active || !plan) {
      return "Plano Gratuito";
    } else {
      return plan?.name;
    }
  };

  const handleStatus = (value) => {
    if (value?.active) {
      switch (value.status) {
        case "Dados Preenchidos":
          return "Inativo";

        case "Cliente Ativo":
          return "Ativo";
        default:
          return value;
      }
    } else {
      return "Cadastrado";
    }
  };

  // Fetch clients from API
  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/affiliates/partners");
      if (data) {
        setClients(
          data.data.map((item) => {
            return {
              id: Number(item.id),
              partnerName: item.attributes?.name || "Nome não informado",
              sNumber: item.attributes?.serviceNumber
                ? unmask(item.attributes?.serviceNumber)
                : "Número não informado",
              phoneNumber:
                item.attributes?.contactNumber || "Número não informado",
              subscription: handleSubscription(
                item.attributes?.active,
                item.attributes?.currentPlan
              ),
              statusT: handleStatus(item?.attributes),
              tokenUsage: `${item.attributes?.montlyTokensConsumed || 0} / ${
                item.attributes?.montlyTokensConsumed +
                item.attributes?.monthlyTokensLeft
              }`,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);
  const filteredData = clients.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => setPage(pageNumber);
  const handlePageSizeChange = (val) => {
    setPageSize(Number(val));
    setPage(1);
  };

  const generateCSV = (data) => {
    const formattedData = data.map((item) => ({
      ID: Number(item.id),
      "Nome do Parceiro": item?.partnerName || "Nome não informado",
      "Nome da Empresa": item?.name || "Nome não informado",
      Email: item?.email || "N/A",
      "Telefone de Contato":
        parsePhoneAdmin(item?.phoneNumber) || "Número não informado",
      "Telefone Vinculado":
        parsePhoneAdmin(item?.sNumber) || "Número não informado",
      Assinatura: item?.subscription || "N/A",
      Documento: item?.document || "N/A",
      Google: item?.connectedWithGoogle ? "Vinculado" : "Não Vinculado",
      WhatsApp: item?.wppConnected ? "Vinculado" : "Não Vinculado",
      Status: item?.active ? "Ativa" : "Inativa",
      "Data de Cadastro": item?.createdAt
        ? moment(item?.createdAt).format("DD/MM/YYYY")
        : "N/A",
      "Uso de Tokens": `${item?.montlyTokensConsumed || 0} / ${
        item?.montlyTokensConsumed + item?.monthlyTokensLeft
      }`,
    }));

    const csv = Papa.unparse(formattedData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `SacGPT Parceiros ${moment().format("DD-MM-YYYY")}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AuthorizedLayout>
      <MainCard>
        <Card title="Lista de Clientes">
          <Grid.Container gap={0}>
            <Grid xs={24} style={{ marginBottom: "var(--spacing-lg)" }}>
              <GeistTableFilter
                searchTerm={searchTerm}
                onSearchChange={(value) => setSearchTerm(value)}
                onSearchClear={() => {
                  setSearchTerm("");
                  setTypeFilter("");
                }}
                filterKeys={filterKeys}
                selectedFilters={filterKeys.map((k) => k.value)}
                onFilterChange={(values) => {
                  setFilterKeys(
                    filterKeys.filter((item) => values.includes(item.value))
                  );
                }}
                onExport={generateCSV}
                exportData={currentItems}
              />
            </Grid>

            <Grid
              xs={24}
              style={{ marginTop: 0, width: "100%", display: "flex" }}
            >
              {loading ? (
                <div className="loading-container">
                  <Loading>Carregando parceiros...</Loading>
                </div>
              ) : currentItems.length === 0 ? (
                <div className="empty-state">Nenhum cliente encontrado</div>
              ) : (
                <GeistTable
                  columns={[
                    {
                      field: "id",
                      label: "ID",
                      sortable: true,
                      render: (value) => value,
                    },
                    {
                      field: "name",
                      label: "Nome / E-MAIl",
                      sortable: true,
                      render: (value, rowData) => (
                        <TableUser
                          name={rowData.name}
                          email={rowData.email}
                          avatar={rowData.avatar || iconUser}
                        />
                      ),
                    },
                    {
                      field: "phoneNumber",
                      label: "Telefone",
                      sortable: true,
                      render: (value) => parsePhoneAdmin(value),
                    },

                    {
                      field: "subscription",
                      label: "Plano Atual",
                      sortable: true,
                      render: (value) => value,
                    },

                    // {
                    //   field: "createdAt",
                    //   label: "Data de Cadastro",
                    //   sortable: true,
                    //   render: (value) => moment(value).format("DD/MM/YYYY"),
                    // },
                    {
                      field: "tokenUsage",
                      label: "Uso de Tokens",
                      sortable: true,
                      render: (value) => value,
                    },

                    {
                      field: "statusT",
                      label: "Status",
                      sortable: true,
                      render: (value) => value,
                    },
                    {
                      field: "wppConnected",
                      label: "Whatsapp",
                      sortable: true,
                      render: (value) =>
                        `${value?.wppConnected ? "Conectado" : "Desconectado"}`,
                    },
                    {
                      field: "connectedWithGoogle",
                      label: "Google",
                      sortable: true,
                      render: (value) =>
                        `${
                          value?.connectedWithGoogle
                            ? "Conectado"
                            : "Desconectado"
                        }`,
                    },
                  ]}
                  data={currentItems}
                  loading={loading}
                  emptyText="Nenhum cliente encontrado"
                  className="geist-table"
                />
              )}
            </Grid>

            <Grid xs={24}>
              <GeistPagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </Grid>
          </Grid.Container>
        </Card>
      </MainCard>
    </AuthorizedLayout>
  );
};

export default Clients;
