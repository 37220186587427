import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import * as yup from "yup";
import { AuthContext } from "../../providers/AuthProvider";

import image1 from "../../assets/fuundo_ondas (1).png";
import image2 from "../../assets/fuundo_ondas.png";
import logo from "../../assets/Frame 1.png";
import arrow from "../../assets/Vector.png";
import iconRegister from "../../assets/NotePencil.png";

import "./SignUp.css";

const SignUp = () => {
  const navigate = useNavigate();
  const { signUp } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("O campo nome é obrigatório!"),
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("O campo e-mail é obrigatório!"),
      // phone: yup
      //   .string()
      //   .required("O campo telefone é obrigatório!")
      //   .matches(
      //     /^\+?\d{1,3}\s?\d{1,4}\s?\d{4,10}$/,
      //     "Formato de telefone inválido."
      //   ),
      phone: yup
        .string()
        .min(14, "Telefone inválido!")
        .required("Telefone de contato é obrigatório!"),
      password: yup
        .string()
        .min(8, "Senha deve conter no mínimo 8 caracteres.")
        .required("O campo senha é obrigatório!"),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref("password")], "As senhas devem corresponder.")
        .required("O campo de confirmação de senha é obrigatório!"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await signUp({ values });
        if (response === "success") {
          navigate("/dashboard");
        }
      } catch (error) {
        // Handle error if necessary
      } finally {
        setLoading(false);
      }
    },
  });

  const renderInputField = (label, name, type = "text", mask = false) => (
    <div className="inputGroup">
      <div className="labelInputRegister">{label}</div>
      {mask ? (
        <ReactInputMask
          mask={form.values[name].replace(/\s/g, "").length > 13
            ? "(99) 9 9999-9999" 
            : "(99) 9999-99999" }
          maskChar={""}
          name={name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values[name]}
        >
          {(inputProps) => {
            // Mesclar os props do ReactInputMask com nossos props personalizados
            const mergedProps = {
              ...inputProps,
              id: name,
            };
            return (
              <input {...mergedProps} type="text" className="inputRegister" />
            );
          }}
        </ReactInputMask>
      ) : (
        <input
          className="inputRegister"
          type={type}
          name={name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values[name]}
          autoComplete="new-password"
        />
      )}
      {form.touched[name] && form.errors[name] && (
        <Typography className="errorText">{form.errors[name]}</Typography>
      )}
    </div>
  );

  return (
    <div className="containerRegister">
      <header className="headerRegister">
        <img src={logo} alt="logo" />
      </header>

      <img src={image1} className="image1" alt="Background decoration" />
      <img src={image2} className="image2" alt="Background decoration" />

      <div className="containerCenterRegister">
        <div className="boxRegister">
          <div className="headerBoxRegister">
            <div className="text">
              <img src={iconRegister} alt="Register icon" />
              CADASTRE-SE
            </div>
            <div className="subText">
              Para criar sua conta é simples, basta preencher os campos abaixo:
            </div>
          </div>
          <form onSubmit={form.handleSubmit} className="formRegister">
            {renderInputField("Nome", "name")}
            {renderInputField("E-mail", "email", "email")}
            {renderInputField("Telefone", "phone", "number", true)}
            {renderInputField("Senha", "password", "password")}
            {renderInputField(
              "Confirme sua Senha",
              "passwordConfirmation",
              "password"
            )}

            <button type="submit" className="buttonRegister">
              {loading ? (
                "Carregando..."
              ) : (
                <div className="buttonContent">
                  Cadastrar
                  <img src={arrow} alt="Seta para a direita" />
                </div>
              )}
            </button>
          </form>
        </div>
      </div>

      <footer className="footerRegister">
        sacgpt.com.br © 2024 - Todos os Direitos Reservados
      </footer>
    </div>
  );
};

export default SignUp;
