import React from 'react';
import PropTypes from 'prop-types';
import GeistTable from '../../../../components/GeistTable/GeistTable';

/**
 * Componente TableTransactions - Exibe uma tabela de transações financeiras
 * 
 * @param {Array} transactions - Array de transações a serem exibidas
 * @param {boolean} loadingTable - Estado de carregamento da tabela
 */
const TableTransactions = ({ transactions, loadingTable }) => {
  const getStatusVariant = (status) => {
    const statusMap = {
      'Autorizado': 'success',
      'Capturada na Operadora de Cartão': 'success',
      'Negada na Operadora de Cartão': 'error',
      'Estornada na Operadora de Cartão': 'warning',
      'Cancelada manualmente': 'error',
      'Cancelada ao cancelar a cobrança': 'error',
      'Paga fora do sistema': 'info',
      'Gratuito': 'info',
    };
    return statusMap[status] || 'default';
  };

  const columns = [
    {
      field: 'id',
      label: 'ID',
      width: 100,
    },
    {
      field: 'description',
      label: 'Descrição',
      width: 200,
    },
    {
      field: 'date',
      label: 'Data',
      width: 150,
    },
    {
      field: 'type',
      label: 'Tipo',
      width: 100,
      render: (value) => (
        <div className="scoreValue" style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'auto',
          minWidth: '28px',
          height: '28px',
          backgroundColor: value === 'Saída' ? '#FFF8E1' : '#E8F5E9',
          color: value === 'Saída' ? '#F9A825' : '#2E7D32',
          borderRadius: '50px',
          fontWeight: 600,
          fontSize: '14px',
          padding: '0 12px'
        }}>
          {value}
        </div>
      )
    },
    {
      field: 'amount',
      label: 'Valor',
      width: 100,
      render: (value) => `R$ ${value.toFixed(2)}`,
    },
    {
      field: 'tStatus',
      label: 'Status',
      width: 200,
      render: (value) => {
        const statusColors = {
          'success': { bg: '#E8F5E9', color: '#2E7D32' },
          'error': { bg: '#FFEBEE', color: '#C62828' },
          'warning': { bg: '#FFF8E1', color: '#F9A825' },
          'info': { bg: '#E3F2FD', color: '#1976D2' },
          'default': { bg: '#F5F5F5', color: '#757575' }
        };
        
        const variant = getStatusVariant(value);
        const colors = statusColors[variant];
        
        return (
          <div className="scoreValue" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            minWidth: '28px',
            height: '28px',
            backgroundColor: colors.bg,
            color: colors.color,
            borderRadius: '50px',
            fontWeight: 600,
            fontSize: '14px',
            padding: '0 12px'
          }}>
            {value}
          </div>
        );
      }
    },
  ];

  return (
    <div className="containerTransactions">
      <div className="title">Histórico de Transações</div>
      <GeistTable
        data={transactions}
        columns={columns}
        loading={loadingTable}
        emptyText="Nenhuma transação encontrada"
      />
    </div>
  );
};

TableTransactions.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      date: PropTypes.string,
      type: PropTypes.string,
      amount: PropTypes.number,
      tStatus: PropTypes.string,
    })
  ).isRequired,
  loadingTable: PropTypes.bool.isRequired,
};

export default TableTransactions;