import React from "react";
import PropTypes from "prop-types";
import { Progress } from "@geist-ui/core";
import Card from "../../../../components/Card";
import Button from "../../../../components/Button";
import arrow from "../../../../assets/Vector.png";

const TokenCard = ({ metrics, navigate }) => {
  const renderTokenProgress = () => {
    const totalTokens = Number(metrics?.remainingTokens || 0) + Number(metrics?.montlyTokensConsumed || 0);
    const tokenProgress = totalTokens ? (Number(metrics?.montlyTokensConsumed || 0) / totalTokens) * 100 : 0;
    return (
      <>
        <Progress value={tokenProgress.toFixed(2)} />
        <div className="tokenPercentage" style={{ marginTop: '4px', fontSize: '0.9rem', color: 'var(--color-secondary)', marginBottom: '10px' }}>
          {tokenProgress.toFixed(2)}% consumidos
        </div>
      </>
    );
  };

  return (
    <Card className="cardScore">
      <div className="containerText">
        <div className="title">
          Tokens disponíveis: <strong>{Number(metrics?.remainingTokens || 0).toLocaleString()}</strong>
        </div>
        <div className="subtitle">
          Tokens consumidos: {Number(metrics?.montlyTokensConsumed || 0).toLocaleString()}
        </div>
      </div>
      {renderTokenProgress()}
      <Button
        onClick={() => navigate("/tokens", { state: { metrics } })}
        variant="outline"
      >
        Comprar mais tokens
        {/* <img src={arrow} alt="arrow" className="imgBUtonNex" /> */}
      </Button>
    </Card>
  );
};

TokenCard.propTypes = {
  metrics: PropTypes.shape({
    remainingTokens: PropTypes.number,
    montlyTokensConsumed: PropTypes.number,
  }),
  navigate: PropTypes.func.isRequired,
};

export default TokenCard;